import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {withStyles} from '@material-ui/core/styles';


const styles = theme => ({
    input: {
        outline: 'none',
        width: '100%',
        padding: '14px 24px',
        borderRadius: 10,
        fontSize: "14px",
        lineHeight: "19px",
        color: theme.palette.primary.text,
        border: '1px solid #C7CAD6',
        maxHeight: 45,
    },
    inputTitle: {
        fontSize: 14,
        color: theme.palette.primary.headerText,
        fontWeight: 400,
        margin: '24px 0 8px',
    },
    error: {
        borderColor: theme.palette.alerts.error,
    }
});

const Input = props => {
    const {classes, title, autofocus, inputTitleStyles, maxLength, style, value, placeholder, onChange, handleKeyPress, handleEnterPress, error} = props;

    const handleKeyPressHandler = e => {
        if (e.key === 'Enter') {
            if (handleEnterPress) {
                handleEnterPress();
            }
        }
        if (handleKeyPress) {
            handleKeyPress(e);
        }
    };

    return (
        <div>
            {title && (
                <p className={classes.inputTitle} style={inputTitleStyles}>{title}</p>
            )}
            <input
                className={`${classes.input} ${error && classes.error}`}
                style={style}
                value={value}
                autoFocus={autofocus}
                placeholder={placeholder}
                maxLength={maxLength ? maxLength : 255}
                onChange={onChange}
                onKeyPress={handleKeyPressHandler}
            />
        </div>
    )
};

Input.defaultProps = {
    inputTitleStyles: {}
};

Input.propTypes = {
    classes: PropTypes.object.isRequired,
    placeholder: PropTypes.string.isRequired,
    inputTitleStyles: PropTypes.object,
    style: PropTypes.object,
    maxLength: PropTypes.number,
    title: PropTypes.string,
    error: PropTypes.bool,
    autofocus: PropTypes.bool,
    handleKeyPress: PropTypes.func,
    handleEnterPress: PropTypes.func
};

export default withRouter(withStyles(styles)(Input));
