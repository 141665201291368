import React, {Component} from "react";
import cookie from "react-cookies";
import {Redirect, Route, Switch} from "react-router-dom";
import {HOME_PATH} from "../../../configs";
import {authenticate} from "../actions/security";
import LoginForm from "./LoginForm";
import ForgotPasswordForm from "./ForgotPasswordForm";
import ResetPasswordForm from "./ResetPasswordForm";
import SignUp from "./SignUpForm";
import {getLoginPageElement} from "../api/login";
import {withStyles} from "@material-ui/core";
import PropTypes from "prop-types";

const formStyles = () => ({
    main: {
        flex: '0 0 50%',
        maxWidth: '50%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        padding: '40px',
        position: 'relative'
    },
    "@media (min-width: 320px) and (max-width: 480px)":{
        main: {
            flex: '0 0 100%',
            maxWidth: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-evenly',
            alignItems: 'center',
            padding: '15px',
            position: 'relative'
        },
    },
    formTitle: {
        fontSize: '18px',
        fontWeight: '500',
        margin: 0,
        color: '#3A3F62'
    },
    demoForm: {
        width: '60%',
        margin: '0 0 32px',
        textAlign: 'center',
    },
    demoDiv: {
        margin: '32px 0 8px',
        textAlign: 'left',
    },
    bottomText: {
        position: 'absolute',
        bottom: '60px'
    },
    forgotPasswordLink: {
        fontSize: '13px',
        color: 'gray',
        display: 'block',
        textAlign: 'center',
        marginTop: '30px',
    },
    forgotPasswordNoteText: {
        fontSize: '14px',
        color: '#616581',
        marginTop: '-10px',
        marginBottom: '25px',
        fontWeight: '300'
    },
});

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: null,
            subTitle: null,
            imagePath: null,
            isSignUpEnable: false,
            redirectToReferrer: false,
        };
    }

    componentDidMount() {
        getLoginPageElement((element) => {
            this.setState({
                title: element.title,
                subTitle: element.subTitle,
                imagePath: element.imagePath,
                isSignUpEnable: element.isSignUpEnable
            });
        });

        let token = cookie.load('YWxpYWFkbWlu');
        if (token) {
            this.authenticateWithReferrer(token);
        }
    }

    authenticateWithReferrer(token) {
        authenticate(token);

        this.setState({redirectToReferrer: true});
    }

    render() {
        const {classes} = this.props;
        const {from} = this.props.location.state || {from: {pathname: HOME_PATH + "/"}};
        const {redirectToReferrer, title, subTitle, imagePath, isSignUpEnable} = this.state;
        if (redirectToReferrer) {
            return <Redirect to={from}/>;
        }
        return (
            <div className="main container-fluid">
                {title && subTitle && imagePath &&
                (<div className="row row--main">
                    <section style={{
                        background: `url("${imagePath}") top right no-repeat #F7FAFE`,
                        backgroundSize: 'contain !important'
                    }} className="title-section col-sm-6">
                        <article className="section__inner">
                            <h1 className="title--main">{title}</h1>
                            <h4 className="title--small">{subTitle}</h4>
                        </article>
                    </section>
                    <Switch>
                        <Route path={"/sign-up/:code"} component={(props) => <SignUp {...props} invited={true} styles={classes}/>}/>
                        <PrivateRoute isSignUpEnable={isSignUpEnable} exact path="/sign-up" component={() => <SignUp styles={classes}/>}/>
                        <Route path={"/Login"} component={
                            () => <LoginForm authenticateWithReferrer={(token) => this.authenticateWithReferrer(token)}
                                             isSignUpEnable={isSignUpEnable}
                                             styles={classes}/>
                            }/>
                        <Route path="/forgot-password" component={(props) => <ForgotPasswordForm styles={classes}/>}/>
                        <Route path="/reset-password" component={(props) => <ResetPasswordForm styles={classes}/>}/>
                    </Switch>
                </div>)
                }
            </div>
        )
    }
}

const PrivateRoute = ({component: Component, isSignUpEnable, ...rest}) => (
    <Route
        {...rest}
        render={props =>
            isSignUpEnable ? (
                <Component {...props} />
            ) : (
                <Redirect
                    to={{
                        pathname: "/Login",
                        state: {from: props.location}
                    }}
                />
            )
        }
    />
);
Login.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default (withStyles(formStyles, {withTheme: true})(Login));
