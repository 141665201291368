import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Typography, withStyles} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { NavLink, Redirect, Route, withRouter} from 'react-router-dom';
import AtomEditor from './components/AtomEditor';
import NewAtom from './components/NewAtom';
import DeleteFlow from './components/DeleteFlow';
import NewFlow from './components/NewFlow';
import DeleteAtom from './components/DeleteAtom';
import {getFlows} from './api/flow';
import {getParams} from '../home/api/bots';
import {SearchIcon} from '../../uiKit/icons/Icons.js';
import Headline from '../../uiKit/texts/Headline';
import {HOME_PATH} from "../../configs";
import {debounce} from 'lodash';

import { DEFAULT_DEBOUNCE_TIME } from '../../constants/time';
import { searchAtomsByContent } from './api/atom';
import {isStringEmpty} from "../../helpers/isStringEmpty";

const styles = theme => ({
    input: {
        padding: '10px',
        border: 'none',
        outline: 'none',
        borderRadius: '5px',
        fontSize: '14px',
        lineHeight: '19px',
        backgroundColor: 'white',
        width: '100%'
    },
    inputHolder: {
        border: '1px solid #C7CAD6',
        width: 'max-content',
        borderRadius: '10px',
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: 'white',
        marginRight: 10,
        padding: 10,
        maxHeight: 45,
        minWidth: 340
    },
    searchIcon: {
        margin: 10,
        fontSize: 20,
        color: theme.palette.primary.main
    },
    atomsSearch: {
        padding: 10
    },
    activeChip: {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        margin: 5
    },
    chip: {
        margin: 5,
        border: '1px solid #EBEBEF'
    },
    mainWrap: {
        background: '#FFFFFF',
        boxShadow: 'none',
        borderRadius: 10,
        height: 'auto',
        display: 'flex',
        justifyContent: 'space-between'
    },
    header: {
        color: theme.palette.primary.headerText,
        fontSize: 14,
        fontWeight: 700,
        fontFamily: 'Lato, sans-serif'
    },
    '@global': {
        "[class*='MuiExpansionPanel']:before ": {
            display: 'none'
        },
        '.flowExp:hover .delete': {
            display: 'block'
        },
        '.flowExp::before': {
            display: 'none'
        },
        '.delete': {
            position: 'relative',
            left: 35,
            bottom: 33,
            display: 'none',
            transition: 'all 0.5s ease'
        },
        ".flowExp[class*='expanded'] .delete": {
            bottom: 44,
            transition: 'all 0.5s ease'
        }
    },
    addNew: {
        backgroundColor: 'white',
        color: theme.palette.primary.main,
        border: '1px solid #1658F3',
        padding: 10,
        borderRadius: 10,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        maxHeight: 40,
        margin: 5
    }
});

class Builder extends React.Component {
    state = {
        searchText: '',
        atomId: null,
        flowId: null,
        redirect:false,
        redirectId:null
    };

    constructor() {
        super();
        this.getSearchAtoms = this.getSearchAtoms.bind(this);
    }

    componentDidMount() {
        getFlows(this.props.match.params.botId);
        getParams();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.flows !== this.props.flows) {
            if (!this.state.atomId) {
                this.selectWelcomeAtom();
            }
        }
    }

    selectWelcomeAtom = () => {
        const {flows} = this.props;
        if (flows && flows.length > 0 && flows[0].atoms && flows[0].atoms[0].id && this.state.redirectId=== null) {
            this.setState({
                redirect:true,
                redirectId:flows[0].atoms[0].id
            },()=>{
                this.setState({
                    redirect:false
                })})
        }
    };

    selectAtom(id) {
        console.log(id);
        this.setState({
            redirectId:id,
            redirect:true
        },()=>{
            this.setState({
                redirect:false
            })
        })
    }

    checkAccessToDelete(flow) {
        let deleteAccess = true;
        if (flow.atoms !== undefined) {
            flow.atoms.find((atom) => {
                if (atom.isHardCoded) {
                    deleteAccess = false;
                }
            });
        }
        return deleteAccess;
    }

    renderRedirect(){
        if(this.state.redirect){
            return <Redirect    to={`${HOME_PATH}/bot/${this.props.match.params.botId}/flows/${this.state.redirectId}`}/>
        }
    }

    checkTextExists = (atom) => {
        if (isStringEmpty(this.state.searchText)) return true;

        if (atom.name.toUpperCase().includes(this.state.searchText.toUpperCase())) return true;

        const searchFlow = this.props.searchAtoms[atom.flowId];
        return (!!searchFlow && searchFlow.some(({ id }) => id === atom.id));
    };

    checkAtom(flow) {
        if (isStringEmpty(this.state.searchText))
            return true;
        if (flow.atoms != null) {
            for (let x = 0; x < flow.atoms.length; x++) {
                if (this.checkTextExists(flow.atoms[x])) {
                    return true;
                }
            }
        }

        return false;
    }

    getSearchAtoms = debounce(_ => {
        if (this.state.searchText.length > 0) {
            searchAtomsByContent(this.state.searchText);
        }
    }, DEFAULT_DEBOUNCE_TIME);

    render() {
        const {classes} = this.props;
        const {redirectId, atomId, flowId} = this.state;
        return (
            <div>
                <div style={{background: '#F7FAFE', display: 'flex', justifyContent: 'space-between'}}>
                    <div style={{padding: '0px 26px 40px 0', width: '56%'}}>
                        <div className={classes.header}>
                            <div style={{display: 'flex'}}>
                                <div style={{
                                    display: 'flex',
                                    width: '100%',
                                    justifyContent: 'space-between',
                                    alignItems: 'center'
                                }}
                                >
                                    <Headline
                                        title="Flows"
                                    />

                                    <div className={classes.inputHolder}>
                                        <input
                                            className={classes.input}
                                            value={this.state.searchText}
                                            placeholder="Search"
                                            onChange={event => {
                                                this.setState({searchText: event.target.value})
                                                this.getSearchAtoms(event.target.value);
                                            }}
                                        />


                                        <SearchIcon width="20" height="20" color="#D8D8D8"/>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div style={{
                            overflow: 'visible', flex: 1, padding: '5px', width: '100%',
                        }}
                        >
                            <div>
                                <NewFlow botId={this.props.match.params.botId} flows={this.props.flows}/>
                                {this.props.flows != null && this.props.flows
                                    .filter(flow => this.checkAtom(flow))
                                    .reverse()
                                    .map((flow) => (
                                        <ExpansionPanel
                                            defaultExpanded={flow.atoms && flow.atoms.length !== 0 && !this.checkAccessToDelete(flow)}
                                            style={{
                                                borderRadius: 10,
                                                margin: '20px 0',
                                                boxShadow: ' 4px 4px 29px rgba(19, 69, 186, 0.0855129)'
                                            }}
                                            className="flowExp"
                                            key={flow.id}
                                        >
                                            <ExpansionPanelSummary
                                                className={classes.mainWrap}
                                                expandIcon={<ExpandMoreIcon/>}>
                                                <div style={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    width: '100%'
                                                }}>
                                                    <Typography className={classes.header}>
                                                        {flow.name}
                                                    </Typography>
                                                    {!flow.atoms && this.checkAccessToDelete(flow) &&
                                                    <DeleteFlow
                                                        selectAtom={atomId => this.selectAtom(atomId)}
                                                        flow={flow}
                                                        atomId={this.state.atomId}
                                                    />}
                                                </div>
                                            </ExpansionPanelSummary>
                                            <ExpansionPanelDetails
                                                style={{display: 'block', paddingTop: 4}}
                                            >
                                                {!this.checkAccessToDelete(flow) && <div style={{
                                                    fontFamily: 'Lato',
                                                    fontSize: 12,
                                                    color: '#616581',
                                                    paddingBottom: 16
                                                }}>
                                                    Start with customizing these messages to make the conversation
                                                    feel personal
                                                </div>}
                                                <div style={{
                                                    flexDirection: 'row',
                                                    display: 'flex',
                                                    flexWrap: 'wrap'
                                                }}
                                                >
                                                    <NewAtom
                                                        flowId={flow.id}
                                                        activeBotId={this.props.activeBot.id}
                                                        flows={this.props.flows}
                                                    />

                                                    {flow.atoms != null && flow.atoms
                                                        .filter(atom => this.checkTextExists(atom))
                                                        .reverse()
                                                        .map((atom, key) => (
                                                            <div className={classes.chip}
                                                                 key={atom.id}
                                                                       style={{
                                                                           backgroundColor: 'white',
                                                                           color: '#616581',
                                                                           padding: 10,
                                                                           borderRadius: 10,
                                                                           display: 'flex',
                                                                           alignItems: 'center',
                                                                           justifyContent: 'space-between',
                                                                           cursor: 'pointer'
                                                                       }}>
                                                                <NavLink

                                                                    style={{color: '#616581'}}
                                                                    to={`${HOME_PATH}/bot/${this.props.match.params.botId}/flows/${atom.id}`}>

                                                                {atom.name}
                                                                </NavLink>
                                                                <div
                                                                    style={{cursor: 'pointer', marginLeft: 10}}
                                                                >
                                                                    {!atom.isHardCoded && <DeleteAtom
                                                                        atom={atom}
                                                                        activeBotId={this.props.match.params.botId}
                                                                        selectWelcomeAtom={()=>this.selectAtom(this.props.flows[0].atoms[0].id)}

                                                                    />}
                                                                </div>

                                                            </div>
                                                        ))}
                                                </div>
                                            </ExpansionPanelDetails>
                                        </ExpansionPanel>
                                    ))
                                }

                            </div>

                        </div>
                    </div>
                    {this.props.flows[0] && this.props.flows[0].atoms[0] &&
                    <div style={{flexBasis: '50%'}}>
                        <div style={{position: 'relative'}}>
                            <Route
                                path={`${HOME_PATH}/bot/:botId/flows/:atomId`}
                                component={AtomEditor}/>
                            {this.renderRedirect()}

                        </div>
                    </div>
                    }
                </div>
            </div>
        );
    }
}

Builder.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired
};

const mapStateToProps = ({flows, activeBot, searchAtoms}) => ({ flows, activeBot, searchAtoms });

export default withRouter(withStyles(styles, {withTheme: true})(connect(mapStateToProps)(Builder)));
