import React, {Fragment, useState} from 'react';
import {withStyles} from "@material-ui/core";
import {withRouter} from "react-router-dom";
import PropTypes from "prop-types";
import Popover from "@material-ui/core/Popover";

import {styles} from "./CommentsFilterStyles";
import {CalendarIcon} from "../../../../uiKit/icons/Icons";
import PopoverItem from "../PopoverItem/PopoverItem";
import {
    ANCHOR_ORIGIN,
    DIRECTION_ASC,
    DIRECTION_DESK,
    SORT_DATE,
    SORT_SCORE,
    TRANSFORM_ORIGIN
} from "./CommentsFilterConfig";

const CommentsFilter = (props) => {
    const {classes, sortDirection, setSortDirection, sortBy, setSortBy} = props;
    const [target, setTarget] = useState(null);
    const [isOpenPopover, setOpenPopover] = useState(false);
    const sortLabel = sortBy === 'score' ? 'Rate' : 'Date';

    return <Fragment>
        <div className={classes.selectButton}
             onClick={() => setOpenPopover(true)}
             ref={node => setTarget(node)}>
                <span>
                    <CalendarIcon width={16}/>
                </span> Sort by: {sortLabel}
        </div>
        <Popover
            classes={{paper: classes.popover}}
            open={isOpenPopover}
            anchorEl={target}
            onClose={() => setOpenPopover(!isOpenPopover)}
            anchorOrigin={ANCHOR_ORIGIN}
            transformOrigin={TRANSFORM_ORIGIN}>

            <PopoverItem
                label={SORT_DATE.label}
                showCheck={sortBy === SORT_DATE.value}
                onClick={() => setSortBy(SORT_DATE.value)}/>
            <PopoverItem
                label={SORT_SCORE.label}
                showCheck={sortBy === SORT_SCORE.value}
                onClick={() => setSortBy(SORT_SCORE.value)}/>

            <div className={classes.divider}/>

            <PopoverItem
                label={DIRECTION_ASC.label}
                showCheck={sortDirection === DIRECTION_ASC.value}
                onClick={() => setSortDirection(DIRECTION_ASC.value)}/>
            <PopoverItem
                label={DIRECTION_DESK.label}
                showCheck={sortDirection === DIRECTION_DESK.value}
                onClick={() => setSortDirection(DIRECTION_DESK.value)}/>

        </Popover>
    </Fragment>
};

CommentsFilter.propTypes = {
    classes: PropTypes.object.isRequired,
    sortDirection: PropTypes.string.isRequired,
    setSortDirection: PropTypes.func.isRequired,
    sortBy: PropTypes.string.isRequired,
    setSortBy: PropTypes.func.isRequired
};

export default withRouter(withStyles(styles)(CommentsFilter));
