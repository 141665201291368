/**
 * Created by Admin on 08.05.2018.
 */

import {alertError, createJsonHeaders, logoutOnRequestOrResponseJson} from "./api";
import {saveAdminUser} from "./tabs/authorization/actions/adminUser";
import {BASE_URL} from "./configs";

export const ROLE = {
    ADMIN: "ROLE_ADMIN",
    SUPPORT: "ROLE_SUPPORT",
};

export const ROLE_OPTIONS = [
    {
        value: ROLE.ADMIN,
        label: "Admin",
        features: ['support', 'dashboard', 'flows', 'atomBuilder', 'settings', 'nlp', 'broadcast', 'widget', 'createBot', 'shareBot', 'analytics'],
    },
    {
        value: ROLE.SUPPORT,
        label: "Support",
        features: ['support', 'dashboard', 'analytics'],
    },
];

export const access = {
    userRole: null,
    botAccesses: [],

    loadRole() {
        fetch(BASE_URL + "/getAdminUser", {
            credentials: 'same-origin',
            method: "GET",
            headers: createJsonHeaders()
        }).then((response) =>
            logoutOnRequestOrResponseJson(response))
            .then((json) => {
                // access.userRole = json.role;
                access.botAccesses = json.botAccesses;
                saveAdminUser(json);
                return json;
            }).catch(function (error) {
            alertError("Sorry but something going wrong please ping support!");
        });
    }
};

export function hasPermissionForBot(botId, featureName) {
    let botAccess = access?.botAccesses?.filter(ba => ba.botId === Number(botId))[0];

    return botAccess && ROLE_OPTIONS?.find(role => role.value === botAccess.role)?.features?.includes(featureName);
}

export function hasPermissionForFeature(featureName) {
    let userRole = access?.botAccesses[0]?.role;

    return ROLE_OPTIONS?.find(role => role.value === userRole)?.features?.includes(featureName);
}
