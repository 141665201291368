export const styles = () => ({
    sectionWrap: {
        overflowY: 'auto',
        height: 'calc(100% - 127px)'
    },
    loaderWrap:{
        height: 'calc(100% - 127px)',
        display:'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    searchContainer:{
        margin: '8px 12px',
    }
});
