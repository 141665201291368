/**
 * Created by Admin on 05.02.2018.
 */
import React from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import {CloseIcon} from "../../../uiKit/icons/Icons.js";
import {deleteAtom} from "../api/atom";
import Modal from "../../../uiKit/Modal";
import SubmitButton from "../../../uiKit/buttons/SubmitButton";

const styles = theme => ({
    buttonsHolder: {
        display: 'flex',
        justifyContent: 'space-around',
        padding: "24px 70px",
    },
});

class DeleteAtom extends React.Component {
    state = {
        open: false,
    };

    handleOpen = () => {
        this.setState({open: true});
    };

    handleClose = () => {
        this.setState({open: false});
    };

    handleAtomDelete = () => {
        this.props.selectWelcomeAtom();
        this.handleClose();
    };

    deleteAtom = () => {
        deleteAtom(this.props.atom.id, this.props.activeBotId, this.handleAtomDelete);
    };

    render() {
        const {classes} = this.props;

        return (
            <div style={{marginLeft: "auto"}}>
                <div onClick={() => this.handleOpen()}>
                    <CloseIcon width='10px' height="10px" color='#B0B2C0'/>
                </div>
                <Modal
                    open={this.state.open}
                    onClose={this.handleClose}
                    title={"Are you sure you want to delete " + this.props.atom.name + " atom?"}
                >
                    <div className={classes.buttonsHolder}>
                        <SubmitButton onClick={this.deleteAtom}
                                      title="Delete"
                        />
                        <SubmitButton onClick={this.handleClose}
                                      empty
                                      title="Cancel"
                        />
                    </div>
                </Modal>
            </div>
        );
    }
}

DeleteAtom.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DeleteAtom);
