export const styles = theme => ({
    alert: {
        color: '#FF624C',
        fontSize: 12
    },
    container: {
        overflowY: 'auto',
        height: '100%'
    },
    statesModal: {
        height: 45,
        padding: '10px 0'
    },
    heading: {
        lineHeight: 'normal',
        fontSize: 18,
        color: '#3A3F62',
        textAlign: 'center'
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 200,
        flexBasis: '60%'
    },
    progress: {
        margin: theme.spacing.unit * 2,
    },
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
    },
    table: {
        minWidth: 500,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    input: {
        padding: "10px",
        border: "none",
        outline: "none",
        width: '90%',
        borderRadius: "5px",
        fontSize: "14px",
        lineHeight: "19px",
        backgroundColor: "white",
        color: '#1658F3',
    },
    enter: {
        width: "10%",
        cursor: 'pointer',
    },
    inputHolder: {
        border: "1px solid #C7CAD6",
        width: "max-content",
        borderRadius: "10px",
        display: "flex",
        justifyContent: 'space-between',
        backgroundColor: "white",
        marginRight: 10,
        padding: 10,
        maxHeight: 45,
        minWidth: "100%",
    },
    userSaysWrapper: {
        maxHeight: '175px',
        overflow: 'auto',
    },
    save: {
        margin: '30px auto 0',
    },
    modalHeading: {
        fontSize: 16,
        color: '#3A3F62',
        fontWeight: 600,
        marginTop: 24
    },
    modalHeadingQuestions: {
        fontSize: 16,
        color: '#3A3F62',
        fontWeight: 600,
        marginTop: 24
    },
    none: {
        display: 'none',
    },
    '@global': {
        '.Select-placeholder': {
            color: '#616581',
            lineHeight: '43px'
        },
        '.Select-input > input': {
            lineHeight: '28px'
        },
        '.Select-value': {
            paddingTop: 4
        },
        '.Select-control': {
            height: 45
        },
    }
});

export const HolderStyles = {
    color: '#3A3F62',
    padding: '10px 24px',
    width: '100%',
    marginRight: 0
};

export const TabTemplateStyles = {
    display: 'block',
    padding: '0'
};
