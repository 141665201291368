import React from "react";
import {  NOTIFICATION_GRANTED } from '../constants/notificationsPermissions';
import {SERVER_URL} from "../configs";
import {toast} from "react-toastify";

function displayCustom(title, body, userId, botId) {
    if (userId && botId) {
        function openChat() {
            window.open(`${SERVER_URL}/admin/bot/${botId}/support/${userId}`, '_blank');
        }
        toast.info(<div>{title}<br />{body}</div>, {
            onClick: openChat
        })
    }
}

function displayNative(title, body, userId, botId) {
    if (Notification.permission === NOTIFICATION_GRANTED) {
        const notification = new Notification(title, { body });
        if (userId && botId) {
            notification.onclick = (e) => {
                e.preventDefault();
                window.open(`${SERVER_URL}/admin/bot/${botId}/support/${userId}`, '_blank');
            }
        }
    }
}

export default {
    askPermission() {
        Notification.requestPermission()
    },

    displayNotification(title, body, options = {}) {
        const { userId, botId } = options;
        if (document.hidden) {
            displayNative(title, body, userId, botId);
        } else {
            displayCustom(title, body, userId, botId);
        }
    }
};
