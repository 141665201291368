export const styles = () => ({
    containerReceived: {
        width: '100%',
        margin: '4px 0px',
        display: 'flex',
        flexDirection: 'row-reverse',
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    containerSend: {
        width: '100%',
        margin: '4px 0px',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    timeZone: {
        fontSize: 12,
        color: '#B0B2C0',
        fontWeight: 300,
        margin: '0px 14px'
    }
});
