import React from 'react';
import {withStyles} from '@material-ui/core';
import PropTypes from 'prop-types';
import classnames from "classnames";

import {OPEN_REQUESTS, SOLVED_REQUESTS} from "./config";

import {styles} from './styles';

const TabsWrap = (props) => {
    const {classes, selectedTab, setSelectedTab} = props;

    const getTabClasses = (tab) => {
        return classnames(classes.tab, {
            [`${classes.activeTab}`]: selectedTab === tab
        })
    };

    return (
        <div className={classes.container}>
            <div className={getTabClasses(OPEN_REQUESTS)}
                 onClick={() => setSelectedTab(OPEN_REQUESTS)}>
                Open requests
            </div>
            <div className={getTabClasses(SOLVED_REQUESTS)}
                 onClick={() => setSelectedTab(SOLVED_REQUESTS)}>
                Solved requests
            </div>
        </div>
    );
};

TabsWrap.propTypes = {
    classes: PropTypes.object,
    selectedTab: PropTypes.string,
    setSelectedTab: PropTypes.func
};

export default withStyles(styles)(TabsWrap);
