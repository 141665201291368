import {SAVE_ATOMS} from "../actions/atoms";


const atoms = (state = [], action) => {
    if (action.type === SAVE_ATOMS) {
        return action.atoms;
    } else {
        return state;
    }
};

export default atoms

