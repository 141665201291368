import React from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import {withRouter} from "react-router-dom";
import {HOME_PATH} from "../../configs";
import Headline from "../../uiKit/texts/Headline";
import {nlpRoutes} from "../../routes/nlpRoutes";
import Submenu from "../../uiKit/submenu/Submenu";
import {getFlows} from "../flowBuilder/api/flow";
import connect from "react-redux/es/connect/connect";
import EmptyNlp from "./components/EmptyNlp";
import Loader from "../../uiKit/loaders/loader";
import {getBot} from "../home/api/bots";

const styles = theme => ({
    loaderWrap: {
        height: 'calc(100vh - 198px)'
    }
});


class NLP extends React.Component {

    state = {
        botHasNlp: false,
        loading: true
    };

    componentDidMount() {
        getFlows(this.props.match.params.botId);
        getBot(this.props.match.params.botId)
            .then(() => {
                this.setState({loading: false})
            })
    }

    render() {
        const pathLink = `${HOME_PATH}/bot/${this.props.match.params.botId}/nlp`;
        const pathRoute = `${HOME_PATH}/bot/:botId/nlp/`;
        const {loading} = this.state;
        const {classes} = this.props;

        return (
            <div>
                <Headline title='NLP Training'/>
                {loading && (
                    <div className={classes.loaderWrap}>
                        <Loader/>
                    </div>
                )}

                {!loading && (this.props.activeBot?.nlpEnabled ? (
                    <Submenu pathLink={pathLink}
                             pathRoute={pathRoute}
                             routes={nlpRoutes}/>
                ) : (
                    <EmptyNlp activeBot={this.props.activeBot}/>
                ))}
            </div>
        )
    }
}

NLP.propTypes = {
    classes: PropTypes.object,
    theme: PropTypes.object,
};

const mapStateToProps = state => (
    {
        activeBot: state.activeBot,
    });


export default withRouter(withStyles(styles, {withTheme: true})(connect(mapStateToProps)(NLP)));
