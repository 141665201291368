/**
 * Created by dshevchenko on 15.04.2018.
 */
import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {withStyles} from '@material-ui/core';
import {connect} from 'react-redux';
import MenuItem from './MenuItem';

const styles = theme => ({
    level: {
        background: '#FFFFFF',
        boxShadow: '4px 4px 29px rgba(19, 69, 186, 0.0855129)',
        borderRadius: 10,
        width: 300,
        height: 'max-content'
    },
    levelHide: {
        width: 300
    },
    heading: {
        width: '100%',
        padding: '16px 0',
        textAlign: 'center',
        fontWeight: 'bold',
        lineHeight: 'normal',
        fontSize: 14,
        color: '#3A3F62',
        fontFamily: 'Lato'
    },
    addSubmenu: {
        padding: 24,
        fontFamily: 'Lato',
        fontSize: 14,
        color: '#B0B2C0',
        cursor: 'pointer',
        borderTop: '1px dashed #EBEBEF'
    },
    icon: {
        position: 'absolute',
        top: 10,
        left: 24,
        cursor: 'pointer'
    },
    '@global': {
        '.addMenu:hover': {
            color: '#616581'
        }
    }
});

class Level extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: null
        };
    }

    updateItem = (item, index) => {
        this.props.updateMenu(item, index);
    }

    addItem() {
        this.props.addMenuitem({
            callToActions: [],
            enableMessengerExtensions: false,
            payload: 'Welcome message',
            title: 'Menu Item',
            type: 'postback',
            url: null,
            webViewHeightRatio: null
        });

        setTimeout(() => {
            this.setState({
                open: this.props.item.callToActions.length - 1
            });
        }, 300);
    }

    setDefault = () => {
        this.setState({
            open: null
        });
    }

    render() {
        const {classes, item} = this.props;
        const {open} = this.state;
        return (
            <div className={item == null ? classes.levelHide : classes.level}>
                <div>
                    {item == null
                        ? <div/> : (
                            <div style={{position: 'relative'}}>
                                <div className= {classes.heading}>
                                    {'Menu'}
                                </div>
                                {item.callToActions && item.callToActions.map((callToAction, index) => (
                                    <MenuItem
                                        menuItem={callToAction}
                                        onUpdate={
                                            (item) => {
                                                this.updateItem(item, index);
                                            }}
                                        setDefault={this.setDefault}
                                        index={index}
                                        deleteItem={index => this.props.deleteItem(index)}
                                        defaultOpen={open}
                                    />
                                ))}
                                {item.callToActions !== undefined
                                && (
                                    <div
                                        className={`${classes.addSubmenu} addMenu`}
                                        onClick={() => this.addItem()}
                                    >
                                        + Click here to add a menu item
                                    </div>
                                )}
                            </div>
                        )}
                </div>
            </div>

        );
    }
}

Level.propTypes = {
    classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    activeBot: state.activeBot
});

export default withRouter(withStyles(styles, {withTheme: true})(connect(mapStateToProps)(Level)));
