import {BASE_URL} from "../../../configs";

import {createJsonHeaders, alertError} from "../../../api";

export const deleteAttributeAPI = (botID, attributeId) => (
  fetch( `${BASE_URL}/bot/${botID}/attribute/${attributeId} `, {
    credentials: 'same-origin',
    headers: createJsonHeaders(),
    method: "DELETE"
  }).catch(error => alertError("Sorry but something going wrong please ping support!"))
)

export const getAttributesAPI = botID => (
  fetch(`${BASE_URL}/bot/${botID}/attribute`, {
    credentials: 'same-origin',
    headers: createJsonHeaders(),
    method: "GET"
  }).then(response => response.json())
    .catch(error => alertError("Sorry but something going wrong please ping support!"))
)

export const setAttributeAPI = (botID, attribute) => (
  fetch(`${BASE_URL}/bot/${botID}/attribute`, {
    credentials: 'same-origin',
    headers: createJsonHeaders(),
    method: "POST",
    body: JSON.stringify(attribute)
  }).catch(error => alertError("Sorry but something going wrong please ping support!"))
)
