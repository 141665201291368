import React from "react";

import {Button} from "@material-ui/core";
import {withStyles} from "@material-ui/core/styles";

import {styles} from "./Training-styles";


function TrainButton(props) {

    const {classes, onClick} = props;
    return <Button variant="raised"
                   color="primary"
                   className={classes.button}
                   onClick={onClick}>
        Train
    </Button>
}

export default withStyles(styles)(TrainButton);