
export const tomezoneList = [
    {value: -12, label: '(UTC-12:00) Baker Island, Howland Island'},
    {value: -11, label: '(UTC-11:00) Niue, American Samoa'},
    {value: -10, label: '(UTC-10:00) Papeete, Honolulu'},
    {value: -9, label: '(UTC-09:00) French Polynesia'},
    {value: -8, label: '(UTC-08:00) Los Angeles, Vancouver, Tijuana'},
    {value: -7, label: '(UTC-07:00) Phoenix, Calgary, Ciudad Juarez'},
    {value: -6, label: '(UTC-06:00) Chicago, Winnipeg, Mexico City'},
    {value: -5, label: '(UTC-05:00) New York, Toronto, Havana'},
    {value: -4, label: '(UTC-04:00) Santiago, Halifax, Santo Domingo'},
    {value: -3, label: '(UTC-03:00) Buenos Aires, Montevideo, Sao Paulo'},
    {value: -2, label: '(UTC-02:00) South Sandwich Islands'},
    {value: -1, label: '(UTC-01:00) Cape Verde, Azores islands'},
    {value: 0, label: '(UTC+00:00) London, Dublin, Lisbon'},
    {value: 1, label: '(UTC+01:00) Berlin, Paris, Rome'},
    {value: 2, label: '(UTC+02:00) Helsinki, Cairo, Jerusalem'},
    {value: 3, label: '(UTC+03:00) Istanbul, Moscow, Baghdad'},
    {value: 4, label: '(UTC+04:00) Baku, Dubai, Samara'},
    {value: 5, label: '(UTC+05:00) Karachi, Tashkent, Yekaterinburg'},
    {value: 6, label: '(UTC+06:00) Almaty, Dhaka, Omsk'},
    {value: 7, label: '(UTC+07:00) Jakarta, Bangkok, Hanoi'},
    {value: 8, label: '(UTC+08:00) Beijing, Singapore, Kuala Lumpur'},
    {value: 9, label: '(UTC+09:00) Seoul, Tokyo, Yakutsk'},
    {value: 10, label: '(UTC+10:00) Port Moresby, Sydney, Vladivostok'},
    {value: 11, label: '(UTC+11:00) Noumea'},
    {value: 12, label: '(UTC+12:00) Auckland, Suva'},
    {value: 13, label: '(UTC+13:00) Apia'},
    {value: 14, label: '(UTC+14:00) Line Islands'}
];
export const timeList = [
    {value: '00:00', label: '12:00 AM'},
    {value: '00:30', label: '12:30 AM'},
    {value: '01:00', label: '1:00 AM'},
    {value: '01:30', label: '1:30 AM'},
    {value: '02:00', label: '2:00 AM'},
    {value: '02:30', label: '2:30 AM'},
    {value: '03:00', label: '3:00 AM'},
    {value: '03:30', label: '3:30 AM'},
    {value: '04:00', label: '4:00 AM'},
    {value: '04:30', label: '4:30 AM'},
    {value: '05:00', label: '5:00 AM'},
    {value: '05:30', label: '5:30 AM'},
    {value: '06:00', label: '6:00 AM'},
    {value: '06:30', label: '6:30 AM'},
    {value: '07:00', label: '7:00 AM'},
    {value: '07:30', label: '7:30 AM'},
    {value: '08:00', label: '8:00 AM'},
    {value: '08:30', label: '8:30 AM'},
    {value: '09:00', label: '9:00 AM'},
    {value: '09:30', label: '9:30 AM'},
    {value: '10:00', label: '10:00 AM'},
    {value: '10:30', label: '10:30 AM'},
    {value: '11:00', label: '11:00 AM'},
    {value: '11:30', label: '11:30 AM'},
    {value: '12:00', label: '12:00 PM'},
    {value: '12:30', label: '12:30 PM'},
    {value: '13:00', label: '01:00 PM'},
    {value: '13:30', label: '01:30 PM'},
    {value: '14:00', label: '02:00 PM'},
    {value: '14:30', label: '02:30 PM'},
    {value: '15:00', label: '03:00 PM'},
    {value: '15:30', label: '03:30 PM'},
    {value: '16:00', label: '04:00 PM'},
    {value: '16:30', label: '04:30 PM'},
    {value: '17:00', label: '05:00 PM'},
    {value: '17:30', label: '05:30 PM'},
    {value: '18:00', label: '06:00 PM'},
    {value: '18:30', label: '06:30 PM'},
    {value: '19:00', label: '07:00 PM'},
    {value: '19:30', label: '07:30 PM'},
    {value: '20:00', label: '08:00 PM'},
    {value: '20:30', label: '08:30 PM'},
    {value: '21:00', label: '09:00 PM'},
    {value: '21:30', label: '09:30 PM'},
    {value: '22:00', label: '10:00 PM'},
    {value: '22:30', label: '10:30 PM'},
    {value: '23:00', label: '11:00 PM'},
    {value: '23:30', label: '11:30 PM'},
];
