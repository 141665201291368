import {SAVE_ACCOUNT_SETTINGS} from "../actions/account";

const accountSettings = (state = null, action) => {
    switch (action.type) {
        case SAVE_ACCOUNT_SETTINGS:
            return action.accountSettings;
        default:
            return state;
    }
};

export default accountSettings;
