export const styles = () => ({
    container: {
        width: '100%',
        borderTop: '1px solid #EBEBEF',
        position: 'relative'
    },
    input: {
        border: 'none',
        width: '100%',
        padding: '0px 70px',
        fontSize: 14,
        height: 54,
        outline: 'none',
        '&::placeholder': {
            color: 'rgba(53, 64, 82, 0.7)'
        }
    },
    iconWrap: {
        cursor: 'pointer',
        padding: '14px 24px',
        height: 54,
        position: 'absolute',
        top: 0,
        right: 0
    }
});
