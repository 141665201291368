import {store} from '../../../index';

export const SAVE_FACEBOOK_TOKEN = 'SAVE_FACEBOOK_TOKEN';

export const saveFacebookToken = (token) => {
	store.dispatch(
		{
			type: SAVE_FACEBOOK_TOKEN,
			token
		}
	);
};
