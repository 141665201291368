export const styles = () => ({
  badge: {
    top: -11,
    right: -11,
    width: 22,
    height: 22,
    display: 'flex',
    zIndex: 1,
    position: 'absolute',
    flexWrap: 'wrap',
    fontSize: '0.75rem',
    alignItems: 'center',
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    alignContent: 'center',
    borderRadius: '50%',
    flexDirection: 'row',
    justifyContent: 'center',
    color: '#ffffff',
    backgroundColor:'#1658F3'
  },
  badgeWrap:{
    display: 'inline-flex',
    position: 'relative',
    verticalAlign: 'middle'
  }
});
