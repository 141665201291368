import { SET_SEARCH_ATOMS } from '../actions/searchAtoms';

const convertSearchAtoms = (searchAtoms) => {
    return searchAtoms.reduce((flowsMap, atom) => {
        if (!flowsMap[atom.flowId]) {
            flowsMap[atom.flowId] = [];
        }
        flowsMap[atom.flowId].push(atom);
        return flowsMap;
    }, {})
};

const searchAtoms = (state = {}, action) => {
    if (action.type === SET_SEARCH_ATOMS) {
        return convertSearchAtoms(action.searchAtoms);
    } else {
        return state;
    }
};

export default searchAtoms;
