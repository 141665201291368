import {store} from '../../../index';

export const SAVE_SUPPORT_STATISTICS = 'SAVE_SUPPORT_STATISTICS';

export const saveSupportStatistics = (stats) => {
	store.dispatch(
		{
			type: SAVE_SUPPORT_STATISTICS,
			stats
		});
};
