export const styles = () => ({
    noChatWrap: {
        flexBasis: '74%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    noChat: {
        color: '#B0B2C0',
        maxWidth: 206,
        textAlign: 'center'
    }
});
