import React from 'react';
import {withRouter} from "react-router-dom";
import {SERVER_URL} from "../../../configs";

class MobileSupport extends React.Component {
    async componentDidMount() {
        let html = await fetch(`${SERVER_URL}${this.props.match.url}${this.props.location.search}`)
            .then((response) => {
                    return response.text()
                }
            );
        window.document.write(html);
    }

    render(){
        return null;
    }
}

export default withRouter((MobileSupport));
