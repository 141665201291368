import React, {useContext, useState} from 'react';
import {withStyles} from '@material-ui/core';
import PropTypes from 'prop-types';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";

import SendIcon from "../../../../uiKit/icons/SendIcon";
import {SharedWebsocketContext} from "../../../../contexts/webSockets";
import {CHAT_PLATFORM} from "../../../../constants/chatPlatform";
import {SUPPORT_STATUSES} from '../../../../constants/suportStatuses'
import {CHAT_STATUS} from '../../../../constants/chatStatus'
import {styles} from './styles';
import UploadButton from "../UploadButton/UploadButton";
import {assignSupportRequest} from "../../api/user";
import {saveActiveUser} from "../../actions/activeUser";
import {getMinutesLeft} from "../../../../helpers/getMinutesLeft";

const ChatInput = (props) => {
    const {classes, user, adminUserId, activeBot} = props;
    const {sendMessage} = useContext(SharedWebsocketContext);
    const [textMessage, setTextMessage] = useState('');
    const minutesLeft = user && getMinutesLeft(user.userMessageDatetime) < 0;
    const isAssignedToMe = adminUserId === user.supportRequest?.support?.adminId;
    const disableInput = user?.chatStatus === CHAT_STATUS.DISCONNECTED
        || (user?.platform === CHAT_PLATFORM.WHATSAPP && minutesLeft)
        || (user.supportRequest?.status === SUPPORT_STATUSES.ASSIGNED && !isAssignedToMe);
    const isRtl = user?.messages[0]?.isRtl;


    const handleSendMessage = () => {
        if (disableInput) return;
        if (isAssignedToMe) {
            onSendMessage();
        } else {
            const json = {
                supportRequestId: user.supportRequest?.id,
                userId: user.userId,
                adminId: adminUserId
            };

            const userCopy = Object.assign({}, user);

            userCopy.supportRequest = {
                status: SUPPORT_STATUSES.ASSIGNED,
                support: {
                    adminId: adminUserId
                },
                userId: user.userId
            };

            assignSupportRequest(activeBot.id, json)
                .then(() => {
                    saveActiveUser(userCopy);
                })
                .then(onSendMessage)
        }
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter' && textMessage) {
            handleSendMessage()
        }
    };

    const onSendMessage = () => {
        const message = {
            adminId: adminUserId,
            text: textMessage,
            recipient: user.userId
        };
        sendMessage(message);
        setTextMessage('')
    };

    return (
        <div className={classes.container}>
            <UploadButton disabled={disableInput}/>
            <input className={classes.input}
                   onChange={(e) => setTextMessage(e.target.value)}
                   value={textMessage}
                   disabled={disableInput}
                   placeholder='Some message goes here'
                   dir={isRtl ? 'rtl' : 'ltr'}
                   onKeyPress={handleKeyPress}/>
            <span className={classes.iconWrap}
                  onClick={handleSendMessage}>
                <SendIcon color={disableInput && '#828282'}/>
            </span>
        </div>
    );
};

ChatInput.propTypes = {
    classes: PropTypes.object,
    user: PropTypes.object,
    adminUserId: PropTypes.number,
};

const mapStateToProps = (state) => ({
    user: state.activeUser,
    adminUserId: state.adminUser.id,
    activeBot: state.activeBot,
});

export default withRouter(withStyles(styles)(connect(mapStateToProps)(ChatInput)));
