import { useState } from "react";

import { getAttributesAPI, setAttributeAPI, deleteAttributeAPI } from "../../api/attributes";
import { alertError, alertSuccess } from "../../../../api";

export const useAttributes = botID => {
  const [attributes, setAttributes] = useState([]);

  const getAttributes = () => {
    getAttributesAPI(botID).then(response => setAttributes(attributes.concat(response)))
  }

  const setAttribute = (attributeIndex ,attribute) => {
    const array = [...attributes]
    array.splice(attributeIndex, 1, attribute)

    let isSameName = false;

    const name = attribute.name;
    const value = attribute.value;
    const id = attribute.id;

    attributes.forEach(item => {
      if (item.name === name && item.id !== id) {
        isSameName = true
      }
    })

    if (isSameName) {
      setTimeout(() => {
        alertError("Attribute name already exists");
      }, 500)
      return
    }

    setAttributes(array)

    if (!name || !value) {
      return
    }

    setAttributeAPI(botID, attribute)
      .then(response => {
        if (response.status === 200) {
          setTimeout(() => {
            alertSuccess(`Attribute successfully ${attribute.id ? "updated" : "created"}!`)
          }, 100)
        }
      })
  }

  const createAttribute = () => {
    const emptyAttribute = {
      name: "",
      value: ""
    }

    setAttributes(attributes.concat(emptyAttribute))
  }

  const deleteAttribute = (attributeIndex, attributeId) => {
    const array = [...attributes];
    const newArrayAttributes = array.slice(0, attributeIndex).concat(array.slice(attributeIndex + 1, array.length))

    if (!attributeId) {
      setAttributes(newArrayAttributes)
    } else {
      deleteAttributeAPI(botID, attributeId).then(() => {
        setTimeout(() => {
          alertSuccess(`Attribute successfully deleted!`)
        }, 100)
        setAttributes(newArrayAttributes)
      })
    }


  }

  return ({
    attributes,
    getAttributes,
    setAttribute,
    deleteAttribute,
    createAttribute
  })
}
