import React, {useEffect, useState} from 'react';
import {withStyles} from '@material-ui/core';
import PropTypes from 'prop-types';
import Popover from "@material-ui/core/Popover";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";

import {assignSupportRequest, getAdminsList} from "../../api/user";

import {styles} from './styles';
import {saveActiveUser} from "../../actions/activeUser";

const AssignToPopover = (props) => {
    const {classes, user, match, title, adminUser} = props;
    const [isOpenPopover, setOpenPopover] = useState(false);
    const [target, setTarget] = useState(null);
    const [adminsList, setAdminsList] = useState([]);

    useEffect(() => {
        getAdminsList(match.params.botId)
            .then((admins) => {
                if (admins) {
                    const filteredAdmins = admins.filter(admin => admin.name && admin.id !== adminUser.id);
                    setAdminsList(filteredAdmins)
                }
            })
    }, []);

    const handleAssign = (admin) => {
        const json = {
            supportRequestId: user.supportRequest.id,
            userId: user.userId,
            adminId: admin.id
        };
        const userCopy = Object.assign({}, user);

        userCopy.supportRequest = {
            status: 'ASSIGNED',
            support: {
                adminId: admin.id,
                name: admin.name
            },
            userId: user.userId
        };

        assignSupportRequest(match.params.botId, json)
            .then(() => {
                saveActiveUser(userCopy);
            });
    };

    return (
        <>
            <button className={classes.button}
                    ref={node => setTarget(node)}
                    disabled={!adminsList.length}
                    onClick={() => setOpenPopover(!isOpenPopover)}>
                {title}
            </button>
            <Popover
                open={isOpenPopover}
                anchorEl={target}
                elevation={2}
                anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                transformOrigin={{vertical: 'top', horizontal: 'right'}}
                onClose={() => setOpenPopover(!isOpenPopover)}>
                <div className={classes.popover}>
                    {adminsList
                        .filter(admin => admin.name && admin.id !== adminUser.id)
                        .map(admin => (
                            <div key={admin.id}
                                 className={classes.adminItem}
                                 onClick={() => {
                                     setOpenPopover(!isOpenPopover);
                                     handleAssign(admin);
                                 }}>
                                {admin.name}
                            </div>
                        ))}
                </div>
            </Popover>
        </>
    );
};

AssignToPopover.propTypes = {
    classes: PropTypes.object,
    user: PropTypes.object,
    adminUser: PropTypes.object,
    title: PropTypes.string
};

const mapStateToProps = (state) => ({
    user: state.activeUser,
    adminUser: state.adminUser
});

export default withRouter(withStyles(styles)(connect(mapStateToProps)(AssignToPopover)));
