export const styles = () => ({
    image: {
        display: "block",
        width: "100%",
        height: "100%",
        margin: "auto",
        objectFit: "cover",
        objectPosition: "top"
    },
    dropzone: {
        display: "flex",
        position: "relative",
        width: 120,
        height: 120,
        borderRadius: "50%",
        overflow: "hidden",
        cursor: "pointer",
    },
    hoverBlock: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        position: "absolute",
        width: "100%",
        height: "100%",
        background: "linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(.png)",
        color: "#ffffff",
        textAlign: "center",
        opacity: 0,
        transition: "opacity .2s ease-out",

        "& p": {
            margin: "4px 0 0",
            fontSize: 14,
            lineHeight: "120%"
        },

        "&:hover": {
            opacity: 1
        }
    }
});
