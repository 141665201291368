import React, { useState, useEffect } from "react";
import {withStyles} from '@material-ui/core';
import classnames from "classnames";

import {alertError} from "../../../../api";


import { TrashDarkIcon } from "../../../../uiKit/icons/Icons";

import { styles } from "./styles";

const Attribute = props => {
  const { classes } = props;
  const [ name, setName ] = useState(props.attribute.name)
  const [ value, setValue ] = useState(props.attribute.value)
  const [ errorName, setErrorName ] = useState(false)
  const [ errorValue, setErrorValue ] = useState(false)
  const [ newAttribute, setNewAttribute ] = useState(null);

  useEffect(() => {
    if (newAttribute) {
      setErrorName(false)
      setErrorValue(false)

      if (!value) {
        setErrorValue(true)
        setTimeout(() => {
          alertError("Sorry but field 'Value' can't be empty!");
        }, 100)
      }
      if (!name) {
        setErrorName(true)
        setTimeout(() => {
          alertError("Sorry but field 'Name' can't be empty!");
        }, 100)
      }
    }
  }, [newAttribute])

  useEffect(() => {
    if (props.attribute.name !== name) {
      setName(props.attribute.name)
    }
    if (props.attribute.value !== value) {
      setValue(props.attribute.value)
    }
  }, [props.attribute])

  const handleChangeName = e => setName(e.target.value)

  const handleChangeValue = e => setValue(e.target.value)

  const onBlur = () => {
    const attribute = {
      name,
      value,
      id: props.attribute.id
    }

    setNewAttribute(attribute)

    props.setAttribute(props.index, attribute)
  }

  const handleDelete = () => props.deleteAttribute(props.index, props.attribute.id);

  return (
    <div className={classes.attribute}>
      <div className={classes.column}>
        <input
          onBlur={onBlur}
          onChange={handleChangeName}
          type="text"
          name="name"
          placeholder="Enter name here"
          value={name}
          style={errorName ? {border: "1px solid #FF624C"} : null}
        />
      </div>
      <div className={classes.column}>
        <input
          onBlur={onBlur}
          onChange={handleChangeValue}
          type="text"
          name="value"
          value={value}
          placeholder="Enter value here"
          style={errorValue ? {border: "1px solid #FF624C"} : null}
        />
        <span
          onClick={handleDelete}
          className={classnames(classes.deleteIcon, "deleteIcon")}
        >
          <TrashDarkIcon />
        </span>
      </div>
    </div>
  )
}

export default withStyles(styles, {withTheme: true})(Attribute);
