import {store} from '../../../index';

export const SAVE_ATOMS = 'SAVE_ATOMS';

export const saveAtoms = (atoms) => {
	store.dispatch(
		{
			type: SAVE_ATOMS,
			atoms
		}
	);
};
