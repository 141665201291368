import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import LoaderSmall from '../loaderSmall'

const styles = theme => ({
    loaderOverlayContainer: {
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        top: '0',
        bottom: '0',
        left: '0',
        right: '0',
        background: 'rgba(255, 255, 255, 0.6)',
        zIndex: '10000'
    },
});

const LoaderOverlay = ({classes}) => {
    return (
        <div className={classes.loaderOverlayContainer}>
            <LoaderSmall showLoader={true}/>
        </div>
    );
}

export default withStyles(styles, {withTheme: true})(LoaderOverlay);
