import {SAVE_BROADCASTS} from "../actions/broadcasts";

const broadcasts = (state = [], action) => {
    if (action.type === SAVE_BROADCASTS) {
        return action.broadcasts;
    } else {
        return state;
    }
};

export default broadcasts
