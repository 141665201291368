/**
 * Created by Admin on 08.02.2018.
 */
import React, {Component} from "react";
import Select from "react-select";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core";
import * as ReactDOM from "react-dom";


const styles = theme => ({
    '@global': {
        '.drop-up .Select-menu-outer': {
            bottom: '100% !important',
            top: 'auto',
            marginTop: 0,
            marginBottom: -1,
        },

    }
});


class States extends Component {
    state = {
        states: [],
        showHardcoded: true,
    };

    handleChangeState(option) {
        this.setState({
            selectedOption: option.value,
        });
        let id = this.state.states.find(item => item.value === option.value);

        if (option != null && id !== undefined) {
            id = this.state.states.find(item => item.value === option.value).id;
        }
        else {
            id = null;
        }

        this.props.payload(option.value, id);
    };

    componentDidMount() {
        let states = [];
        if (this.props.timezone != true) {
            this.props.flows && this.props.flows.forEach(flow => {
                flow.atoms != null && flow.atoms.forEach(atom => {
                    states.push({
                        label: atom.name,
                        value: atom.name,
                        id: atom.id,
                    })
                })
            });
        }

        this.setState({
            states,
            selectedOption: this.props.value ? {value: this.props.value, label: this.props.value} : undefined
        });

        if (this.props.showHardcoded != null) {
            this.setState({showHardcoded: this.props.showHardcoded});
        }
    }

    componentWillReceiveProps(nextProps) {
        let states = [];
        nextProps.flows && nextProps.flows.forEach(flow => {
            flow.atoms != null && flow.atoms.forEach(atom => {
                if (!atom.isHardCoded || this.state.showHardcoded)
                    states.push({
                        label: atom.name,
                        value: atom.name,
                        id: atom.id,
                    })
            })
        });
        this.setState({
            states,
            selectedOption: nextProps.value ? {value: nextProps.value, label: nextProps.value} : undefined
        });
    }

    render() {
        const {selectValue} = this.state;
        const {holderStyle,isBackToBot} = this.props;
        let positionValue=this.select!=null && ReactDOM.findDOMNode(this.select).getBoundingClientRect().top>500;
        return (
            <div style={holderStyle}
            >
                <Select
                    required={this.props.required}
                    onSelectResetsInput={false}
                    options={this.state.states}
                    ref={node => {
                        this.select = node;
                    }}
                    simpleValue
                    menuPlacement={`${positionValue ? 'top' : 'bottom'}`}
                    clearable={true}
                    placeholder={isBackToBot ? 'Without any messages' : 'Select'}
                    style={{
                        borderRadius: 10,
                        width: '100%',
                        fontFamily: 'Lato, sans-serif',
                        height: 42.4,
                        border: ((typeof selectValue === 'undefined') || selectValue == null) && this.props.isCurrent ? '1px solid #FF624C' : '1px solid #ccc'
                    }}
                    value={this.state.selectedOption}
                    name="selected-state"
                    onChange={(event) => this.handleChangeState(event)}
                    searchable={true}
                />
            </div>
        );
    }
}

States.propTypes = {
    classes: PropTypes.object.isRequired,
};


const mapStateToProps = (state) => (
    {
        flows: state.flows,
    });

export default withStyles(styles)(connect(mapStateToProps)(States));
