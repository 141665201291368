import {store} from '../../../index';

export const SAVE_USERS_STATISTICS = 'SAVE_USERS_STATISTICS';

export const saveUsersStatistics = (stats) => {
	store.dispatch(
		{
			type: SAVE_USERS_STATISTICS,
			stats
		});
};
