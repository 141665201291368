import {createJsonHeaders} from "../../../api";
import {BACKEND_URL} from "../../../configs";

export function getLoginPageElement(callback) {
    fetch(`${BACKEND_URL}/login/page/element`, {
        credentials: 'same-origin',
        headers: createJsonHeaders(),
        method: "GET",
    }).then((response) => {
        if (response.ok) {
            return response.json();
        }
    }).then((json) => {
        callback(json);
    });
}
