import React, {Component} from "react";
import Login from "../tabs/authorization/component/Login";
import {connect} from "react-redux";
import "react-notifications/lib/notifications.css";
import {withStyles} from "@material-ui/core";
import "react-s-alert/dist/s-alert-default.css";
import "react-s-alert/dist/s-alert-css-effects/slide.css";
import Menu from "./Menu/Menu";
import {BrowserRouter, Redirect, Route, Switch, withRouter} from "react-router-dom";
import Bots from "../tabs/home/Home";
import {HOME_PATH} from "../configs";
import NotificationSnackbar from "../uiKit/Notification/Notification";
import Unavailable from "./Unavailable/Unavailable";
import MobileSupport from "../tabs/home/components/MobileSupport";
import EmailConfirmation from "../tabs/authorization/component/EmailConfirmation";
import AfterSignUp from "../tabs/authorization/component/AfterSignUp";
import {NOTIFICATION_DEFAULT} from '../constants/notificationsPermissions';
import NotificationService from '../services/notificationService';
import {StyledToastContainer} from "../tabs/widget/components/StyledToastContainer";
import {AccountContainer} from '../tabs/account/AccountContainer';

const styles = () => ({
    app: {
        width: "100%"
    }
});

class App extends Component {
    askForNotification() {
        if (Notification.permission === NOTIFICATION_DEFAULT) {
            NotificationService.askPermission();
        }
    }

    render() {
        this.askForNotification();
        const {classes, security} = this.props;
        return (
            <div className={classes.app}>
                <BrowserRouter>
                    <Switch>
                        <PrivateRoute isAuthenticated={security.authenticated} exact path={HOME_PATH + "/"} component={Bots}/>
                        <Route path={"/login"} component={Login}/>
                        <Route exact path={"/sign-up"} component={Login}/>
                        <Route exact path={"/forgot-password"} component={Login}/>
                        <Route exact path={"/reset-password"} component={Login}/>
                        <Route exact path={'/sign-up/confirm'} component={AfterSignUp}/>
                        <Route path={"/sign-up/:code"} component={Login}/>
                        <Route path={"/unavailable"} component={Unavailable}/>
                        <Route path={"/link-expired"} component={() => <Unavailable linkExpired={true}/>}/>
                        <Route path={'/mobileSupport'} component={MobileSupport}/>
                        <Route path={'/mail-confirm'} component={EmailConfirmation}/>

                        <PrivateRoute isAuthenticated={security.authenticated} exact path={"/account-settings"} component={AccountContainer} />
                        <PrivateRoute isAuthenticated={security.authenticated} path={HOME_PATH + "/bot/:botId/"} component={Menu}/>
                        <Redirect from='/' to='/admin'/>
                    </Switch>
                </BrowserRouter>
                <NotificationSnackbar/>
                <StyledToastContainer/>
            </div>
        )
    }
}

const PrivateRoute = ({component: Component, isAuthenticated, ...rest}) => (
    <Route
        {...rest}
        render={props =>
            isAuthenticated ? (
                <Component {...props} />
            ) : (
                <Redirect
                    to={{
                        pathname: "/Login",
                        state: {from: props.location}
                    }}
                />
            )
        }
    />
);

const mapStateToProps = (state) => (
    {
        security: state.security,
        adminUser: state.adminUser,
        activeBot: state.activeBot,
    });


export default withRouter(withStyles(styles)(connect(mapStateToProps)(App)));
