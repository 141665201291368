import {store} from '../../../index';

export const SHOW_SNACKBAR = 'SHOW_SNACKBAR';

export const showSnackBar = (snackBar) => {
	store.dispatch(
		{
			type: SHOW_SNACKBAR,
			snackBar
		}
	);
};
