import React from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import {deleteContext} from "../api/context";
import {TrashIcon} from '../../../uiKit/icons/Icons.js';
import {Dialog, DialogActions, DialogTitle, IconButton, Slide} from "@material-ui/core";

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const styles = theme => ({
    paper: {

        width: theme.spacing.unit * 50,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 4,
    },
    flow: {
        boxShadow: '4px 4px 29px rgba(19, 69, 186, 0.14)',
        borderRadius: 10,
    },
    button: {
        background: '#1658F3',
        boxShadow: '0px 3px 9px rgba(19, 69, 186, 0.206267)',
        borderRadius: 10,
        margin: '40px 0',
        color: 'white',
        fontFamily: 'Lato, sans-serif'
    },
    "@global": {
        "[class*='MuiDialog-paperWidthSm']": {
            boxShadow: '4px 4px 29px rgba(19, 69, 186, 0.14)',
            borderRadius: 10
        },
        ".buttonHover:hover": {
            background: 'white',
            color: '#1658F3'
        },
        ".buttonHover1:hover": {
            color: 'white',
            background: '#1658F3'
        },
        ".buttonHover1": {
            background: 'white',
            color: '#1658F3'
        }
    }
});

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

class DeleteTrigger extends React.Component {
    state = {
        open: false,
    };

    handleOpen = () => {
        this.setState({open: true});
    };

    handleClose = () => {
        this.setState({open: false});
    };

    deleteTrigger() {
        if (this.props.newContext) {
            this.props.changeNewContext()
        }
        else {
            deleteContext(this.props.triggerId, this.props.contextSize);
            this.setState({open: false});
        }
    }

    render() {
        const {classes} = this.props;

        return (
            <div style={{
                position: 'absolute',
                right: 0
            }}>
                <IconButton className='trashIcon' style={{
                    background: 'white', border: '1px solid #EBEBEF',
                    boxShadow: '0px 3px 6px #EBEBEF', width: 40, height: 40
                }} aria-label="Delete" onClick={() => this.handleOpen()}>
                    <TrashIcon width='20px' height='20px' color='#B0B2C0'/>
                </IconButton>
                <Dialog

                    PaperProps={{}}
                    open={this.state.open}
                    transition={Transition}
                    keepMounted
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle id="alert-dialog-slide-title">
                        {"Are you sure you want to delete this trigger?"}
                    </DialogTitle>
                    <DialogActions style={{display: 'flex', justifyContent: 'space-around'}}>
                        <Button className={`${classes.button} buttonHover`} onClick={() => this.deleteTrigger()}
                                color="primary">
                            Delete
                        </Button>
                        <Button className={`${classes.button} buttonHover1`} onClick={this.handleClose}>
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

DeleteTrigger.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DeleteTrigger);
