import {SAVE_CONTEXTS_SIZE, ADD_CONTEXTS_SIZE, UPDATE_CONTEXTS_SIZE} from "../actions/contextSize";

const contextSize = (state = [], action) => {
  let contextSize;
  switch (action.type) {
    case SAVE_CONTEXTS_SIZE:
      return action.contextSize;
    case ADD_CONTEXTS_SIZE:
      contextSize = action.contextSize + 1;
      return contextSize;
    case UPDATE_CONTEXTS_SIZE:
      contextSize = action.contextSize - 1;
      return contextSize;
    default:
      return state;
  }
};

export default contextSize
