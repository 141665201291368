import React from 'react';
import {withStyles} from '@material-ui/core';
import PropTypes from 'prop-types';

import MessageWrap from "../MessageWrap/MessageWrap";
import GenericMessage from "../GenericMessage/GenericMessage";
import Slider from "../../../../uiKit/slider/Slider";

import {styles} from './styles';

const CarouselMessage = (props) => {
    const {classes, timezone, isReceived, payload, isRtl} = props;

    return (
        <MessageWrap timezone={timezone} isReceived={isReceived}>
            <Slider itemLength={payload.elements?.length}>
                <div className={classes.genericContainer}>
                    {payload.elements?.map((element) => (
                        <GenericMessage element={element}
                                        isRtl={isRtl}/>
                    ))}
                </div>
            </Slider>
        </MessageWrap>
    );
};

CarouselMessage.propTypes = {
    classes: PropTypes.object,
    timezone: PropTypes.string,
    isReceived: PropTypes.bool,
    isRtl: PropTypes.bool,
    payload: PropTypes.object
};

export default withStyles(styles)(CarouselMessage);
