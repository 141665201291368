export const addOptions = (languages) => {
    const formattedLanguages = [...languages];
    return formattedLanguages.map(language => ({
        value: language.shortName,
        label: language.fullName,
        id: language.id,
        shortName: language.shortName,
        fullName: language.fullName,
    }));
};

export const removeOptionsFromLanguage = (language) => {
    return {
        id: language.id,
        shortName: language.shortName,
        fullName: language.fullName,
    }
};

export const removeLanguageFromArrayByShortName = (array, shortName) => {
    const newArray = [...array];
    newArray.forEach((language, index) => {
        if (language.shortName === shortName) {
            newArray.splice(index, 1);
        }
    });
    return newArray;
};
