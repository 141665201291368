import React from "react";
import {connect} from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";

import {styles} from "./languagesStyles"
import HeaderView from "./HeaderView";
import {deleteLanguage, getBotLanguages} from "../../api/settings";
import ActiveLanguagesListView from "./ActiveLanguagesListView";
import RowView from "./RowView";
import NewLanguageModal from "./NewLanguageModal";
import Modal from "../../../../uiKit/Modal";
import Loader from "../../../../uiKit/loaders/loader";

class Languages extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeLoader: false,
            addLanguageModal: false,
            deleteLanguageModal: null,
            botDefaultLanguage: {},
            botActiveLanguagesList: [],
            botAvailableLanguagesList: [],
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.botActiveLanguagesList && nextProps.botActiveLanguagesList !== prevState.botActiveLanguagesList) {
            return {
                botActiveLanguagesList: nextProps.botActiveLanguagesList
            }
        }
        return null;
    }

    componentDidMount() {
        getBotLanguages(this.props.activeBot.id).then(botLanguages => {

            if (botLanguages) {
                let {defaultLanguage, activeLanguages, availableLanguages} = botLanguages;

                this.setState({
                    botDefaultLanguage: defaultLanguage,
                    botActiveLanguagesList: activeLanguages,
                    botAvailableLanguagesList: availableLanguages,
                });
            }
        })
    }

    setActiveLoader = (active) => {
        this.setState({
            activeLoader: active,
        })
    };

    setAddLanguageModal = (value) => {
        this.setState({
            addLanguageModal: value,
        });
    };

    setDeleteLanguageModal = (value) => {
        this.setState({
            deleteLanguageModal: value,
        });
    };

    deleteLanguage = (shortName) => {
        const newBotActiveLanguagesList = this.state.botActiveLanguagesList.filter(language => language.shortName !== shortName);
        this.setActiveLoader(true);
        deleteLanguage(this.props.activeBot.id, shortName).then(r => {
            this.setState({
                botActiveLanguagesList: newBotActiveLanguagesList,
                activeLoader: false,
            });
        });

    };

    addLanguageToBot = (language) => {
        const {botActiveLanguagesList} = this.state;
        const present = botActiveLanguagesList.some(lan => lan.shortName === language.shortName);

        if (!present) {
            botActiveLanguagesList.push(language);
            this.setState({
                botActiveLanguagesList: botActiveLanguagesList,
            });
        }
    };

    render() {
        const {botActiveLanguagesList, botAvailableLanguagesList, addLanguageModal, deleteLanguageModal} = this.state;
        const {classes} = this.props;

        return (
            <React.Fragment>
                {this.state.activeLoader
                    ? <Loader/>
                    :
                    <React.Fragment>
                        <HeaderView
                            onButtonClick={() => this.setAddLanguageModal(true)}
                            availableLanguagesLength={this.state.botAvailableLanguagesList.length}
                        />
                        <ActiveLanguagesListView>
                            {
                                botActiveLanguagesList
                                    .filter(language => language !== null)
                                    .map((language, index) => (
                                    <RowView
                                        key={language.id}
                                        language={language}
                                        index={index}
                                        onLanguageDelete={this.deleteLanguage}
                                        deleteModal={deleteLanguageModal}
                                        setDeleteModal={this.setDeleteLanguageModal}
                                    />
                                ))
                            }
                            <Modal
                                classes={{paper: classes.paper}}
                                open={addLanguageModal}
                                onClose={() => this.setAddLanguageModal(false)}
                                style={{overflowY: 'visible',}}
                            >
                                <NewLanguageModal
                                    availableLanguages={botAvailableLanguagesList}
                                    closeAddLanguageModal={() => this.setAddLanguageModal(false)}
                                    updateSelectedLanguage={this.addLanguageToBot}
                                    setActiveLoader={this.setActiveLoader}
                                />
                            </Modal>
                        </ActiveLanguagesListView>
                    </React.Fragment>
                }
            </React.Fragment>

        );
    }
}

const mapStateToProps = (state) => (
    {
        activeBot: state.activeBot,
    });

export default withStyles(styles, {withTheme: true})(connect(mapStateToProps)(Languages));
