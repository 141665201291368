export const styles = () => ({
    userName: {
        color: '#354052',
        fontSize: 16,
        display: 'flex',
        alignItems: 'center'
    },
    userNameWrap: {
        display: 'flex',
        flexDirection: 'column'
    },
});
