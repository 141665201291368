import React from "react";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import uuid from 'uuid';
import withStyles from "@material-ui/core/styles/withStyles";
import TableRow from "../../../../uiKit/table/TableRow";
import TableCell from "../../../../uiKit/table/TableCell";
import Table from "../../../../uiKit/table/Table";
import Select from "react-select";
import DeleteAccess from "./DeleteAccess";
import Button from "@material-ui/core/Button";
import Modal from "../../../../uiKit/Modal";
import {SearchIcon} from "../../../../uiKit/icons/Icons";
import ShareModal from "../../../home/components/ShareModal";
import {
    loadBotAccessors,
    removeBotAccessor, updateBotAccessorRole,
    updateBotAccessorSupportRequestNotifying
} from "../../api/settings";
import {ROLE_OPTIONS} from "../../../../security";
import Heading from "../../../../uiKit/texts/Heading";
import {Checkbox, FormControlLabel} from "@material-ui/core";

const baseUserImage = '/images/platform/default-user-icon.svg';
const supportRequestNotifyTooltipText = "Send email notifications about new customer support requests.";
const styles = (theme) => ({
    inputHolder: {
        border: "1px solid #C7CAD6",
        width: "max-content",
        borderRadius: "10px",
        display: "flex",
        justifyContent: 'space-between',
        backgroundColor: "white",
        marginRight: 10,
        padding: 10,
        maxHeight: 45,
        minWidth: 340
    },
    input: {
        width: "100%",
        padding: "10px",
        border: "none",
        outline: "none",
        borderRadius: "5px",
        fontSize: "14px",
        lineHeight: "19px",
        backgroundColor: "white",
    },
    submitButton: {
        background: 'linear-gradient(285.2deg, #5A98F7 -35.9%, #1658F3 61.17%)',
        boxShadow: '0px 3px 9px rgba(19, 69, 186, 0.206267)',
        borderRadius: 10,
        height: 45,
        outline: "none",
    },
    submitButtonText: {
        color: "white",
        margin: 0,
        fontWeight: 500,
        fontSize: 16,
        textTransform: 'none',
        fontFamily: 'Lato, sans-serif',
    },
    paper: {
        padding: "30px 50px 24px",
    },
    tableCellData: {
        margin: "auto 0"
    },
    userImageContainer: {
        height: 40,
        flex: "0 0 40px",
        position: "relative",
        marginRight: 8
    },
    userImage: {
        display: "block",
        width: "100%",
        height: "100%",
        objectFit: "cover",
        objectPosition: "top",
        borderRadius: "50%",
        overflow: "hidden",
    },
    userStatus: {
        position: "absolute",
        boxSizing: "border-box",
        width: 10,
        height: 10,
        border: "2px solid #ffffff",
        borderRadius: "50%",
        bottom: 0,
        right: 0
    }
});

class Users extends React.Component {
    state = {
        open: false,
        searchUser: "",
    };
    removeAccess = (id, inviteAccessId) => {
        removeBotAccessor(this.props.match.params.botId, id, inviteAccessId);
    };
    closeShareModal = () => {
        this.setState({open: false})
    };

    componentDidMount() {
        loadBotAccessors(this.props.match.params.botId);
    }

    handleSearchUser(value) {
        this.setState({searchUser: value});
    }

    updateRole(id, inviteAccessId, newRole) {
        updateBotAccessorRole(this.props.match.params.botId, id, inviteAccessId, newRole);
    };

    updateSupportNotifying(id, supportRequestNotify) {
        updateBotAccessorSupportRequestNotifying(this.props.match.params.botId, id, supportRequestNotify);
    };

    render() { //NOSONAR
        let botAccessors = this.props.botAccessors;
        const {classes} = this.props;
        return <div>
            <Heading>Users</Heading>

            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: 20}}>
                <div style={{display: "flex", alignItems: "center"}}>
                    <div className={classes.inputHolder}>
                        <input className={classes.input}
                               placeholder="Search admin panel users by name"
                               onChange={(event) => this.handleSearchUser(event.target.value)}/>
                        <SearchIcon width='20' height='20' color='#D8D8D8'/>
                    </div>
                </div>
                <Button className={classes.submitButton} onClick={() => this.setState({open: true})}>
                    <p className={classes.submitButtonText}>Invite new user</p>
                </Button>
            </div>
            <Table titles={[
                {title: "Full name", maxWidth: 210},
                {title: "Email", maxWidth: 210},
                {title: "Role", maxWidth: 120},
                {
                    title: "Send notifications",
                    tooltipText: supportRequestNotifyTooltipText,
                    maxWidth: 100
                },
                "Action"]}>
                {!(botAccessors && botAccessors.length) ? <div/> :
                    botAccessors
                        .sort(ba => !ba.isInvited ? -1 : 1)
                        .filter(ba => ba.email.toUpperCase().indexOf(this.state.searchUser.toUpperCase()) !== -1)
                        .map((accessor, index) =>
                            <TableRow key={uuid()} index={index}>
                                {console.log(accessor)}
                                <TableCell styles={{
                                    maxWidth: 210,
                                    display: "flex",
                                    alignItems: "center"
                                }}>
                                    <div className={classes.userImageContainer}>
                                        <img className={classes.userImage}
                                             src={accessor?.imageUrl || baseUserImage}
                                             alt="user image"/>
                                        <span
                                            className={classes.userStatus}
                                            style={{background: accessor.active ? "#11C314" : "#969DA8"}}/>
                                    </div>
                                    {accessor.isInvited ?
                                        <p className={classes.tableCellData}><i>{accessor.name}</i></p>
                                        : <p className={classes.tableCellData}>{accessor.name}</p>
                                    }

                                </TableCell>
                                <TableCell styles={{maxWidth: 210}}>
                                    <p
                                        className={classes.tableCellData}
                                        style={{
                                            textOverflow: "ellipsis",
                                            overflow: "hidden",
                                            width: "100%",
                                            whiteSpace: "nowrap"
                                        }}
                                        title={accessor.email}
                                    >
                                        {accessor.email}
                                    </p>
                                </TableCell>
                                <TableCell styles={{maxWidth: 120}}>
                                    <div className={classes.tableCellData}
                                         style={{width: "100%"}}>
                                        {(accessor.adminId !== this.props.adminUser.id) ?
                                            <Select
                                                options={ROLE_OPTIONS}
                                                value={ROLE_OPTIONS.find(access => access.value === accessor.role)}
                                                name="selected-state"
                                                clearable={false}
                                                onChange={(o) => this.updateRole(accessor.id, accessor.inviteAccessId, o.value)}
                                                searchable={true}
                                            /> :
                                            <p
                                                className={classes.tableCellData}>{ROLE_OPTIONS.find(access => access.value === accessor.role).label}</p>
                                        }
                                    </div>
                                </TableCell>
                                <TableCell styles={{
                                    maxWidth: 100,
                                    display: "flex",
                                    justifyContent: "center"
                                }}>
                                    <div>
                                        {accessor.isInvited == false ?
                                            <div className={classes.tableCellData}>
                                                <FormControlLabel
                                                    className="checkbox"
                                                    control={(
                                                        <Checkbox
                                                            onChange={(event, checked) => this.updateSupportNotifying(accessor.id, checked)}
                                                            checked={accessor.supportRequestNotify}
                                                            color="primary"
                                                        />
                                                    )}
                                                />
                                            </div> : null
                                        }
                                    </div>
                                </TableCell>
                                <TableCell>
                                    {(accessor.adminId !== this.props.adminUser.id) ?
                                        <div className={classes.tableCellData}>
                                            <DeleteAccess
                                                email={accessor.email}
                                                id={accessor.id}
                                                inviteAccessId={accessor.inviteAccessId}
                                                submit={this.removeAccess}
                                            />
                                        </div> : null
                                    }
                                </TableCell>
                            </TableRow>
                        )}
            </Table>
            <Modal
                classes={{paper: classes.paper}}
                open={this.state.open}
                onClose={this.closeShareModal}
                title="Share a chatbot"
            >
                <ShareModal bot={{id: this.props.match.params.botId}} closeModal={this.closeShareModal}/>
            </Modal>
        </div>;
    }
}

const mapStateToProps = (state) => (
    {
        botAccessors: state.botAccessors,
        adminUser: state.adminUser,
    });

export default withRouter(withStyles(styles, {withTheme: true})(connect(mapStateToProps)(Users)));
