/**
 * Created by Admin on 08.02.2018.
 */
import React, {Component} from "react";
import {createNewContext, updateContext} from "../api/context";
import States from "../../flowBuilder/components/States";
import DeleteTrigger from "./DeleteTrigger";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles/index";
import {withRouter} from "react-router-dom";
import Intents from "./IntentsSelect";
import {getAllPagingIntentsForTriggers} from "../api/nlp";

const styles = theme => ({
    mainSelected: {
        display: 'flex',
        margin: '20px 0',
        width: '100%',
        justifyContent: 'space-between',
        background: '#FFFFFF',
        boxShadow: '4px 4px 29px rgba(19, 69, 186, 0.0855129)',
        borderRadius: 10,
        position: 'relative'
    },
    selectItem: {
        flexBasis: '33%',
        padding: 24,
        borderRight: '1px solid #EBEBEF',
    },
    desc: {
        fontWeight: 'bold',
        fontSize: 14,
        color: '#3A3F62',
    },
    states: {
        color: '#1658F3',
        fontSize: 14
    },
    '@global': {
        '[class*="TriggerItem-mainSelected" class*="TriggerItem-selectItem":last-child ]': {
            borderRight: 0
        },
        '.Select.has-value.Select--single > .Select-control .Select-value .Select-value-label': {
            color: '#1658F3 !important',
            fontSize: 14
        },
        '.trashIcon': {
            position: 'absolute',
            right: -19,
            display: 'none',
            marginTop: -20
        },
        '.selected-trigger:hover .trashIcon': {
            display: 'block'
        }
    }
});

class TriggerItem extends Component {
    state = {
        triggerAtomId: this.props.modalTriggerId || null,
        context: null,
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        const { context } = nextProps;

        if (!prevState.context && context) {
            return { context };
        }

        return null;
    }

    handleChange = (name, value) => {
        if (name === 'previousAtom' || value !== null) {
            let context = JSON.parse(JSON.stringify(this.state.context));
            context[name] = value;
            this.setState({ context: context }, () => {
                if (context.currentAtom !== null && context.intent !== null) {
                    if (this.props.newContext) {
                        createNewContext(context, this.props.match.params.botId, this.props.contextSize);
                        this.props.changeNewContext()
                    } else {
                        updateContext(context, this.props.match.params.botId)
                    }
                }
            });
        }
    };
    customSeartch = (e) => {
        getAllPagingIntentsForTriggers(this.props.match.params.botId,e);
    };

    render() {
        const {classes} = this.props;

        return (
            <div>
                {this.state.context &&
                <div className={`selected-trigger ${classes.mainSelected}`}>
                    <div className={classes.selectItem}>
                        <p className={classes.desc}>Current Atom</p>
                        <States isCurrent={false}
                                payload={(value) => this.handleChange('previousAtom', value)}
                                value={this.state.context.previousAtom}/>
                    </div>
                    <div className={classes.selectItem}>
                        <p className={classes.desc}>Intent</p>
                        <Intents payload={(value) => this.handleChange('intent', value)}
                         customSeartch={(value)=> this.customSeartch(value)}
                                 value={this.state.context.intent}/>
                    </div>
                    <div className={classes.selectItem}>
                        <p className={classes.desc}>New Atom</p>
                        <States isCurrent={true}
                                payload={(value) => this.handleChange('currentAtom', value)}
                                value={this.state.context.currentAtom}/>
                    </div>
                    <DeleteTrigger triggerId={this.props.modalTriggerId}
                                   context={this.state.context}
                                   newContext={this.props.newContext}
                                   changeNewContext={this.props.changeNewContext}
                                   botId={this.props.match.params.botId}
                                   closeTriggerModal={this.props.closeTrigger}
                                   triggerAtomId={this.state.triggerAtomId}
                                   contextSize={this.props.contextSize}/>
                </div>}

            </div>

        );
    }
}

TriggerItem.propTypes = {
    classes: PropTypes.object.isRequired,
};
export default withRouter(withStyles(styles)(TriggerItem));
