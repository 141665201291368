import React from 'react';
import {withStyles} from '@material-ui/core';
import PropTypes from 'prop-types';

import {styles} from './BadgeStyles';

const Badge = (props) => {
  const {classes, badgeContent} = props;

  return (
    <span className={classes.badgeWrap}>
          <span className={classes.badge}>
              {badgeContent}
          </span>
      </span>
  );
};

Badge.propTypes = {
  classes: PropTypes.object,
  badgeContent: PropTypes.any.isRequired
};

export default withStyles(styles)(Badge);
