import React from "react";
import {IconButton} from "@material-ui/core";
import MessageButton from "./MessageButton";
import MessageQuickReplies from "./MessageQuickReplies";
import ParamsOld from "../../../../uiKit/inputs/ParamsOld";
import ImageDropzone from "../../../../uiKit/inputs/FileDropzone";
import {connect} from "react-redux";
import {Scrollbars} from "react-custom-scrollbars";
import {PlusIcon} from '../../../../uiKit/icons/Icons.js';

const styles = {
    container: {
        position: "relative",
        overflow: "hidden",
        width: "100%",
        height: "auto",
    },
    view: {
        overflow: "scroll",
        marginRight: "-17px",
        marginBottom: "-17px",
        paddingBottom: 12,
        display: "flex",
    },
    message: {
        height: "max-content",
        minWidth: "280px",
        margin: "5px",
        boxShadow: "0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)",
        borderRadius: 10,
        marginRight: 20,
        position: "relative",
    },
    addElement: {
        minWidth: "280px",
        margin: "5px",
        boxShadow: "0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)",
        borderRadius: 10,
        marginRight: 20,
        position: "relative",
    }
};

class Template extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    addElement() {
        let newMessage = JSON.parse(JSON.stringify(this.props.message));
        newMessage.attachment.payload.elements.push({
            title: "",
            subtitle: "",
            image_url: "",
            buttons: [],
        });
        this.props.updateMessage(newMessage);
    }

    handleChange(elemIndex, value, name) {
        let newMessage = JSON.parse(JSON.stringify(this.props.message));
        newMessage.attachment.payload.elements[elemIndex][name] = value;
        this.props.updateMessage(newMessage);
    }

    addNewButtonToElem(elemIndex) {
        let newMessage = JSON.parse(JSON.stringify(this.props.message));
        if (newMessage.attachment.payload.elements[elemIndex].buttons == null) {
            newMessage.attachment.payload.elements[elemIndex].buttons = [];
        }
        newMessage.attachment.payload.elements[elemIndex].buttons.push({
            type: "postback",
            title: '',
            payload: '',
        });
        this.props.updateMessage(newMessage);
    }

    addNewButton() {
        let newMessage = JSON.parse(JSON.stringify(this.props.message));
        if (newMessage.attachment.payload.buttons == null) {
            newMessage.attachment.payload.buttons = [];
        }
        newMessage.attachment.payload.buttons.push({
            type: "postback",
            title: '',
            payload: '',
        });
        this.props.updateMessage(newMessage);
    }

    updateBtn(btn, btnIndex, elemIndex) {
        let newMessage = JSON.parse(JSON.stringify(this.props.message));
        if (elemIndex !== null)
            newMessage.attachment.payload.elements[elemIndex].buttons[btnIndex] = btn;
        else
            newMessage.attachment.payload.buttons[btnIndex] = btn;
        this.props.updateMessage(newMessage);
    }

    deleteButton(btnIndex, elemIndex) {
        let newMessage = JSON.parse(JSON.stringify(this.props.message));
        if (elemIndex !== null)
            newMessage.attachment.payload.elements[elemIndex].buttons.splice(btnIndex, 1);
        else
            newMessage.attachment.payload.buttons.splice(btnIndex, 1);
        this.props.updateMessage(newMessage);
    }

    isEnoughElements() {
        let numOfElem = 11;
        return this.props.message.attachment.payload.elements.length < numOfElem;
    }

    onDeleteElem(elemIndex) {
        let newMessage = JSON.parse(JSON.stringify(this.props.message));
        newMessage.attachment.payload.elements.splice(elemIndex, 1);
        this.props.updateMessage(newMessage);
    }

    render() {
        const elementsNum = this.props.message.attachment.payload.elements.length;
        const {rtl} = this.props;

        return (
            <div>
                <Scrollbars
                    style={styles.container}
                    renderView={props =>
                        <div {...props}
                             style={styles.view}
                        />}
                >
                    {this.props.message.attachment.payload.elements.map((elem, elemIndex) =>
                        <div key={elem.id} style={styles.message}>
                            <ImageDropzone value={elem.image_url}
                                           onChange={(image_url) => this.handleChange(elemIndex, image_url, "image_url")}
                                           title="Replace image"
                            />

                            <ParamsOld
                                onChange={(value) => this.handleChange(elemIndex, value, "title")}
                                value={elem.title}
                                placeholder="Title"
                                height="70px"
                                title="Title"
                                rtl={rtl}
                                params={this.props.params}
                            />

                            <ParamsOld
                                onChange={(value) => this.handleChange(elemIndex, value, "subtitle")}
                                value={elem.subtitle}
                                placeholder="Subtitle"
                                height="70px"
                                title="Subtitle"
                                rtl={rtl}
                                params={this.props.params}
                            />

                            <div className="rep-btns-container">
                                {elem.buttons != null && elem.buttons.map((btn, btnIndex) =>
                                    <MessageButton btn={btn}
                                                   key={btnIndex}
                                                   rtl={rtl}
                                                   onUpdate={(btn) => this.updateBtn(btn, btnIndex, elemIndex)}
                                                   onDelete={() => this.deleteButton(btnIndex, elemIndex)}
                                    />
                                )}
                                {(elem.buttons == null || elem.buttons.length < 3) &&
                                <div className="add-button" onClick={() => this.addNewButtonToElem(elemIndex)}>
                                    add button
                                </div>}
                            </div>
                            {elementsNum > 1 &&
                            <IconButton aria-label="Delete"
                                        style={{
                                            position: "absolute",
                                            right: "-25px",
                                            top: "-5px",
                                        }}
                                        onClick={() => this.onDeleteElem(elemIndex)}>
                                <img src="/images/platform/delete.svg" alt="delete"/>
                            </IconButton>}
                        </div>
                    )}
                    {this.isEnoughElements() &&
                    <div onClick={() => this.addElement()} style={{...styles.addElement, display: "flex"}}>
                        <div style={{margin: "auto", color: "#c1c1c1"}}>
                            <PlusIcon width='20px' height='20px' color='grey'/>
                        </div>
                    </div>}
                    {this.props.onDelete &&
                    <IconButton aria-label="Delete"
                                style={{
                                    cursor: "pointer",
                                    position: "absolute",
                                    right: 0,
                                    top: 0,
                                }}
                                onClick={() => this.props.onDelete()}>
                        <img src="/images/platform/delete.svg" alt="delete"/>
                    </IconButton>}
                </Scrollbars>
                <MessageQuickReplies message={this.props.message}
                                     rtl={rtl}
                                     updateMessage={(message) => this.props.updateMessage(message)}/>
            </div>
        )
    }
}

const mapStateToProps = (state) => (
    {
        params: state.params,
    });

export default connect(mapStateToProps)(Template);
