/**
 * Created by Admin on 25.04.2018.
 */
import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import TabTemplate from "../TabTemplate";
import {CustomizationIcon} from "../../../../uiKit/icons/Icons";
import {Popover, withStyles} from "@material-ui/core";
import DropzoneWidget from "../DropzoneWidget";
import InputContent from "../../../../uiKit/inputs/InputContent";
import {SketchPicker} from "react-color";

const styles = theme => ({
  title: {
    fontFamily: 'Lato',
    fontSize: 16,
    color: theme.palette.text.header
  },
  color: {
    fontFamily: 'Lato',
    fontSize: 14,
    color: theme.palette.text.header
  }
});


class Customization extends React.Component {
  state = {
    open: false,
    color: this.props.widget.color
  };

  toggle = () => {
    this.setState({
      open: !this.state.open
    });
  };


  handleChange = (value, name) => {
    this.setState({
      [name]: value
    });
  };

  render() {
    const {classes} = this.props;
    const {open, color} = this.state;
    return (
        <TabTemplate
            tab={{
                name: "Customization",
                icon: <CustomizationIcon/>,
                description: 'Add your brand logo and colors'
            }}
            expanded={this.props.expanded}
            onChange={() => {
                this.props.handleChangeExpansion()
            }}
        >
            <p className={classes.title}>Choose widget logo</p>
            <div style={{marginBottom: 24}}>
                <DropzoneWidget
                    ifWidget
                    value={this.props.widget.imageUrl}
                    style={{marginTop: '10px'}}
                    onChange={imageUrl => this.props.handleChange(imageUrl, 'imageUrl')}
                    title="Click here to choose some image"
                />
            </div>
            <p className={classes.title}>Choose widget color</p>
            <div ref={(node) => {
                this.anchorEl = node;
            }}>
                <InputContent onClick={() => this.handleChange(true, 'open')}>
                    <div
                        style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'space-between'}}
                    >
                        <p>{color}</p>
                        <div style={{background: color, width: 24, height: 24}}></div>
                    </div>
                </InputContent>
                <Popover
                    open={open}
                    onClose={() => {
                        this.handleChange(false, 'open');
                        this.props.handleChange(this.state.color, 'color')
                    }}
                    anchorEl={this.anchorEl}
                    anchorReference="anchorEl"
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left'
                    }}
                    transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left'
                    }}
                    PaperProps={{
                        style: {
                            boxShadow: '4px 4px 29px rgba(19, 69, 186, 0.222685)',
                            borderRadius: 10,
                            overflow: "visible",
                        }
                    }}
                >
                    <SketchPicker
                        color={color}
                        onChange={color => this.handleChange(color.hex, 'color')}
                    />
                </Popover>
            </div>

        </TabTemplate>
    );
  }
}

Customization.propTypes = {
  classes: PropTypes.object
};

const mapStateToProps = state => (
    {
      activeBot: state.activeBot,
      botWidget: state.botWidget
    });

export default withRouter(withStyles(styles, {withTheme: true})(connect(mapStateToProps)(Customization)));
