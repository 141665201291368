import React, {useEffect, useState} from "react";
import {getMinutesLeft} from "../helpers/getMinutesLeft";

export function useTimer(start) {
    const minutesLeft = getMinutesLeft(start);
    const [count, setCount] = useState(minutesLeft);

    useEffect(() => {
        const minutesLeft = getMinutesLeft(start);
        setCount(minutesLeft)
    }, [start]);

    useEffect(() => {
        if (minutesLeft) {
            const secondsLeft = setInterval(() => {
                setCount(c => c - 1);
            }, 60000);
            return () => clearInterval(secondsLeft);
        }
    }, [minutesLeft]);

    return count;
}
