/**
 * Created by Admin on 26.02.2018.
 */
import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import Select from 'react-select';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import {FormControlLabel} from '@material-ui/core';
import {updateBot} from '../../../home/api/bots';
import SubmitButton from '../../../../uiKit/buttons/SubmitButton';
import {saveTeamHours} from '../../api/settings';
import img from './workingHours.png';
import HoursSpecification from './HoursSpecification';
import LoaderSmall from '../../../../uiKit/loaders/loaderSmall';
import {isObjectEmpty} from '../../../../helpers/isObjectEmpty';
import Heading from "../../../../uiKit/texts/Heading";
import {timeList, tomezoneList} from "./hoursOptions";
import {alertError} from "../../../../api";

const styles = theme => ({
    paragraph: {
        fontFamily: 'Lato',
        fontSize: 14,
        color: '#616581'
    },
    grayParagraph: {
        fontFamily: 'Lato',
        fontSize: 12,
        color: '#B0B2C0'
    },
    block: {
        borderBottom: '1px solid #EBEBEF',
        paddingBottom: 32
    },
    input: {
        border: 'none',
        outline: 'none',
        width: '100%',
        borderRadius: '5px',
        fontSize: '14px',
        lineHeight: '19px',
        backgroundColor: 'white'
    },
    inputHolder: {
        border: '1px solid #C7CAD6',
        borderRadius: '10px',
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: 'white',
        marginRight: 10,
        padding: 10,
        maxHeight: 45,
        width: '400px'
    },
    btn: {
        marginTop: 16,
        cursor: 'pointer',
        border: '1px solid #5A98F7',
        boxSizing: 'border-box',
        borderRadius: 10,
        color: '#5A98F7',
        padding: '8px 16px',
        width: 'max-content'
    },
    btnDelete: {
        marginTop: 16,
        cursor: 'pointer',
        border: '1px solid rgba(97, 101, 129, 0.5)',
        boxSizing: 'border-box',
        borderRadius: 10,
        color: 'rgba(97, 101, 129, 0.5)',
        padding: '8px 16px',
        width: 'max-content'
    },
    modal: {
        padding: '40px 160px',
        margin: '15% auto', /* 15% from the top and centered */
        boxShadow: '4px 4px 29px rgba(19, 69, 186, 0.14)',
        borderRadius: 10,
        overflowY: 'auto',
        backgroundColor: 'white',
        width: 640
    },
    dateContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    container: {
        display: 'flex',
        justifyContent: 'space-between'
    },
  rootRadio: {
    marginLeft: 13,
    color: theme.palette.primary.iconsGray,
    fontSize: 14,
    fontFamily: 'Lato',
    '&$checked': {
      color: theme.palette.primary.main
    }
  },
  checked: {},
  '@global':{
    '.rootRadio label':
        {
          margin: '-4px 16px -4px -14px'
        },
    '.rootRadio span[class*="label"]':
        {
          marginLeft: 8
        },
    '.rootRadio span':
        {
          fontSize: 14,
          fontFamily: 'Lato'
        },
  }
});

class Hours extends React.Component {
    state = {
        open: false,
        bot: this.props.activeBot,
        value: false,
        selectValue: this.props.activeBot.timezone,
        supportSettings: {
            weekdays: {
                startTime: '09:00'
            },
            weekends: {},
            enabled: false
        },
        weekdaysValue: true,
        weekendsValue: false,
        showLoader: true
    };
    handleOpen = () => {
        this.setState({open: true});
    };
    handleClose = () => {
        this.setState({open: false});
    };

    static getDerivedStateFromProps(props, state) {
        if (state.showLoader && props.activeBot.supportSettings) {
            return ({
                supportSettings: JSON.parse(JSON.stringify(props.activeBot.supportSettings)),
                weekdaysValue:  !!props.activeBot.supportSettings.weekdays ,
                weekendsValue: !!props.activeBot.supportSettings.weekends,
                value: props.activeBot.supportSettings.enabled,
                selectedOption: props.activeBot.timezone,
                showLoader: false
            });
        }

        if (state.showLoader && !isObjectEmpty(props.activeBot) && !props.activeBot.supportSettings) {
            return ({
                showLoader: false,
                selectedOption: props.activeBot.timezone
            });
        }
        return null;
    }

    handleChange(value, name) {
        const bot = JSON.parse(JSON.stringify(this.state.bot));
        bot[name] = value;
        this.setState({
            bot
        });
    }

    handleSet(name) {
        if (name === 'weekdaysValue' && this.state.weekendsValue || name === 'weekendsValue' && this.state.weekdaysValue || !this.state.weekendsValue && !this.state.weekdaysValue) {
            this.setState({
                [name]: !this.state[name]
            });
        }
    }

    updateTimezone(value) {
        let bot = JSON.parse(JSON.stringify(this.props.activeBot));
        bot.timezone = value;
        updateBot(bot);
    }

    updateTime(value, type, days) {
        const supportSettings = JSON.parse(JSON.stringify(this.state.supportSettings));
        if (supportSettings[days] == null) {
            supportSettings[days] = {};
        }
        supportSettings[days][type] = value;
        this.setState({
            supportSettings
        });
    }

    saveHours() {
        const supportSettings = this.state.supportSettings;
        if (!this.state.weekdaysValue) {
            supportSettings.weekdays = null;
        } else {
            supportSettings.weekdays = this.hoursConverter(supportSettings.weekdays)

            if (supportSettings.weekdays.startTime > supportSettings.weekdays.endTime) {
                alertError('Invalid weekdays hours. "From time" should be less than "to time".');
                return;
            }
        }
        if (!this.state.weekendsValue) {
            supportSettings.weekends = null;
        } else {
            supportSettings.weekends = this.hoursConverter(supportSettings.weekends)

            if (supportSettings.weekends.startTime > supportSettings.weekends.endTime) {
                alertError('Invalid weekends hours. "From time" should be less than "to time".');
                return;
            }
        }
        supportSettings.enabled = this.state.value;
        saveTeamHours(this.props.activeBot.id, supportSettings);
        const bot = JSON.parse(JSON.stringify(this.props.activeBot));
        bot.supportSettings = supportSettings;
        updateBot(bot);
    }

    hoursConverter = (date) => {
        let dateCopy = Object.assign({},date);
        if (date == null) {
            dateCopy = {};
        }
        if (dateCopy.startTime == null) {
            dateCopy.startTime = '09:00';
        }
        if (dateCopy.endTime == null) {
            dateCopy.endTime = '18:00';
        }

        return dateCopy
    }

    render() {
        const {classes} = this.props;
        const radio = <Radio
            classes={{
              root: classes.rootRadio,
              checked: classes.checked,
            }}
        />;
        return (
            <div>
                {this.state.showLoader ?
                    <div style={{
                        height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center'
                    }}
                    >
                        <LoaderSmall showLoader={this.state.showLoader}/>
                    </div>
                    :
                    <div className={classes.container}>
                        <div style={{
                            flexBasis: '80%',
                            maxWidth: 500
                        }}
                        >
                            <div className={classes.block}>
                                <Heading>Team Hours</Heading>
                                <p className={classes.paragraph}>
                                    Set online working hours for your team of customer
                                    supports
                                </p>
                                <p
                                    style={{color: '#3A3F62', marginTop: 32}}
                                    className={classes.paragraph}
                                >
                                    Choose a preferable timezone
                                </p>
                                <div style={{width: 400}}>
                                    <Select
                                        onBlurResetsInput={false}
                                        onSelectResetsInput={false}
                                        options={tomezoneList}
                                        simpleValue
                                        value={tomezoneList.find(opt => opt.value === this.props.activeBot.timezone)}
                                        name="selected-state"
                                        onChange={event => this.updateTimezone(event.value)}
                                        searchable
                                    />
                                </div>
                            </div>
                            <p style={{color: '#3A3F62', marginTop: 32, marginBottom: 8}}
                               className={classes.paragraph}>
                                Schedule working hours
                            </p>
                            <div>
                                <FormControlLabel
                                    className={`${classes.root} rootRadio`}
                                    control={(
                                        <RadioGroup
                                            className={classes.group}
                                            value={this.state.value}
                                            checked={this.state.value}
                                            onChange={(e) => {
                                                const values = (e.target.value === 'true');
                                                this.setState({
                                                    value: values,
                                                 weekdaysValue: !this.props.activeBot.supportSettings.enabled ? true : !!this.props.activeBot.supportSettings.weekdays
                                                });
                                            }
                                            }
                                        >
                                            <FormControlLabel
                                                value={false}
                                                control={radio}
                                                label="Online for 24/7"
                                            />
                                            <FormControlLabel
                                                value
                                                control={radio}
                                                label="Set up working days and hours"
                                            />
                                        </RadioGroup>
                                    )}
                                />
                            </div>
                            {this.state.value && this.state.supportSettings
                            && <div>
                                <HoursSpecification
                                    timeList={timeList}
                                    classes={classes}
                                    days={this.state.supportSettings.weekdays}
                                    handleSet={value => this.handleSet(value)}
                                    value={this.state.weekdaysValue}
                                    type="weekdaysValue"
                                    label="weekdays"
                                    onChange={(value, type, days) => this.updateTime(value, type, days)}
                                />
                                <HoursSpecification
                                    timeList={timeList}
                                    classes={classes}
                                    days={this.state.supportSettings.weekends}
                                    handleSet={value => this.handleSet(value)}
                                    value={this.state.weekendsValue}
                                    type="weekendsValue"
                                    label="weekends"
                                    onChange={(value, type, days) => this.updateTime(value, type, days)}
                                />
                            </div>
                            }
                            <SubmitButton
                                onClick={() => this.saveHours()}
                                title="Save"
                                styles={{marginTop: 8}}
                            />
                        </div>
                        <div style={{maxWidth: '30%'}}>
                            <p className={classes.paragraph} style={{marginBottom: 0}}>
                                Preview of Offline Customer
                                Support Mode
                            </p>
                            <p className={classes.grayParagraph} style={{maxWidth: '90%'}}>
                                This flow will be sent to
                                users in case of none of
                                customer supports are online. If you want to edit it, go to Flows section.
                            </p>
                            {/* todo link for flow with underline */}
                            <img
                                style={{
                                    maxWidth: 260,
                                    maxHeight: 490
                                }}
                                src="/images/platform/workingHours.png"
                                alt="working hours"
                            />

                        </div>
                    </div>
                }
            </div>
        );
    }
}

Hours.propTypes = {
    classes: PropTypes.object.isRequired
};

const mapStateToProps = state => (
    {
        activeBot: state.activeBot
    });

export default withRouter(withStyles(styles, {withTheme: true})(connect(mapStateToProps)(Hours)));
