export const styles = () => ({
    chatBubbleReceived: {
        maxWidth: '80%',
        padding: '8px 16px',
        fontSize: 14,
        background: 'rgba(90, 152, 247, 0.08)',
        borderRadius: '10px 10px 10px 2px',
        display: 'inline-block',
    },
    chatBubbleSend: {
        maxWidth: '80%',
        padding: '8px 16px',
        fontSize: 14,
        display: 'inline-block',
        background: '#1658F3',
        color: '#ffffff',
        borderRadius: '10px 10px 2px 10px',
    }
});
