/**
 * Created by Admin on 17.04.2018.
 */

import {AttachmentIcon, GalleryIcon, ListIcon, ReplyIcon, TextIcon} from "../../../../uiKit/icons/Icons.js";
import React from "react";

export const availableDefaultMessages = [
    {
        name: "Text",
        icon: <TextIcon width="14" height='11' color="#B0B2C0"/>,
        newMessage: {
            texts: [''],
            type: 'text'
        }
    },
    {
        name: "List",
        icon: <ListIcon width="11" height='11' color="#B0B2C0"/>,
        type: 'list',
        newMessage: {
            attachment: {
                type: 'template',
                payload: {
                    template_type: "list",
                    elements: [
                        {
                            title: "",
                            subtitle: "",
                            image_url: "",
                            buttons: [],
                        },
                        {
                            title: "",
                            subtitle: "",
                            image_url: "",
                            buttons: [],
                        }
                    ]
                }
            }
        },
        iconName: "TextIcon"
    },
    {
        name: "Gallery",
        icon: <GalleryIcon width="10" height='12' color="#B0B2C0"/>,
        type: 'gallery',
        newMessage: {
            attachment: {
                type: 'template',
                payload: {
                    template_type: "generic",
                    elements: [
                        {
                            title: "",
                            subtitle: "",
                            image_url: "",
                            buttons: [],
                        }
                    ]
                }
            }
        },
        iconName: "TextIcon"
    },
    {
        name: "Attachment",
        icon: <AttachmentIcon width="17" height='8' color="#B0B2C0"/>,
        type: 'attachment',
        newMessage: {
            attachment: {
                type: 'image',
                payload: {
                    url: '',
                }
            }
        },
        iconName: "TextIcon"
    },
    {
        name: "Typing",
        type: 'typing',
        icon: <ReplyIcon width="17" height='11' color="#B0B2C0"/>,
        newMessage: {
            senderAction: "typing_on",
            time: 3,
        },
        iconName: "TextIcon"
    }
];

export const availableOtherMessages = [
    {
        name: "Media template",
        newMessage: {
            attachment: {
                type: 'image',
                payload: {
                    url: '',
                }
            }
        }
    },
    {
        name: "User Input",
        type: 'user_input',
        newMessage: {
            type: 'user_input',
            parameter: '',
            next_atom: ''
        }
    }

];

export const randomRedirectInitialState = {
    randomAtoms: [{
        atomName: '',
        number: 1
    }],
    sendFirstAtomOnlyOnce: false
};
