import React from "react";
import {withStyles} from "@material-ui/core/styles";
import MUIDialogActions from "@material-ui/core/DialogActions";

import { styles } from "./styles";

const View = props => {
    const { classes } = props;

    return (
        <MUIDialogActions className={classes.root} {...props} />
    )
};

export const DialogActions = withStyles(styles)(View);
