import {ADD_CONTEXT, DELETE_CONTEXT, SAVE_CONTEXTS, UPDATE_CONTEXT, CLEAR_CONTEXTS} from '../actions/contexts'

const contexts = (state = null, action) => {
    switch (action.type) {
        case SAVE_CONTEXTS:
            return action.contexts.reverse();
        case ADD_CONTEXT:
            let context = [...state];
            context.unshift(action.context);
            return context;
        case UPDATE_CONTEXT:
            let contexts = [...state];
            contexts.forEach((element, index) => {
                if (element.id === action.context.id) {
                    contexts[index] = action.context;
                }
            });
            return contexts;
        case DELETE_CONTEXT:
            return [...state].filter(x => {
                return x.id !== action.contextId;
            });
        case CLEAR_CONTEXTS:
            return null;
        default:
            return state;
    }
};

export default contexts
