import {SAVE_BOT_ACCESSORS} from "../actions/botAccessors";

const botAccessors = (state = [], action) => {
    switch (action.type) {
        case SAVE_BOT_ACCESSORS:
            return action.botAccessors;
        default:
            return state;
    }
};

export default botAccessors;
