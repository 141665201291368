export const styles = () => ({
    iconWrap: {
        position: 'relative',
        width: 330,
        height: 'max-content'
    },
    randomWrap: {
        width: 330,
        scrollBehavior: 'smooth',
        overflowX: 'hidden',
        '&::-webkit-scrollbar': {
            display: 'none'
        }
    },
});
