export function getNestedProps(obj, path, def) {
  const fullPath = path
    .replace(/\[/g, '.')
    .replace(/]/g, '')
    .split('.')
    .filter(Boolean);

  return fullPath.every(everyFunc) ? obj : def;

  function everyFunc(step) {
    // eslint-disable-next-line no-param-reassign
    return !(step && (obj = obj[step]) === undefined);
  }
}
