import {SAVE_ADMIN_USER} from "../actions/adminUser";

const adminUser = (state = null, action) => {
    if (action.type === SAVE_ADMIN_USER) {
        return action.adminUser;
    } else {
        return state;
    }
};

export default adminUser;
