import {store} from '../../../index';

export const SAVE_FLOWS = 'SAVE_FLOWS';
export const ADD_NEW_FLOW = 'ADD_NEW_FLOW';

export const saveFlows = (flows) => {
	store.dispatch(
		{
			type: SAVE_FLOWS,
			flows
		}
	);
};

export const addNewFlow = (flow) => {
	store.dispatch(
		{
			type: ADD_NEW_FLOW,
			flow
		}
	);
};
