import React from "react";
import ReactDOM from "react-dom";
import {BrowserRouter, Route} from "react-router-dom";
import {createStore} from "redux";
import {Provider} from "react-redux";
import "./containers/index.css";
import App from "./containers/App";
import reducer from "./reducers/index";
import {createMuiTheme, MuiThemeProvider} from "@material-ui/core/";
import {MuiPickersUtilsProvider} from "material-ui-pickers";
import DateFnsUtils from "material-ui-pickers/utils/date-fns-utils";
export const SET_WIT_AI = 'SET_WIT_AI';

export const store = createStore(reducer);
const theme = createMuiTheme({
    palette: {
        primary: {
            light: '#5A98F7',
            medium: '#1146C2',
            main: '#1658F3',
            dark: '#1345BA',
            grayColor: '#D7D8DF',
            text: "#616581",
            contrastText: '#fff',
            azure: '#8EB0F8',
            iconsGray: '#D7D8DF',
            semiAzure: '#D5E5FD',
            headerText: '#3A3F62',
            background: '#F7FAFE',
        },
        text: {
            header: '#3A3F62',
            gray: '#B0B2C0'
        },
        secondary: {
            light: '#ff7961',
            main: '#f44336',
            dark: '#ba000d',
            contrastText: '#000',
        },
        default: {
            gray: '#C7CAD6'
        },
        alerts: {
            success: "#11C314",
            error: "#FF624C",
        },
        pieChartColors: {
            chatbot: '#1658F3',
            support: '#F3B116'
        }
    },
});

ReactDOM.render(
    <Provider store={store}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <BrowserRouter>
                <MuiThemeProvider theme={theme}>
                    <Route path="/" component={App}/>
                </MuiThemeProvider>
            </BrowserRouter>
        </MuiPickersUtilsProvider>
    </Provider>,

    document.getElementById('root'));
// registerServiceWorker();
