export const styles = ({
    filterItem: {
        height: 28,
        color: '#3A3F62',
        cursor: 'pointer',
        display: 'flex',
        fontSize: 14,
        alignItems: 'center',
        padding: '0px 12px',
        '&:hover': {
            backgroundColor: '#EBEBEF'
        },
        '& span': {
            width: 36,
            textAlign: 'center'
        }
    },
    popover: {
        width: 135,
        height: 40,
        borderRadius: 4,
        boxShadow: '0px 1px 5px rgba(19, 69, 186, 0.241904)',
        padding: '6px 0px'
    },
});
