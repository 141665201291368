export const initialColors = ['#9bb3e4', '#9bb3e4', '#9bb3e4', '#9bb3e4', '#9bb3e4'];

export const initialDataSet = {
    labels: ['5 stars', '4 stars', '3 stars', '2 stars', '1 star'],
    datasets: [
        {
            backgroundColor: initialColors,
            borderColor: '#366ac9',
            borderWidth: 1,
            hoverBackgroundColor: initialColors,
            hoverBorderColor: '#366ac9'
        }
    ]
};

export const chartOptions = {
    maintainAspectRatio: false,
    animation: {duration: 0},
    hover: {animationDuration: 0},
    responsiveAnimationDuration: 0,
    legend: {display: false},
    scales: {
        xAxes: [{
            gridLines: {
                display: false
            },
            ticks: {
                beginAtZero: true,
                min: 0
            }
        }]
    }
};
