import React from 'react';
import {withStyles} from '@material-ui/core';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import {HOME_PATH} from '../../configs';

import {RobotIcon} from '../../uiKit/icons/Robot';

import {styles} from './styles';

const ErrorScreen = (props) => {
	const {classes, activeBot} = props;

	const handleClick = () => {
		window.location.href = `${HOME_PATH}/bot/${activeBot.id}/dashboard`;
	};

	return (
		<div className={classes.container}>
			<RobotIcon/>
			<h1 className={classes.title}>
				Whoops!
			</h1>
			<p className={classes.subtitle}>
				Something went wrong
			</p>
			<p className={classes.text}>
				If this problem persists, pleace contact our Support Team.
			</p>
			<button className={classes.button}
					onClick={handleClick}>
				Back to Dashboard
			</button>
		</div>
	);
};

ErrorScreen.propTypes = {
	classes: PropTypes.object
};

const mapStateToProps = (state) => ({activeBot: state.activeBot});


export default withRouter(withStyles(styles)(connect(mapStateToProps)(ErrorScreen)));
