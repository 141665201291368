import React from "react";
import {withRouter} from "react-router-dom";
import moment from "moment";
import {withStyles} from "@material-ui/core";
import PropTypes from "prop-types";

import Headline from "../../uiKit/texts/Headline";
import AnalyticsHeader from "./components/AnalyticsHeader/AnalyticsHeader";
import Chart from "./components/Chart/Chart";
import {getRatingDistribution} from "./api";
import {styles} from './components/AnalyticsStyles';
import CommentsList from "./components/CommentsList/CommentsList";

class Analytics extends React.Component {
  state = {
    startDate: moment().subtract(1, 'month').add(1, 'day'),
    endDate: moment(),
    ratingDistribution: null,
    selectedBar: null
  };

  componentDidMount() {
    this.handleRequests();
  }

  handleUpdateDate = (startDate, endDate) => {
    this.setState({
      startDate,
      endDate
    }, this.handleRequests)
  };

  handleRequests = () => {
    const {botId} = this.props.match.params;
    const {startDate, endDate} = this.state;

    getRatingDistribution(botId, startDate, endDate)
      .then(res => {
        this.setState({
          ratingDistribution: res
        })
      })
      .catch((err) => console.log(err))
  };

  handleSelectedBar = (selectedBar) => {
    this.setState({selectedBar})
  };

  render() {
    const {classes} = this.props;
    const {startDate, endDate, ratingDistribution, selectedBar} = this.state;
    const isData = ratingDistribution && Object.values(ratingDistribution.distribution).some(elem => elem !== 0);

    return (
      <div>
        <Headline title="Analytics"/>
        {ratingDistribution
        && <div className={classes.container}>
          <AnalyticsHeader startDate={startDate}
                           endDate={endDate}
                           ratingDistribution={ratingDistribution}
                           setDate={this.handleUpdateDate}/>
          {isData
            ? <div className={classes.chartWrap}>
              <Chart data={ratingDistribution.distribution}
                     selectedBar={selectedBar}
                     handleSelectedBar={this.handleSelectedBar}/>
              <CommentsList startDate={startDate}
                            selectedBar={selectedBar}
                            endDate={endDate}/>
            </div>
            : <div className={classes.noDataWrap}>
              <p className={classes.noData}>No data</p>
            </div>}
        </div>}
      </div>
    );
  }
}

Analytics.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withRouter(withStyles(styles)(Analytics));
