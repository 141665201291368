import {combineReducers} from "redux";

import analytics from '../tabs/analytics/reducers/analytics';

import security from "../tabs/authorization/reducers/security";
import adminUser from "../tabs/authorization/reducers/adminUser";

import broadcasts from "../tabs/broadcast/reducers/broadcasts";

import supportStatistics from "../tabs/dashboard/reducers/supportStatistics";
import usersStatistics from "../tabs/dashboard/reducers/usersStatistics";
import popularHoursStatistics from "../tabs/dashboard/reducers/popularHoursStatistics";

import flows from "../tabs/flowBuilder/reducers/flows";
import atom from "../tabs/flowBuilder/reducers/atom";
import atoms from "../tabs/flowBuilder/reducers/atoms";
import tags from "../tabs/flowBuilder/reducers/tags";
import searchAtoms from '../tabs/flowBuilder/reducers/searchAtoms';

import bots from "../tabs/home/reducers/bots";
import activeBot from "../tabs/home/reducers/activeBot";
import params from "../tabs/home/reducers/params";
import snackBar from "../tabs/home/reducers/snackBar";

import intents from "../tabs/nlp/reducers/intents";
import intentsSize from "../tabs/nlp/reducers/intentsSize";
import contextSize from "../tabs/nlp/reducers/contextSize";
import contexts from "../tabs/nlp/reducers/contexts";
import nlpRequests from "../tabs/nlp/reducers/nlpRequests";

import botAccessors from "../tabs/settings/reducers/botAccessors";
import token from "../tabs/settings/reducers/facebookToken";
import settingsPages from "../tabs/settings/reducers/settingsPages";
import twilioCredentials from "../tabs/settings/reducers/twilioCredentials";

import supportRequests from "../tabs/support/reducers/supportRequests";
import supportUsersNew from "../tabs/support/reducers/users";
import activeUser from "../tabs/support/reducers/activeUser";

import botWidget from "../tabs/widget/reducers/botWidget";

import account from "../tabs/account/reducers/account";
// -- APPEND IMPORT HERE --
const Reducers = combineReducers({
    security,
    flows,
    bots,
    activeBot,
    atom,
    broadcasts,
    params,
    activeUser,
    intents,
    intentsSize,
    contextSize,
    contexts,
    nlpRequests,
    snackBar,
    atoms,
    botWidget,
    supportRequests,
    adminUser,
    botAccessors,
    tags,
    settingsPages,
    token,
    supportStatistics,
    usersStatistics,
    popularHoursStatistics,
    analytics,
    searchAtoms,
    twilioCredentials,
    supportUsersNew,
    account,
// -- APPEND FILE HERE --
});

export default Reducers
