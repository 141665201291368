export const styles = () => ({
    header: {
        padding: '20px 40px 25px',
        borderBottom: '1px solid rgba(53, 64, 82, 0.1)',
        display: 'flex',
        alignItems: 'center',
        '& .react-bootstrap-daterangepicker-container input': {
            margin: 0
        }
    },
    title: {
        fontSize: 18,
        fontWeight: '700',
        marginRight: 16,
        margin: 0,
        display: 'inline'
    },
    rateWrap: {
        display: 'inline',
        color: 'rgba(53, 64, 82, 0.5)',
        fontSize: 12,
        '& span': {
            fontWeight: '700',
            color: '#3A3F62',
            fontSize: 14
        },
        margin: 0
    },
    rateContainer: {
        display: 'inline',
        '& p:first-child': {
            marginRight: 16
        }
    }
});
