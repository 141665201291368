/**
 * Created by Admin on 21.03.2018.
 */
import {SET_PARAMS} from "../actions/params";

const params = (state = [], action) => {
  if (action.type === SET_PARAMS) {
    return action.params;
  } else {
    return state;
  }
};

export default params
