export const styles = theme => ({
    mb16: {
        marginBottom: 16
    },
    mb34: {
        marginBottom: 34
    },
    description: {
        fontSize: 12,
        color: '#B0B2C0',
        fontFamily: 'Lato, sans-serif',
        margin: '4px 0 16px'
    },
    inputTitle: {
        marginTop: 3
    }
});

export const inputStyle = {
    marginTop: 0
};