/**
 * Created by Admin on 20.03.2018.
 */
import React from "react";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import MoreVertIcon from "@material-ui/icons/MoreVert";


const ITEM_HEIGHT = 48;

class ParamsList extends React.Component {
    state = {
        anchorEl: null,
    };

    handleClick = event => {
        this.setState({anchorEl: event.currentTarget});
    };

    handleClose(option) {
        this.props.addParam("" + option + "}}");
        this.setState({anchorEl: null});
    };

    getTopPosition() {
        return -427 + this.props.pos.top + this.props.pos.height / 2 + "px";
    }

    getLeftPosition() {
        return -16 + this.props.pos.left + this.props.pos.width / 2 + "px";
    }

    render() {
        const {anchorEl} = this.state;

        return (
            <div>
                <IconButton
                    aria-label="More"
                    aria-owns={anchorEl ? 'long-menu' : null}
                    aria-haspopup="true"
                    onClick={this.handleClick}
                >
                    <MoreVertIcon/>
                </IconButton>
                <Menu
                    id="long-menu"
                    anchorEl={anchorEl}
                    open={Boolean(this.props.show)}
                    onClose={() => this.props.onClose()}
                    PaperProps={{
                        style: {
                            maxHeight: ITEM_HEIGHT * 4.5,
                            width: 200,
                        },
                    }}
                    // style={{top: "-427px", left: "-16px"}}
                    style={{top: this.getTopPosition(), left: this.getLeftPosition()}}
                >
                    {this.props.options.map(option => (
                        <MenuItem key={option.id}
                                  selected={option === 'Atria'} onClick={() => this.handleClose(option.name)}>
                            {option.name}
                        </MenuItem>
                    ))}
                </Menu>
            </div>
        );
    }
}

export default ParamsList;