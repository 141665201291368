import React, {useMemo, useState} from 'react';
import {withStyles} from '@material-ui/core/styles';

import Input from './Input'


const styles = () => ({
    passwordInputContainer: {position: 'relative'},
    showPasswordButton: {
        backgroundColor: 'transparent',
        border: 'none',
        position: 'absolute',
        top: '37px',
        right: '24px',
        padding: 0,
        height: '12px',
        width: '20px',
        cursor: 'pointer',
        "&>img": {
            maxWidth: '100%',
            maxHeight: '100%'
        }
    },
    eyeIcon: {color: '#1658F3'}
});

const PasswordInput = ({classes, ...otherProps}) => {
    const [hidePassword, setHidePassword] = useState(true);

    const inputType = useMemo(() => hidePassword ? 'password' : 'text', [hidePassword]);
    const iconType = useMemo(() => hidePassword ? 'visibility' : 'visibility_off', [hidePassword]);

    const handleHidePasswordToggle = e => {
        e.preventDefault();
        setHidePassword(!hidePassword);
    };

    return (
        <div className={classes.passwordInputContainer}>
            <Input
                {...otherProps}
                type={inputType}
                required={true}
            />
            <button
                type='button'
                className={classes.showPasswordButton}
                onClick={handleHidePasswordToggle}
                tabIndex='-1'
            >
                <i className={`material-icons ${classes.eyeIcon}`}>{iconType}</i>
            </button>
        </div>
    );
};

export default (withStyles(styles, {withTheme: true})(PasswordInput));
