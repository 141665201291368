export const styles = () => ({
	container: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'column',
		height: '100%',
		marginRight: 40
	},
	title: {
		fontSize: 36,
		color: '#3A3F62',
		fontWeight: 700,
		margin: '64px 0px 8px'
	},
	subtitle: {
		fontSize: 18,
		color: '#354052',
		fontWeight: 400,
		margin: '0px 0px 16px'
	},
	text: {
		color: 'rgb(53,64,82,0.7)',
		fontSize: 14,
		margin: '0px 0px 24px'
	},
	button: {
		padding: '13px 28px',
		border: 0,
		background: '#1658F3',
		boxShadow: '0px 3px 9px rgba(19, 69, 186, 0.206267)',
		borderRadius: 10,
		cursor: 'pointer',
		color: 'white',
		margin: '30px auto 0',
		display: 'block'
	},
});
