export const styles = theme => ({
    wrapper: {},
    headerWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        '& h2': {
            margin: '0',
            fontSize: '24px',
            fontFamily: 'Lato',
            color: theme.palette.primary.headerText
        }
    },
    addNewLanguageBtn: {
        backgroundColor: "white",
        color: theme.palette.primary.main,
        border: '1px solid ' + theme.palette.pieChartColors.chatbot,
        borderRadius: 10,
        padding: 10,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        maxHeight: 40,
        marginLeft: '21px',
        cursor: 'pointer'
    },
    evenRow: {
        color: theme.palette.primary.text,
        padding: '24px',
        display: 'flex',
        justifyContent: 'space-between',
        fontSize: '14px',
        fontFamily: 'Lato'
    },
    notEvenRow: {
        color: theme.palette.primary.text,
        padding: '24px',
        display: 'flex',
        justifyContent: 'space-between',
        fontSize: '14px',
        fontFamily: 'Lato',
        background: 'rgba(90, 152, 247, 0.1)',
        borderRadius: '10px'
    },
    deleteLanguageBtn: {
        color: theme.palette.alerts.error,
        cursor: 'pointer',
        fontSize: '14px',
        fontFamily: 'Lato',
    },
    newLanguageModalHeader: {
        fontSize: '18px',
        fontWeight: 400,
        fontFamily: 'Lato',
        color: theme.palette.text.header,
        textAlign: 'center',
    },
    newLanguageModalLabel: {
        marginTop: '32px',
        fontSize: '14px',
        fontFamily: 'Lato',
        color: theme.palette.text.header,
    },
    languageError: {
        color: theme.palette.alerts.error,
        marginBottom: '-10px',
        fontSize: 12,
    },
    createButton: {
        padding: '12px 28px',
        border: 0,
        background: theme.palette.pieChartColors.chatbot,
        boxShadow: '0px 3px 9px rgba(19, 69, 186, 0.206267)',
        borderRadius: 10,
        cursor: 'pointer',
        color: 'white',
        margin: '30px auto 0',
        display: 'block',
    },
    paper: {
        padding: "30px 50px 24px",
    },
    buttonsHolder: {
        display: 'flex',
        justifyContent: 'space-around',
        padding: "16px 70px 24px",
    },
    deleteModalHeader: {
        textAlign: 'center',
        margin: '10px auto 0px',
        fontSize: '14px',
        fontFamily: 'Lato',
        color: theme.palette.text.header,
    },
});
