import React from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import {withRouter} from "react-router-dom";

const styles = theme => ({
    topContainer: {
        fontSize: 36,
        color: '#3A3F62',
        margin: '40px 0 30px',
        fontWeight: 'bold',
        fontFamily: 'Lato, sans-serif'
    }
})


class Headline extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {classes} = this.props;
        return (
            <div className={classes.topContainer}>
                {this.props.title}
            </div>
        )
    }
}

Headline.propTypes = {
    classes: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired
};

export default withRouter(withStyles(styles)(Headline));