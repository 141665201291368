import React from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core";

import Input from "../../../../../uiKit/inputs/Input";
import {styles} from "./style";

const Field = props => {
    const {classes, value, inputStyle, onChange, field, error} = props;
    return (
        <React.Fragment>
            <Input
                title={field.title}
                error={error}
                value={value}
                maxLength={255}
                inputTitleStyles={inputStyle}
                placeholder={field.placeHolder}
                onChange={onChange}
            />
            <p className={classes.description}>{field.description}</p>
        </React.Fragment>
    );
};

Field.propTypes = {
    classes: PropTypes.object,
    field: PropTypes.object,
    error: PropTypes.string,
    onChange: PropTypes.func,
    inputStyle: PropTypes.object,
    value: PropTypes.string
};

export default withStyles(styles, {withTheme: true})(Field);
