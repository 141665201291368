import React from "react";
import {withStyles} from '@material-ui/core/styles';
import moment from "moment";
import InputHolder from "../../../uiKit/inputs/InputHolder";
import Tags from "../../flowBuilder/components/messages/Tags";
import {DateTimePicker} from "material-ui-pickers";
import Icon from "@material-ui/core/Icon/Icon";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import States from "../../flowBuilder/components/States";
import SubmitButton from "../../../uiKit/buttons/SubmitButton";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {createBroadcast} from "../api/broadcast";
import RadioGroup from "@material-ui/core/RadioGroup/RadioGroup";
import Radio from "@material-ui/core/Radio/Radio";
import {CalendarIcon} from "../../../uiKit/icons/Icons";

const styles = theme => ({

    menu: {
        display: "flex",
        justifyContent: "space-around",
        fontFamily: 'Lato',
    },
    newBroadcast: {
        padding: 0,
        boxShadow: "none"
    },

    leftTabItem: {
        marginTop: 16
    },
    logicBlock: {
        marginTop: '20px',
        paddingBottom: '25px',
        width: '98%',
    },

    description: {
        fontFamily: 'Lato',
        fontSize: 14,
        color: '#616581'
    },

    select: {
        flexDirection: "column",
    },

    inputHolder: {
        border: "1px solid #C7CAD6 !important",
        width: "max-content",
        borderRadius: "5px",
        display: "flex",
        justifyContent: 'space-between',
        backgroundColor: "white",
        marginRight: 10,
        padding: 10,
        maxHeight: 45,
        minWidth: 400
    },
    tagsStyle: {
        width: '400px'
    },
    submitButton: {
        marginTop: '24px !important'
    },
    input: {
        padding: "10px",
        border: "none",
        outline: "none",
        width: '100%',
        borderRadius: "5px",
        fontSize: "14px",
        lineHeight: "19px",
        backgroundColor: "white",
    },
    picker: {
        margin: "auto",
        display: "block",
    },
    button: {
        display: "flex",
        justifyContent: "flex-start",
        marginTop: 30
    },
    simpleFont: {
        color: "#616581",
    },
    whiteInput: {
        background: "#ffffff",
        fontSize: "14px",
        padding: "0 24px",
        borderRadius: "8px"
    },
    rightIcon: {
        marginLeft: theme.spacing.unit,
    },
    footer: {
        display: "flex",
        maxWidth: 400
    },
    headerButton: {
        margin: "5px",
        background: 'linear-gradient(285.2deg, #5A98F7 -35.9%, #1658F3 61.17%)',
        boxShadow: '0px 3px 9px rgba(19, 69, 186, 0.206267)',
        color: 'white',
        borderRadius: 10,
        height: 45
    },
    headerAddBroadcast: {
        fontSize: "18px",
        fontWeight: "bold",
        fontFamily: 'Lato',
        color: theme.palette.primary.headerText,
        margin: 'auto',
    },

    titleRadio: {
        fontSize: "14px",
        color: theme.palette.text.header
    },

    '@global': {
        "[class*='MuiInput-underline']:before ": {
            display: 'none'
        },
        "[class*='Connect-Broadcasts--whiteInput']": {
            margin: '0 !important',
            border: 0,
            padding: 0
        },
        '.label': {
            fontSize: 14,
            color: '#3A3F62'
        },
        '.checkbox-checked': {
            borderRadius: 10
        },
        '.picker> div> div > div >[class*="jss"]': {
            border: 0
        },
        '.picker [class*="jss"]::before': {
            content: 'none'
        },
        '.broadcast-rootRadio label':
            {
                margin: '10px 16px -4px -14px'
            },
        '.broadcast-rootRadio span[class*="label"]':
            {
                marginLeft: '-3px'
            },
        '.broadcast-rootRadio span':
            {
                margin: '-10px 0px',
                fontSize: 14,
                fontFamily: 'Lato'
            },
    }
});

class BroadcastModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            checked: false,
            sendNow: true,
            ifTimezone: false,
            timezone: null,
            red: false,
            activeBot: {},
            botTimezoneSet: false,
            selectedDate: null,
            shownDate: null,
            loaded: false,
            selectedTags: [],
        };

        this.state.selectedDate = !this.state.activeBot.length ? moment()
            : moment.utc().utcOffset(this.state.activeBot.timezone);
        this.state.shownDate = !this.state.activeBot.length ? moment()
            : moment.utc().utcOffset(this.state.activeBot.timezone);
    }

    timeFunc() {
        if (this.props.activeBot.timezone > 0) {
            return `+ ${this.props.activeBot.timezone}`
        }
        if (this.props.activeBot.timezone == 0) {
            return ' '
        }
        else {
            return `${this.props.activeBot.timezone}`
        }
    }

    changeName = (e) => {
        this.setState({name: e.target.value});
    }

    handleChangeAtom(value) {
      this.state.red ? this.setState({
        atom: value,
        red:false
      }) :
          this.setState({
            atom: value
          })
    }

    sendBroadcast = () => {
        if (this.state.atom != null) {
            const {atom, name, activeBot, ifTimezone, sendNow, selectedDate, checked, selectedTags} = this.state;
            const localDateTime = sendNow ? moment().utc().format('YYYY-MM-DDTHH:mm')
                : moment(selectedDate).format('YYYY-MM-DDTHH:mm');

            let broadcast = {
                botId: this.props.activeBot.id,
                atom,
                name,
                timezone: ifTimezone,
                localDateTime,
                tags: selectedTags,
                allOfTags: checked,
            };
            this.setState({
                atom: undefined,
                checked: false,
                name: '',
                shownDate: moment().utcOffset(-activeBot.timezone * 60 + (moment().utcOffset() > 0 ? moment().utcOffset() : -moment().utcOffset())).format('YYYY-MM-DDTHH:mm'),
                selectedDate: moment(moment().utc().utcOffset(activeBot.timezone)),
                sendNow: true,
                ifTimezone: true,
                red: false,
                selectedTags: []
            }, () => {
                createBroadcast(broadcast);
                this.props.onClose();
            })
        }
        else {
            this.setState({
                red: true
            })
        }
    }

    componentDidMount() {
        const {botTimezoneSet} = this.state;
        const {activeBot} = this.props;
        if (Object.getOwnPropertyNames(activeBot).length && !botTimezoneSet) {
            this.setState({
                shownDate: moment.utc().utcOffset(activeBot.timezone * 60).format('YYYY-MM-DDTHH:mm'),
                selectedDate: moment(moment().utc().utcOffset(activeBot.timezone * 60)).format('YYYY-MM-DDTHH:mm'),
                loaded: true,
                botTimezoneSet: true
            });
        }
    }

    componentDidUpdate() {
        const {botTimezoneSet} = this.state;
        const {activeBot} = this.props;
        if (Object.getOwnPropertyNames(activeBot).length && !botTimezoneSet) {
            this.setState({
                shownDate: moment.utc().utcOffset(activeBot.timezone * 60).format('YYYY-MM-DDTHH:mm'),
                selectedDate: moment(moment().utc().utcOffset(activeBot.timezone * 60)).format('YYYY-MM-DDTHH:mm'),
                loaded: true,
                botTimezoneSet: true
            });
        }
    }

    handleCheck = (event) => {
        this.setState({checked: (event.target.value === 'true')});
    };

    handleTagsChanged(value) {
        this.setState({selectedTags: value});
    }

    toggleSendNow = (event) => {
        const {sendNow, ifTimezone} = this.state;
        let currentIfTimezone = ifTimezone;
        if (sendNow == false && ifTimezone == true) {
            currentIfTimezone = !ifTimezone;
        }
        this.setState({
            sendNow: (event.target.value === 'true'),
            ifTimezone: currentIfTimezone
        })

    };
    toggleTimezone = () => {
        this.setState({ifTimezone: !this.state.ifTimezone})
    };
    handleDateChange = (date) => {
        const shownDate = moment(date);
        const selectedDate = moment(date);
        this.setState({
            shownDate,
            selectedDate
        });
    };

    render() {
        const {classes} = this.props;
        const {loaded, shownDate, name} = this.state;
        return (
            <div className={classes.menu}>
                <div>
                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                    }}>
                        <h2 className={classes.headerAddBroadcast}>
                            Add broadcast
                        </h2>
                    </div>
                    <div className={classes.newBroadcast}>
                        <div className="picker">
                            <div className={classes.leftTabItem}>
                                <h4 className={classes.description}>Broadcast name</h4>
                                <InputHolder
                                    placeholder="Type name"
                                    maxLength={90}
                                    autofocus={true}
                                    defaultValue={name}
                                    holderStyle={{color: '#3A3F62'}}
                                    onChange={this.changeName}
                                    handleEnterPress={this.sendBroadcast}
                                />
                            </div>
                            <div className={classes.logicBlock} style={{
                                borderBottom: 'solid 1px #EBEBEF',
                            }}>
                                <h2
                                    className={classes.description}
                                >
                                    Choose message for broadcast
                                </h2>
                                <div className={classes.footer}
                                     style={this.state.red ? {
                                         width: '100%',
                                         border: '1px solid red',
                                         borderRadius: "5px"
                                     } : {width: '100%'}}>
                                    <States payload={(value) => this.handleChangeAtom(value)}
                                            showHardcoded={true}
                                            holderStyle={{width: '100%'}}
                                            isCurrent={this.state.red}
                                            style={{
                                                width: '100%'
                                            }}
                                            value={this.state.atom}/>
                                </div>
                            </div>
                            <div className={classes.logicBlock}
                                 style={this.state.selectedTags.length <= 1 ? {borderBottom: 'solid 1px #EBEBEF'} : {}}>
                                <h4 className={classes.description}>Choose tags</h4>
                                <Tags onChange={(value) => this.handleTagsChanged(value)}
                                      value={this.state.selectedTags}
                                      disableNew
                                      style={{
                                          width: '400px',
                                      }}
                                      creatable={false}
                                      classes={{select: classes.select}}
                                      showSwitcher={false}
                                />
                            </div>
                            {this.state.selectedTags.length > 1 ?
                                <div style={{borderBottom: 'solid 1px #EBEBEF', paddingBottom: 8}}>
                                    <p className={classes.titleRadio}> Send to </p>
                                    <RadioGroup
                                        aria-label="Tags"
                                        name="tag1"
                                        className={classes.group}
                                        value={this.state.checked.toString()}
                                        onChange={this.handleCheck}
                                    >
                                        <FormControlLabel className='broadcast-rootRadio' value='false' control={<Radio color="primary"/>}
                                                          label="Customers that match with only one tag"/>
                                        <FormControlLabel className='broadcast-rootRadio' value='true' control={<Radio color="primary"/>}
                                                          label="Customers that match with all of the tags"/>
                                    </RadioGroup></div> : <div/>
                            }

                            <div className={classes.leftTabItem}>
                                <div>
                                    <p className={classes.titleRadio}> Send when </p>
                                    <RadioGroup
                                        aria-label="Tags2"
                                        name="tag2"
                                        className={classes.group}
                                        value={this.state.sendNow.toString()}
                                        onChange={this.toggleSendNow}
                                    >
                                        <FormControlLabel className='broadcast-rootRadio' value='true' control={<Radio color="primary"/>}
                                                          label="Send now"/>
                                        <FormControlLabel className='broadcast-rootRadio' value='false' control={<Radio color="primary"/>}
                                                          label="Schedule date & time"/>
                                    </RadioGroup></div>
                            </div>
                            {!this.state.sendNow ?
                                <div>
                                    <div className={classes.leftTabItem}>
                                        <h4 className={classes.description} style={{marginBottom: 10}}>
                                            Set date & time
                                        </h4>
                                        <div className={classes.inputHolder}>
                                            <div>
                                                {!this.state.sendNow && loaded && <DateTimePicker
                                                    className={`${classes.picker} ${classes.whiteInput}`}
                                                    style={{
                                                        fontSize: 14,
                                                        color: '#616581'
                                                    }}
                                                    value={shownDate}
                                                    disablePast
                                                    onChange={(event) => this.handleDateChange(event)}
                                                    leftArrowIcon={<Icon> keyboard_arrow_left </Icon>}
                                                    rightArrowIcon={<Icon> keyboard_arrow_right </Icon>}
                                                />}
                                            </div>
                                            <CalendarIcon/>
                                        </div>
                                    </div>
                                    <div style={{display: "block"}}>
                                        <FormControlLabel
                                            className='rootRadio'
                                            control={
                                                <Checkbox
                                                    checked={this.state.ifTimezone}
                                                    onChange={this.toggleTimezone}
                                                    // value="timezone"
                                                    color="primary"
                                                />
                                            }
                                            label={`Use user timezone (If disabled, it will use UTC ${this.timeFunc()}  timezone)`}
                                        />
                                    </div>
                                </div> : <div/>}
                            <div className={`${classes.button} ${classes.submitButton}`}>
                                <SubmitButton styles={{margin: 'auto', padding: '10px 20px'}}
                                              onClick={this.sendBroadcast} title='Confirm Broadcast'/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}

const mapStateToProps = (state) => (
    {
        tags: state.tags,
        activeBot: state.activeBot,
    });

export default withRouter(withStyles(styles, {withTheme: true})(connect(mapStateToProps)(BroadcastModal)));
