import React from "react";
import {withStyles} from "@material-ui/core";

import {styles, SwitchStyles} from './RandomAtomsStyles';
import Switch from "@material-ui/core/Switch";
import Tooltip from "../../../../uiKit/tooltip/Tooltip";
import States from "../States";
import {InitialState} from "./RandomAtomConfig";

const RandomAtoms = (props) => {
    const {classes, randomAtoms, sendFirstAtomOnlyOnce, updateRandomRedirect, updateSendFirstAtomOnlyOnce, onDelete} = props;
    const randomAtomsDisplay = sendFirstAtomOnlyOnce
        ? randomAtoms.filter((element) => element.number !== 0)
        : randomAtoms;

    const handleSwitch = (e) => {
        const firstTimeRedirect = Object.assign({}, InitialState.firstTimeRedirect);
        let newRandomAtoms = [...randomAtoms];

        if (e.target.checked) {
            newRandomAtoms = [firstTimeRedirect, ...newRandomAtoms];
        } else {
            newRandomAtoms.shift()
        }

        newRandomAtoms = newRandomAtoms.map((elem, index) => {
            elem.number = index;
            return elem
        });

        updateRandomRedirect(newRandomAtoms);
        updateSendFirstAtomOnlyOnce(e.target.checked)
    };

    const handleRandomAtoms = (name, number) => {
        let newRandomAtoms = [...randomAtoms];

        newRandomAtoms = newRandomAtoms.map((elem) => {
            if (elem.number === number) elem.atomName = name;
            return elem
        });
        updateRandomRedirect(newRandomAtoms);
    };

    const handleAddRandomAtom = () => {
        let newRandomAtoms = [...randomAtoms];
        const newRandomAtom = {
            atomName: '',
            number: newRandomAtoms.length + 1
        };

        newRandomAtoms = [...newRandomAtoms, newRandomAtom];
        updateRandomRedirect(newRandomAtoms);
    };

    const handleDeleteRandomAtom = (number) => {
        let newRandomAtoms = [...randomAtoms];

        newRandomAtoms = newRandomAtoms
            .filter(elem => elem.number !== number)
            .map((elem, index) => {
                elem.number = index;
                return elem
            });

        updateRandomRedirect(newRandomAtoms);
    };

    return (
        <div className={classes.container}>
            <div className={classes.header}>
                Random redirect
                <Tooltip tooltipId='tooltip-random-redirect-header'
                         tooltipText='Randomly redirects the user to one of the following atoms.'/>
            </div>

            <div className={classes.body}>
                <div className={classes.switcherWrap}>
                    <div>
                        First time redirect
                        <Tooltip tooltipId='tooltip-random-redirect-body'
                                 tooltipText='The user will be redirected to the following atom if he interacts with current atom for the first time.'/>
                    </div>
                    <Switch classes={SwitchStyles}
                            color='primary'
                            size="small"
                            checked={sendFirstAtomOnlyOnce}
                            onChange={handleSwitch}/>
                </div>

                {sendFirstAtomOnlyOnce && <States payload={(name) => handleRandomAtoms(name, 0)}
                                                  value={randomAtoms[0].atomName}/>}
                <div>
                    Random atoms
                    {randomAtomsDisplay
                        .map((element) => (
                            <div className={classes.atomWrap} key={element.number}>
                                {randomAtoms.length > 1
                                && <i className={classes.deleteRandomAtomWrap}
                                      onClick={() => handleDeleteRandomAtom(element.number)}>
                                    <img src="/images/platform/delete.svg" alt="delete" height="30"/>
                                </i>}

                                <States payload={(name) => handleRandomAtoms(name, element.number)}
                                        value={element.atomName}/>
                            </div>
                        ))}
                </div>

                {randomAtoms.length < 10
                && <div className={classes.addButton}
                        onClick={handleAddRandomAtom}>
                    + Add random atom
                </div>}

            </div>

            <div className="dlt-msg" onClick={onDelete}>
                <img src="/images/platform/delete.svg" alt="delete"/>
            </div>
        </div>
    )
};

export default withStyles(styles)(RandomAtoms);
