import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { withRouter } from "react-router-dom";
import uuid from 'uuid';
import Loader from "../loaders/loader";
import ReactTooltip from "react-tooltip";
import TabTemplate from "../../tabs/widget/components/TabTemplate";

const styles = theme => ({
    tableHeader: {
        borderBottom: '1px solid #EBEBEF',
        // background: '#F7FAFE',
        borderTop: 'none',
        padding: "10px 20px",
        display: "flex",
        justifyContent: "space-between",
    },
    titleContainer: {
        flexGrow: 1,
        flexBasis: 0,
        display: "flex",
        alignItems: 'center',
        maxWidth: 150,
        "&:last-child": {
            maxWidth: 50,
        },
        "&:nth-last-child(2)": {
            // maxWidth: 150,
        }
    },
    title: {
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "normal",
        fontSize: 14,
        color: "#3A3F62",
        display: "inline"
    },
    table: {
        minWidth: 500,
    },
    tableWrapper: {
        overflow: 'visible',
    },
    tableCellText: {
        margin: 0,
        maxWidth: 150,
    },
    view: {
        fontSize: "14px",
        textDecoration: "underline",
        color: "#1658F3",
        cursor: "pointer",
        margin: 0,
    },
    paper: {
        width: 800,
        padding: "30px 50px 40px",
    },
    footer: {
        display: "flex",
        justifyContent: "flex-end",
    },
    tooltip: {
        maxWidth: '80%',
        width: '200px',
        border: '1px solid #EBEBEF',
        boxSizing: 'border-box',
        boxShadow: '4px 4px 29px rgba(19, 69, 186, 0.0855129)',
        borderRadius: '10px',
        zIndex: 9999,
        opacity: 1
    }
});

class Table extends React.Component {

    state = {
        page: 0,
        open: false,
        rowsPerPage: 10,
    };

    render() {
        const { classes, children } = this.props;
        const { titles } = this.props;

        return (
            <div className={classes.tableWrapper}>
                <div className={classes.table}>
                    <div>
                        <div className={classes.tableHeader}>
                            {
                                titles.map((title, index) => {
                                    return (
                                        <div
                                            className={classes.titleContainer}
                                            style={{maxWidth: title.maxWidth}}
                                        >
                                            { typeof title === "string" && (
                                                <div key={index} className={classes.title}>{title}</div>
                                            )}
                                            {typeof title === "object" && !title.tooltipText && (
                                                <div key={index}
                                                     className={classes.title}>{title.title}</div>)
                                            }
                                            {typeof title === "object" && title.tooltipText && (
                                                <div>
                                                    <div className={classes.title} key={uuid()}>{title.title}</div>
                                                    <ReactTooltip className={classes.tooltip} place="bottom"
                                                                  type="light" effect="solid"
                                                                  id={`tooltip-1`}>
                                                        {title.tooltipText}
                                                    </ReactTooltip>
                                                    <img style={{ cursor: 'pointer', paddingLeft: '3px' }} data-tip
                                                         data-for={`tooltip-1`} src="/images/platform/information-button.svg"
                                                         alt="info icon"/>
                                                </div>
                                            )}
                                        </div>
                                    )
                                })
                            }
                        </div>
                        {!(children) ? <Loader/> : children}
                        <div className={classes.footer}>
                            {this.props.paginationComponent}
                        </div>
                    </div>
                </div>
            </div>)
    }
}

export default withRouter(withStyles(styles, { withTheme: true })(Table));
