import React from 'react';
import {withStyles} from '@material-ui/core/styles';

const styles = theme => ({
    inputContainer: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        textAlign: 'left'
    },
    input: {
        width: '100%',
        height: '46px',
        margin: '4px 0 24px',
        padding: '0 24px',
        border: '1px solid #D7D8DF',
        borderRadius: '10px',
        fontFamily: 'Lato, sans-serif',
        boxSizing: 'border-box',
        webkitBoxSizing: 'border-box',
        '&:focus': {
            outline: 'none',
            borderColor: '#1658F3'
        },
        '&.error': {
            borderColor: 'red'
        },
        '&::placeholder': {
            color: '#B0B2C0',
            fontSize: '14px',
            fontWeight: '300'
        },
        '&:disabled': {
            backgroundColor: '#EBEBEF'
        },
    },
    label: {
        fontSize: '14px',
        color: '#616581',
        margin: '0',
        fontWeight: '300'
    },
    errorLabel: {
        position: 'absolute',
        right: 0,
        color:'#FF624C',
        fontSize:14,
        textAlign:'center'
    }
});

const Input = ({id, name, type, required, placeholder, label, errorLabel, hasError,
                    onChange, onBlur, defaultValue, disabled, minLength, pressEnterCallback, classes}) => {
    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            if (pressEnterCallback) {
                pressEnterCallback();
            }
        }
    };

    return (
        <div className={classes.inputContainer}>
            <label className={classes.label}>{label}</label>
            <input className={`${classes.input} ${hasError ? 'error' : ''}`}
                   id={id}
                   name={name}
                   minLength={minLength}
                   type={type}
                   placeholder={placeholder}
                   onChange={onChange}
                   onBlur={onBlur}
                   disabled={disabled}
                   defaultValue={defaultValue}
                   onKeyPress={handleKeyPress}
                   required={required}/>
            {hasError &&
                    <p className={classes.errorLabel}>{errorLabel}</p>
            }
        </div>
    );
};

export default (withStyles(styles, {withTheme: true})(Input));
