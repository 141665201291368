export const styles = () => ({
    wrap: {
        background: '#ffffff',
        borderRadius: 23,
        boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.24)',
        width: 36,
        height: 36,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 2,
        position: 'absolute',
        cursor: 'pointer',
        top: '50%'
    }
});
