export const styles = () => ({
    container: {
        backgroundColor: "#ffffff",
        borderRadius: "10px",
        boxShadow: "4px 4px 29px rgba(19, 69, 186, 0.0855129)",
        width: "calc(100% - 40px)",
        justifyContent: "flex-start",
        maxHeight: "100%",
        display: "flex",
        flexDirection: "column",
        marginRight: 40,
        color: '#3A3F62',
        marginBottom: 26
    },
    chartWrap: {
        display: 'flex'
    },
    noDataWrap: {
        height: 496,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    noData: {
        color: 'rgba(53, 64, 82, 0.5)'
    }
});
