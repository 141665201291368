import React from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import {CircularProgress} from "@material-ui/core/CircularProgress";
import {cloneBot, createNewBotAPI} from "../api/bots";
import {connect} from "react-redux";

import LoaderSmall from '../../../uiKit/loaders/loaderSmall';
import Input from "../../../uiKit/inputs/Input";
import SubmitButton from "../../../uiKit/buttons/SubmitButton";
import {isStringEmpty} from "../../../helpers/isStringEmpty";


const styles = theme => ({
  group: {
    marginLeft: 10
  },
  description: {
    fontSize: 12,
    color: '#B0B2C0',
    fontFamily: 'Lato',
    marginBottom: 0
  },
  root: {
    width: '100%',
    marginTop: 16,
    '& span': {
      fontSize: 14,
      color: '#616581',
      fontFamily: 'Lato'
    },
    '& label': {
      margin: '-4px 16px -4px -14px'
    },
    '& span[class*="label"]': {
      marginLeft: 8
    },
    '&>div>label>span[class*="checked"]>span>svg': {
      color: '#1658F3'
    },
    '& span svg': {
      color: '#D7D8DF'
    },
    '& span svg path': {
      stroke: '1px solid #D7D8DF'
    },
  },
  borderLine: {
    borderBottom: '1px solid #EBEBEF',
    lineHeight: 0,
    margin: '24px 0 0'
  },
  modalHeading: {
    fontSize: '14px',
    lineHeight: '17px',
    fontFamily: 'Lato, "sans-serif"',
    color: '#3A3F62',
    margin: '24px 0 4px',
  },
  error: {
    color: theme.palette.alerts.error,
    marginBottom: '-10px',
    fontSize: 12
  },
  buttonHolder: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 32
  },
});

class NewBotModal extends React.Component {


  checkInputFields = () => {
    let botError = false;
    let botErrorType = 0;
    let botErrorText = '';
    const {name, clientToken, developerToken} = this.state;

    if (isStringEmpty(name)) {
      botError = true;
      botErrorText = 'Name field can\'t be empty!';
    } else if (this.checkBotName()) {
      botError = true;
      botErrorText = 'Chatbot with such name already exists!';
    } else if (this.state.withDialogFlow === "true") {
      if (isStringEmpty(clientToken)) {
        botError = true;
        botErrorType = 1;
        botErrorText = 'DialogFlow client token cannot be an empty field!';
      } else if (isStringEmpty(developerToken)) {
        botError = true;
        botErrorType = 2;
        botErrorText = 'DialogFlow developer token cannot be an empty field!';
      }
    }
    this.setState({
      botError,
      botErrorType,
      botErrorText
    });
    return !botError;
  };

  toggleLoader = () => {
    this.setState({showLoader: !this.state.showLoader});
  };
  errorHandler = (text) => {
      this.setState({
        botError:true,
        botErrorText: text,
        showLoader:false
      })
  }
  checkBotName = () => {
    return this.props.bots.find(bot => bot.name.trim().toUpperCase() === this.state.name.trim().toUpperCase());
  };

  constructor(props) {
    super(props);
    this.state = {
      name: props.botName,
      botError: false,
      botErrorText: '',
      withDialogFlow: "false",
      clientToken: '',
      developerToken: '',
      showLoader: false,
      buttonName: props.buttonName
    };
  }

  onSubmit() {
    if (this.checkInputFields()) {
      let bot = {
        name: this.state.name,
        dialogflowDevToken: this.state.developerToken,
        dialogflowClientToken: this.state.clientToken
      };
      this.toggleLoader();
      if (this.props.new) {
        createNewBotAPI(bot, () => this.props.onClose(), (text) => this.errorHandler(text));
      } else {
        cloneBot(this.props.botId, bot, () => this.props.onClose(), (text) => this.errorHandler(text));
      }
    }
  }

  handleChangeType(withDialogFlow) {
    let newState = {withDialogFlow};
    if (withDialogFlow === "false") {
      newState.clientToken = '';
      newState.developerToken = '';
      newState.botError = false;
    }
    this.setState({...newState});
  };

  render() {
    const {classes} = this.props;
    return (
        <div>
          <Input title="Enter new chatbot name"
                 value={this.state.name}
                 maxLength={255}
                 error={this.state.botError && isStringEmpty(this.state.name)}
                 placeholder="Enter bot name here"
                 onChange={event => this.setState({
                   name: event.target.value,
                   botError: isStringEmpty(event.target.value),
                 })
                 }
          />

          {this.state.botError && <p className={classes.error}>
            {this.state.botErrorText}
          </p>}
          <div className={classes.buttonHolder}>
            <SubmitButton title={this.state.buttonName} onClick={() => this.onSubmit()}
            />
          </div>
          <LoaderSmall showLoader={this.state.showLoader}/>
        </div>
    )
  }
}

NewBotModal.propTypes = {
  classes: PropTypes.object,
};

const mapStateToProps = (state) => (
    {
      bots: state.bots,
    });

export default withStyles(styles, {withTheme: true})(connect(mapStateToProps)(NewBotModal));
