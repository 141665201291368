export const styles = () => ({
    container: {
        display: 'flex',
        border: '1px solid #1658F3',
        borderRadius: 5,
        alignItems: 'center',
        height: 36,
        cursor: 'pointer',
        fontWeight: 'bold'
    },
    closeButton: {
        fontSize: 16,
        padding: '0px 16px',
        color: '#1658F3',
        whiteSpace: 'nowrap',
        border: 'none',
        backgroundColor: '#ffffff',
        fontWeight: 'bold',
        '&:disabled': {
            cursor: 'no-drop'
        }
    },
    iconWrap: {
        borderLeft: '1px solid #1658F3',
        height: '100%',
        width: 36,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    iconWrapDisabled: {
        borderLeft: '1px solid #1658F3',
        height: '100%',
        width: 36,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'no-drop'
    },
    popover: {
        width: 240,
        maxHeight: 180
    },
    popoverHeader: {
        height: 36,
        fontWeight: 'bold',
        display: 'flex',
        alignItems: 'center',
        fontSize: 14,
        padding: '0px 12px',
        borderBottom: '1px solid rgba(53, 64, 82, 0.1)'
    },
    atomItem: {
        padding: '7px 12px',
        cursor: 'pointer',
        fontSize: 14,
        '&:hover': {
            background: 'rgba(53, 64, 82, 0.1)'
        }
    },
    searchContainer: {
        height: 36,
        overflow: 'hidden',
        borderBottom: '1px solid rgba(53, 64, 82, 0.1)'
    }
});
