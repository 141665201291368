import React, {useState} from 'react';
import {withStyles} from '@material-ui/core';
import PropTypes from 'prop-types';
import {withRouter} from "react-router-dom";

import TabsWrap from "../TabsWrap/TabsWrap";
import OpenRequests from "../OpenRequests/OpenRequests";
import SolvedRequests from "../SolvedRequests/SolvedRequests";

import {OPEN_REQUESTS, SOLVED_REQUESTS} from "../TabsWrap/config";
import {styles} from './styles';

const DialogsContainer = (props) => {
    const {classes} = props;
    const [selectedTab, setSelectedTab] = useState(OPEN_REQUESTS);

    return (
        <div className={classes.container}>
            <TabsWrap selectedTab={selectedTab}
                      setSelectedTab={setSelectedTab}/>
            {selectedTab === OPEN_REQUESTS && <OpenRequests/>}
            {selectedTab === SOLVED_REQUESTS && <SolvedRequests/>}
        </div>
    );
};

DialogsContainer.propTypes = {
    classes: PropTypes.object
};

export default withRouter(withStyles(styles)(DialogsContainer));
