import {store} from '../../../index';

export const CLEAR_STATE = 'CLEAR_STATE';
export const SET_ACTIVE_BOT = 'SET_ACTIVE_BOT';
export const SET_DIALOG_FLOW = 'SET_DIALOG_FLOW';

export const clearOldBot = () => {
	store.dispatch(
		{
			type: CLEAR_STATE
		}
	);
};

export const setActiveBot = (activeBot) => {
	store.dispatch(
		{
			type: SET_ACTIVE_BOT,
			activeBot
		}
	);
};

export const updateDialogFlowConfigs = (dialogFlowConfigs) => {
	store.dispatch(
		{
			type: SET_DIALOG_FLOW,
			dialogFlowConfigs
		}
	);
};
