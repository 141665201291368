import React from "react";
import {Checkbox, FormControlLabel, IconButton, Popover, Tab, Tabs, Typography, withStyles} from "@material-ui/core";
import PropTypes from "prop-types";
import States from "../States";
import {urlValidation} from '../../../../helpers/urlValidation'

const styles = theme => ({
    button: {
        marginBottom: theme.spacing.unit * 4,
    },
    typography: {
        margin: theme.spacing.unit * 2,
    },
    popover: {
        borderRadius: 10,
        width: 280,
        height: 'auto'
    },
    tab: {
        minWidth: 90,
        width: 140,
        fontFamily: 'Lato, sans-serif',
    },
    tabs: {
        maxWidth: 280
    },
    tabIndicator: {
        backgroundColor: '#1890ff',
    },
    input: {
        padding: "10px",
        border: "none",
        outline: "none",
        borderRadius: "5px",
        fontSize: "14px",
        lineHeight: "19px",
        backgroundColor: "white",
        width: '100%'
    },
    inputHolder: {
        border: "1px solid #C7CAD6",
        width: "max-content",
        borderRadius: "10px",
        display: "flex",
        justifyContent: 'space-between',
        backgroundColor: "white",
        marginRight: 10,
        padding: 5,
        maxHeight: 34,
        minWidth: 220
    },
    addParametersBtn: {
        marginTop: 10,
        textAlign: "center",
        color: "black",
        cursor: "pointer",
        backgroundColor: "white",
        height: 40,
        border: '0.5px solid rgb(51, 51, 51)',
        padding: 10
    }
});

function TabContainer(props) {
    return (
      <Typography component="div" style={{padding: 8 * 3}}>
          {props.children}
      </Typography>
    );
}


class MessageButton extends React.Component {


    state = {
        open: false,
        anchorOriginVertical: 'top',
        anchorOriginHorizontal: 'center',
        transformOriginVertical: 'top',
        transformOriginHorizontal: 'center',
        positionTop: 200,
        positionLeft: 400,
        anchorReference: 'anchorEl',
        error: false,
    };

    handleChange = (event, value) => {
        let btn = {...this.props.btn};
        btn.type = value;

        if (btn.type === "postback") {
            btn.payload = '';
            btn.url = undefined;
            delete btn.messengerExtensions;
        } else if (btn.type === "web_url") {
            btn.payload = undefined;
            btn.url = '';
            btn.messengerExtensions = true;
        }
        this.props.onUpdate(btn);
    };
    handleClose = () => {
        if (this.props.btn.type === 'web_url' && !urlValidation(this.props.btn.url)) {
            this.setState({error: true});
        } else this.setState({open: false});
    };
    anchorEl = null;

    addParam = () => {
        let btn = {...this.props.btn};
        if (!btn.params) {
            btn.params = [];
        }
        btn.params.push({
            key: '',
            value: '',
        });
        this.props.onUpdate(btn);
    }

    handleChangeParam(value, paramIndex, fieldName) {
        let btn = {...this.props.btn};
        btn.params[paramIndex][fieldName] = value;
        this.props.onUpdate(btn);
    }

    deleteParam(paramIndex) {
        let btn = {...this.props.btn};
        btn.params.splice(paramIndex, 1);
        this.props.onUpdate(btn);
    }

    handleChangeButton(value, fieldName) {
        let btn = {...this.props.btn};
        btn[fieldName] = value;
        this.props.onUpdate(btn);
    }

    handleChangeButtonUrl(value) {
        let btn = {...this.props.btn};
        if (urlValidation(value)) {
            btn.url = value;
            if (this.state.error) {
                this.setState({
                    error: false
                })
            }
            this.props.onUpdate(btn);
        } else {
            this.setState({
                error: true
            })
        }
    }

    focus = () => {
        this.setState({
            open: true,
        });
    };

    render() {
        const {btn, rtl} = this.props;
        const {
            open,
            anchorOriginVertical,
            anchorOriginHorizontal,
            transformOriginVertical,
            transformOriginHorizontal,
            positionTop,
            positionLeft,
            anchorReference,
            error,
            errorTitle
        } = this.state;
        const {classes} = this.props;
        return (
          <div onMouseEnter={() => this.setState({showDelete: true})}
               onMouseLeave={() => this.setState({showDelete: false})}
          >
              <input type="text" className="button" placeholder="Button title"
                     readOnly
                     value={btn.title}
                     ref={node => {
                         this.anchorEl = node;
                     }}
                     onClick={this.focus}
                     style={{
                         color: "transparent",
                         textShadow: "0 0 0 #0084ff",
                         borderRadius: this.props.last ? " 0 0 10px 10px" : 0
                     }}
                     dir={rtl && 'rtl'}
              />
              <IconButton aria-label="Delete"
                          style={this.state.showDelete ? {
                              height: "36px",
                              position: "absolute",
                              right: -5
                          } : {display: "none"}}
                          onClick={this.props.onDelete}>
                  <img style={{height: 30}} src="/images/platform/delete.svg" alt="delete"/>
              </IconButton>
              <Popover
                open={open}
                onClose={this.handleClose}
                anchorEl={this.anchorEl}
                anchorReference={anchorReference}
                anchorPosition={{top: positionTop, left: positionLeft}}
                anchorOrigin={{
                    vertical: anchorOriginVertical,
                    horizontal: anchorOriginHorizontal,
                }}
                transformOrigin={{
                    vertical: transformOriginVertical,
                    horizontal: transformOriginHorizontal,
                }}
                PaperProps={{
                    style: {
                        overflow: "visible",
                        borderRadius: 10
                    },
                }}
              >
                  <div className={classes.popover}>
                      <input type="text" className="button" placeholder="Button title"
                             value={btn.title}
                             style={{border: errorTitle && '1px solid #FF624C',unicodeBidi: rtl && 'bidi-override'}}
                             onChange={(event) => this.handleChangeButton(event.target.value, "title")}
                             maxLength={20}
                             dir={rtl && 'rtl'}
                      />
                      <div>
                          <div>
                              <Tabs classes={{root: classes.tabs, indicator: classes.tabIndicator}} value={btn.type}
                                    onChange={(event, value) => this.handleChange(event, value)}>
                                  <Tab classes={{root: classes.tab,}} label="Postback" value="postback"/>
                                  <Tab classes={{root: classes.tab,}} label="URL" value="web_url"/>
                              </Tabs>
                              {btn.type === "postback" &&
                              <TabContainer> <States payload={(value) => this.handleChangeButton(value, "payload")}
                                                     value={btn.payload}/>
                                  <div style={{padding: 10}}>
                                      <div>
                                          {this.props.btn.params && this.props.btn.params.map((param, index) =>
                                            <div key={this.props.btn.id + index} style={{display: "flex"}}>
                                                <input placeholder="key"
                                                       value={param.key}
                                                       style={{width: 90, padding: 3}}
                                                       onChange={(event) => this.handleChangeParam(event.target.value, index, "key")}/>
                                                <p style={{margin: 2}}>=</p>
                                                <input placeholder="value"
                                                       value={param.value}
                                                       style={{width: 90, padding: 3}}
                                                       onChange={(event) => this.handleChangeParam(event.target.value, index, "value")}/>
                                                <p onClick={() => this.deleteParam(index)}
                                                   style={{
                                                       margin: 2,
                                                       cursor: "pointer"
                                                   }}>
                                                    x
                                                </p>
                                            </div>
                                          )}
                                      </div>
                                      {btn.type !== 'postback' ? ''
                                        : (
                                          <div onClick={this.addParam}
                                               className={classes.addParametersBtn}>
                                              ADD PARAMETERS
                                          </div>
                                        )}
                                  </div>
                              </TabContainer>}
                              {btn.type === "web_url" && <TabContainer>
                                  <div style={error ? {borderColor: '#FF624C'} : {borderColor: '#C7CAD6'}}
                                       className={classes.inputHolder}>
                                      <input className={classes.input}
                                             onKeyDown={(e) => {
                                                 if (e.keyCode === 13) this.handleChangeButtonUrl(e.target.value);
                                             }}
                                             onBlur={(event) => {
                                                 this.handleChangeButtonUrl(event.target.value)
                                             }}
                                             defaultValue={btn.url}
                                             placeholder="Enter URL"
                                      />
                                  </div>
                                  <FormControlLabel
                                    control={
                                        <Checkbox
                                          checked={btn.messengerExtensions}
                                          onChange={() => {
                                              let btn = {...this.props.btn};
                                              btn.messengerExtensions = !btn.messengerExtensions;
                                              this.props.onUpdate(btn);
                                          }}
                                          value="messengerExtensions"
                                          color="primary"
                                        />
                                    }
                                    label="Open in Messenger"
                                  />
                              </TabContainer>}
                          </div>
                      </div>
                  </div>
              </Popover>

          </div>
        )
    }
}

MessageButton.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MessageButton);
