import React from "react";
import Select from "react-select";

import {withStyles} from "@material-ui/core/styles";
import {styles} from "./Training-styles";
import {LEFT, RIGHT} from "./Training-config";
import classnames from "classnames";
import {addOptions} from "../../../settings/components/languages/config";

class TrainingHeaderView extends React.Component {

    getTabClasses = (value, selectedTab) => {
        const classes = this.props.classes;
        return classnames(
            {
                [`${classes.leftTab}`]: value === LEFT
            },
            {
                [`${classes.rightTab}`]: value === RIGHT
            },
            {
                [`${classes.activeNlp}`]: value === selectedTab
            },
            {
                [`${classes.defaultNlp}`]: value !== selectedTab
            }
        )
    };

    render() {
        const {classes, changeTab, selectedTab, selectedLanguageOption, languageOptions, handleLanguageChange} = this.props;
        return (
            <div className={classes.headerWrapper}>
                <div className={classes.header}>
                    <h1 className={classes.title}>Training</h1>

                    <div className={classes.tabsTrain}>
                        <div className={this.getTabClasses(LEFT, selectedTab)}
                             onClick={() => changeTab(LEFT)}
                        >
                            Training
                        </div>
                        <div className={this.getTabClasses(RIGHT, selectedTab)}
                             onClick={() => changeTab(RIGHT)}
                        >
                            History
                        </div>
                    </div>
                </div>

                <div className={classes.filterWrapper}>
                    <p className={classes.filterText}>
                        Filter
                    </p>
                    <div className={classes.selectWrapper}>
                        <Select
                            options={languageOptions}
                            value={selectedLanguageOption}
                            onChange={(option) => handleLanguageChange(option)}
                        />
                    </div>
                </div>
            </div>
        )


    }
}

export default withStyles(styles)(TrainingHeaderView);
