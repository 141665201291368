export const fieldsList = [
    {
        title: 'Channel Id',
        description: 'Find Channel Id in your Amio channels',
        placeholder: 'Enter channel id',
        key: 'channelId'
    },
    {
        title: 'Access Token',
        description: 'Find Access Token in your Amio settings',
        placeholder: 'Enter WhatsApp access token',
        key: 'accessToken'
    },
    {
        title: 'Secret',
        description: 'Find Secret in your Channel Webhook settings',
        placeholder: 'Enter secret',
        key: 'secret'
    }
];