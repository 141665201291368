import {store} from '../../../index';

export const SAVE_BROADCASTS = 'SAVE_BROADCASTS';

export const saveBroadcasts = (broadcasts) => {
  store.dispatch(
    {
      type: SAVE_BROADCASTS,
      broadcasts
    }
  );
};
