import {store} from "../../../index";

export const SAVE_ACCOUNT_SETTINGS = 'SAVE_ACCOUNT_SETTINGS';

export const saveAccountSettings = (accountSettings) => (
    store.dispatch(
        {
            type: SAVE_ACCOUNT_SETTINGS,
            accountSettings
        }
    )
)
