/**
 * Created by Admin on 13.02.2018.
 */
import React from "react";
import {connect} from "react-redux";
import States from "../States";

const styles = {
    container: {
        marginTop: 10,
        padding: 10,
    },

};

class RedirectToAtom extends React.Component {
    state = {};

    render() {

        return (
            <div className='message' style={styles.container}>
                <p>Redirect to atom</p>
                <States payload={(value) => this.props.onChange(value)}
                        value={this.props.value}/>

                <div className="dlt-msg" onClick={() => this.props.onDelete()}>
                    <img src="/images/platform/delete.svg" alt="delete"/>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => (
    {
        params: state.params
    });

export default connect(mapStateToProps)(RedirectToAtom);
