import React from "react";

import { HomeHeader } from "./components/HomeHeader";
import { AccountSettingsForm } from "./components/AccountSettingsForm";

export const AccountContainer = props => {
    const backUrl = props.location && props.location.state && props.location.state.from;

    return (
        <React.Fragment>
            <HomeHeader
                title="Account Settings"
                backUrl={backUrl}
                backButton={true}
            />
            <AccountSettingsForm />
        </React.Fragment>
    )
}
