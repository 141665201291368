export const styles = (theme) => ({
    root: {
        display: 'flex',
        position: 'relative'
    },
    button: {
        height: "100%",
        padding: "0 10px",
        fontSize: 16,
        color: "#3A3F62",
        fontWeight: "bold",
        textTransform: "none",
        fontFamily: "'Lato', sans-serif",

        "& p": {
            margin: 0,
        }
    },
    paper: {
        marginRight: 0,
    },
    popper: {
        right: "0 !important",
        left: "auto !important",
        top: "58px !important",
    },
    userImg: {
        display: 'block',
        width: 32,
        height: 32,
        borderRadius: "50%",
        marginRight: 5,
    },
    list: {
        padding: 0
    },
    listItem: {
        padding: "8px 12px",
        color: "#354052",
        fontSize: 14,
        lineHeight: 20,

        "& a": {
            color: "inherit",
            textDecoration: "none",

            "&:hover": {
                textDecoration: "none",
            }
        }
    }
});
