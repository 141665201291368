export const styles = () => ({
    img: {
        width: 328,
        height: 150,
        borderRadius: '8px',
        backgroundColor: '#fffff',
        margin: '12px 0px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        objectFit: 'contain',
        border: '1px solid #E5E5E5'
    }
});
