import React from "react";
import styled from 'styled-components';
import {Slide, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const CustomToastContainer = () => {
    return (
        <ToastContainer
            position="top-right"
            transition={Slide}
            draggable
            newestOnTop={true}
            hideProgressBar={true}
        />
    );
}

export const StyledToastContainer = styled(ToastContainer).attrs({
    position: "top-right",
    transition: Slide,
    draggable:true,
    newestOnTop:true,
    hideProgressBar:true
})`
    .Toastify__toast {
        border-radius: 10px;
        top: 4em;
        background-color: #5a98f7;
        padding: 14px 18px;
    }
    .Toastify__toast-body {
        font-size: 16px;
        font-family: Lato, sans-serif;   
    }
`