/**
 * Created by serhiy on 12.03.18.
 */
import React from "react";
import PropTypes from "prop-types";
import {CloseIcon, PenIcon} from "../../../uiKit/icons/Icons";
import {withStyles} from "@material-ui/core/styles";
import {withRouter} from "react-router-dom";
import {isStringEmpty} from "../../../helpers/isStringEmpty";

const styles = theme => ({
    input: {
        border: "none",
        outline: "none",
        width: '100%',
        borderRadius: "5px",
        fontSize: "14px",
        lineHeight: "19px",
        backgroundColor: "white",
        color: '#1658F3',
    },
    red: {
        border: '1px solid #FF624C',
        color: '#FF624C',
        width: "max-content",
        borderRadius: "10px",
        display: "flex",
        justifyContent: 'space-between',
        backgroundColor: "white",
        marginRight: 10,
        padding: 10,
        maxHeight: 45,
        minWidth: "100%"
    },
    inputHolder: {
        border: "1px solid #C7CAD6",
        width: "max-content",
        borderRadius: "10px",
        display: "flex",
        justifyContent: 'space-between',
        backgroundColor: "white",
        marginRight: 10,
        padding: 10,
        maxHeight: 45,
        minWidth: "100%"
    },
    disabled: {
        border: "1px solid white",
        marginRight: 10,
        maxHeight: 45,
    },
    userSays: {
        fontSize: 14,
        color: '#1658F3',
        margin: 0,
    },
    none: {
        display: 'none',
    },
    '@global': {
        '.addPhrase::placeholder,.addPhrase': {
            color: '#1658F3'
        },
        '.userSay:hover .editPhrase': {
            display: 'flex',
            alignItems: 'center',
        },
        '.class*=[none]:hover .editPhrase': {
            display: 'none'
        },
        '.editPhrase': {
            display: 'none'
        }
    }
});


class UserSays extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            red: false
        };
    }

    deleteUserPhrase(index, e) {
        let id = this.props.index;
        this.props.deleteUserPhrase(id, e);
    }

    focusTextInput(id) {
        this.props.modifyInput(id);
    }

    onBlur() {
        this.setState({focused: false})
    }

    onFocus() {
        this.setState({focused: true})
    }

    handleOnBlur = (e) => {
        if (isStringEmpty(e.target.value)) {
            this.setState({
                red: true
            })
        } else {
            this.onBlur();
            this.props.editUserPhrase(this.props.index, e);
            if (this.props.emptySay == this.props.index) {
                this.props.setEmptySay(null);
                this.props.modifyInput(null);
            }
        }

    }

    render() {
        const {classes, says, index, emptySay, isRtl} = this.props;
        const {red} = this.state;
        let modify = this.props.emptySay === index;

        return (
            <div className={`${classes.userSays} userSay`}>
                <div

                    style={{display: 'flex', flexAlign: 'center'}}
                    className={modify ? (!red ? classes.inputHolder : classes.red) : classes.disabled}>
                    <div
                        style={{flexBasis: '90%'}}
                        onClick={(e, index) => {
                            let id = this.props.index;
                            this.focusTextInput(id);
                        }}><input
                        dir={isRtl && 'rtl'}
                        maxLength={255}
                        ref={(node) => {
                            this.textInput = node
                        }}
                        autoFocus={modify}
                        readOnly={this.props.editInput !== index}
                        onBlur={this.handleOnBlur}
                        onKeyPress={(event) => {
                            if (event.charCode === 13 && event.target.value !== '') {
                                this.props.editUserPhrase(index, event);
                                this.onBlur();
                                if (emptySay == index) {
                                    this.props.setEmptySay(null);
                                }
                            }
                        }}
                        onFocus={() => this.onFocus()}
                        className={` ${classes.input} addPhrase`}
                        style={this.props.editInput === index ? {padding: '2px 10px'} : {padding: '15px 32px 0px'}}
                        defaultValue={says}/>

                    </div>

                    <div className={`editPhrase ${this.props.editInput === index ? classes.none : ''}`}
                         style={this.props.editInput !== index ? {
                             justifyContent: 'space-between',
                             cursor: 'pointer',
                             alignItems: 'center',
                             width: 38
                         } : {
                             display: 'none'
                         }}>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                             className={this.props.editInput === index ? classes.none : ''}
                             onClick={(e, index) => {
                                 let id = this.props.index;
                                 this.props.modifyInput(id);
                                 this.props.setEmptySay(id);
                             }}>
                            <PenIcon/>
                        </div>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                             onClick={(e, index) => {
                                 this.deleteUserPhrase(e, index)
                             }}>
                            <CloseIcon
                                width='10px' height='10px' color='#B0B2C0'/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

UserSays.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(UserSays));
