import {store} from "../../../index";

export const SAVE_SUPPORT_REQUESTS = 'SAVE_SUPPORT_REQUESTS';

export const saveSupportRequests = (requests) => (
    store.dispatch(
        {
            type: SAVE_SUPPORT_REQUESTS,
            requests
        }
    )
);
