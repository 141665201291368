import React from "react";
import PropTypes from "prop-types";
import {CSVLink} from "react-csv";
import moment from "moment";
import {withStyles} from "@material-ui/core";

const styles = theme => ({
    button: {
        fontFamily: 'Lato, sans-serif',
        padding: 0,
        margin: 0,
        cursor: 'pointer',
        fontWeight: 400,
        color: '#1658F3'
    }
});

const addTimeInterval = (start, end, hourly, name) => {
    const startDate = moment(start).format("DD-MM-YYYY");
    const endDate = moment(end).format("DD-MM-YYYY");

    return hourly && name === 'total_users' ? 'for_last_24h' : `${startDate}_${endDate}`;
};

const ButtonCsv = ({classes, data, fileName, headers, startDate, endDate, hourly}) => (
    <CSVLink className={classes.button}
             data={data}
             filename={`${fileName}_${addTimeInterval(startDate, endDate, hourly, fileName)}.csv`}
             headers={headers}
             style={{textDecoration: 'none'}}>
        Download
    </CSVLink>
);

ButtonCsv.propTypes = {
    classes: PropTypes.object,
    styles: PropTypes.object,
    disabled: PropTypes.bool,
    headers: PropTypes.array,
    data: PropTypes.array
};

export default withStyles(styles, {withTheme: true})(ButtonCsv);