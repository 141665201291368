/**
 * Created by Admin on 02.02.2018.
 */

import {SAVE_BOTS} from "../actions/bots";
import {access} from "../../../security";

const bots = (state = [], action) => {
    switch (action.type) {
        case SAVE_BOTS:
            access.loadRole();
            return action.bots;
        default:
            return state;
    }
};

export default bots
