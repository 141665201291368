export const styles = () => ({
    container: {
        height: 66,
        padding: '8px 16px',
        display: 'flex'
    },
    profileImage: {
        height: '50px',
        width: '50px',
        borderRadius: '50%',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        position: 'relative',
    },
    channelIcon: {
        width: 20,
        height: 20,
        marginLeft: 5,
        position: 'absolute',
        bottom: 0,
        right: 0,
    },
    userInfo: {
        width: 'calc(100% - 50px)',
        height: '100%',
        marginLeft: 15,
        overflow: 'hidden'
    },
    lastMsgTextWrap: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: 'calc(100% - 12px)'
    },
    lastMsgText: {
        fontSize: 12,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: 'calc(100% - 30px)',
        margin: 0,
        textDecoration: 'none !important',
        color: '#B0B2C0'
    },
    lastMsgTextUnread: {
        fontSize: 12,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: 'calc(100% - 30px)',
        margin: 0,
        textDecoration: 'none !important',
        color: '#616581',
        fontWeight: 'bold'
    },
    activeLink: {
        '& div': {
            backgroundColor: '#eff5ff'
        }
    }
});
