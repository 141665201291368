import {BACKEND_URL} from '../../../configs';

export function signUpInvitedUser(user) {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json;charset=UTF-8');
    return fetch(BACKEND_URL + "/invite/user", {
        method: "POST",
        headers: headers,
        credentials: 'same-origin',
        body: JSON.stringify(user)
    })
}

export function signUpUser(user) {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json;charset=UTF-8');
    return fetch(BACKEND_URL + "/signUp/user", {
        method: "POST",
        headers: headers,
        credentials: 'same-origin',
        body: JSON.stringify(user)
    })
}

export function requestPasswordReset(email) {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json;charset=UTF-8');
    return fetch(BACKEND_URL + "/resetPassword/requestReset", {
        method: "POST",
        headers: headers,
        credentials: 'same-origin',
        body: JSON.stringify({email})
    })
}

export function validateResetPasswordToken(token) {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json;charset=UTF-8');
    return fetch(BACKEND_URL + "/resetPassword/validateToken", {
        method: "POST",
        headers: headers,
        credentials: 'same-origin',
        body: JSON.stringify({token})
    })
}

export function changeAdminPasswordWithResetToken(token, newPassword) {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json;charset=UTF-8');
    return fetch(BACKEND_URL + "/resetPassword/changePassword", {
        method: "POST",
        headers: headers,
        credentials: 'same-origin',
        body: JSON.stringify({token, newPassword})
    })
}

export function getBotInviteInfo(code) {
    return fetch(BACKEND_URL + "/invite/" + code, {
        method: "GET",
        credentials: 'same-origin'
    })
}

export function checkIfEmailIsRegistered(email) {
    return fetch(BACKEND_URL + "/signUp/checkEmail?email=" + email, {
        method: "POST",
        credentials: 'same-origin'
    })
}

export function login(user){
    return fetch(BACKEND_URL + "/login?email=" + user.username + "&password=" + user.password, {
        method: "POST",
        credentials: 'same-origin'
    })
}
