import React from 'react';

import {withStyles} from "@material-ui/core/styles";

import {styles} from '../slider/ArrowIconsStyles'
import {Next} from "../slider/Next";

const NextIcon = (props) => {
    const {classes, onClick, top} = props;

    return (
        <div className={classes.wrap} style={{right: -7, top}} onClick={onClick}>
            <Next/>
        </div>
    )
};

export default withStyles(styles)(NextIcon);
