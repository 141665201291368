/**
 * Created by Admin on 26.02.2018.
 */
import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import {deleteBot, updateBot} from '../../../home/api/bots';
import SubmitButton from '../../../../uiKit/buttons/SubmitButton';
import Heading from "../../../../uiKit/texts/Heading";
import Modal from "../../../../uiKit/Modal";
import Input from "../../../../uiKit/inputs/Input";
import Paragraph from "../../../../uiKit/texts/Paragraph";

const styles = theme => ({
    block: {
        borderBottom: '1px solid #EBEBEF',
        paddingBottom: 32,
        marginBottom: 32,
    },
    buttonsHolder: {
        display: 'flex',
        justifyContent: 'space-around',
        padding: "24px 70px",
    },
    btnDelete: {
        marginTop: 16,
        cursor: 'pointer',
        border: '1px solid rgba(97, 101, 129, 0.5)',
        boxSizing: 'content-box',
        borderRadius: 10,
        color: 'rgba(97, 101, 129, 0.5)',
        padding: '8px 28px',
        width: 'max-content',
        fontSize: 14,
        lineHeight: 'normal',
        fontFamily: 'Lato'
    },
});

class General extends React.Component {
    state = {
        open: false,
        changed: false,
    };
    handleOpen = () => {
        this.setState({open: true});
    };
    handleClose = () => {
        this.setState({open: false});
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if (!prevState.changed)
            return {
                bot: nextProps.activeBot
            };
        return null;
    }

    handleChangeName(value) {
        let bot = JSON.parse(JSON.stringify(this.state.bot));
        bot.name = value;
        this.setState({bot, changed: true});
    }

    handleUpdate() {
        updateBot(this.state.bot, () => this.setState({changed: false}));
    }

    render() {
        const {classes} = this.props;

        return (
            <div className={classes.container}>
                <div className={classes.block}>
                    <Heading>Edit chatbot's name</Heading>
                    <Input
                        title="New chatbot's name"
                        style={{width: 300}}
                        value={this.state.bot.name}
                        placeholder="Enter bot Name"
                        onChange={event => this.handleChangeName(event.target.value)}
                    />
                    <SubmitButton
                        styles={{marginTop: 16}}
                        title="Update"
                        onClick={() => {
                            this.handleUpdate()
                        }}
                    />
                </div>
                <div className={classes.block}>
                    <Heading>Delete Bot</Heading>
                    <Paragraph>
                        Deleting a bot will destroy the agent with all corresponding data and cannot be undone.
                    </Paragraph>
                    <div
                        className={classes.btnDelete}
                        onClick={() => {
                            this.setState({
                                open: true
                            });
                        }}
                    >
                        Delete
                    </div>
                    <Modal
                        open={this.state.open}
                        onClose={this.handleClose}
                        title="Delete the chatbot"
                    >
                        <Paragraph>
                            Are you sure you want to delete a chatbot
                            <span style={{color: '#1658F3'}}>{' ' + this.state.bot.name}</span>
                            ? You won't be able to undo this action.
                        </Paragraph>
                        <div className={classes.buttonsHolder}>
                            <SubmitButton onClick={() => deleteBot(this.state.bot.id)}
                                          title="Delete"
                            />
                            <SubmitButton onClick={this.handleClose}
                                          empty
                                          title="Cancel"
                            />
                        </div>
                    </Modal>
                </div>
            </div>
        );
    }
}

General.propTypes = {
    classes: PropTypes.object.isRequired
};

const mapStateToProps = state => (
    {
        activeBot: state.activeBot
    });

export default withRouter(withStyles(styles, {withTheme: true})(connect(mapStateToProps)(General)));
