import React, {Component} from "react";
import {Redirect} from "react-router-dom";
import {withStyles} from '@material-ui/core/styles';

const styles = () => ({
    main: {
        width: '100%',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '32px',
        backgroundColor: '#F7FAFE',
        textAlign: 'center'
    },
    imageNotFound: {
        width: '150px',
        height: '150px'
    },
    titleClass: {
        fontSize: '36px',
        lineHeight: 1,
        color: '#3A3F62',
        margin: '64px 0 16px',
        fontWeight: 'bold',
    },
    paragraphClass: {
        fontSize: '14px',
        lineHeight: '20px',
        fontWeight: 'normal',
        margin: 0,
        color: '#616581'
    },

    backToDashboardButton: {
        height: '54px',
        borderRadius: '10px',
        border: 'none',
        fontWeight: 300,
        lineHeight: 'normal',
        fontSize: '16px',
        textAlign: 'center',
        padding: '0 25px',
        color: '#FFF',
        cursor: 'pointer',
        background: '#1658F3',
        boxShadow: '0 3px 9px rgba(19, 69, 186, 0.206267)',
        margin: '32px 0 0',
        "&:hover": {
            transform: 'scale(1.05)',
            transition: '.1s ease-in-out'
        }
    }

});

class Unavailable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirectToAdmin: false,
        };
    }

    handleGoDashboard = () => {
        this.setState({redirectToAdmin: true});
    };

    render() {
        const {fullScreen, classes} = this.props;
        if (this.state.redirectToAdmin) {
            return <Redirect to='/admin'/>
        }
        return (
            <main className={classes.main}>
                <img className={classes.imageNotFound} src={"/images/platform/link.png"}/>

                <h1 className={classes.titleClass}>
                    Sorry, this link is not available anymore
                </h1>

                {this.props.linkExpired !== true && <p className={classes.paragraphClass}>
                    You've already been invited to the BotsCrew Enterprise Platform. Enjoy!
                </p>}

                <button className={classes.backToDashboardButton} type="button" onClick={this.handleGoDashboard}>Back to
                    Dashboard
                </button>
            </main>
        )
    }
}

export default withStyles(styles, {withTheme: true})(Unavailable);
