import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { CloseIcon, PenIcon } from '../../../uiKit/icons/Icons';

const styles = theme => ({
  input: {
    outline: 'none',
    width: '100%',
    padding: '14px 24px',
    borderRadius: 10,
    fontSize: '14px',
    lineHeight: '19px',
    color: theme.palette.primary.text,
    border: `1px solid ${theme.palette.primary.contrastText}`,
    maxHeight: 45,
    '&:hover': {
      color: theme.palette.primary.dark
    },
    '&:focus': {
      color: theme.palette.primary.main,
      border: '1px solid #C7CAD6'
    }
  },
  alert: {
    color: theme.palette.alerts.error,
    marginBottom: '0px',
    fontSize: 12
  },
  error: {
    border: `1px solid ${theme.palette.alerts.error}`,
    color: theme.palette.alerts.error,
    outline: 'none',
    width: '100%',
    padding: '14px 24px',
    borderRadius: 10,
    fontSize: '14px',
    lineHeight: '19px',
    maxHeight: 45
  },
  inputHolder: {
    border: '1px solid #C7CAD6',
    width: 'max-content',
    borderRadius: '10px',
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: 'white',
    marginRight: 10,
    padding: 10,
    maxHeight: 45,
    minWidth: '100%'
  },
  disabled: {
    border: '1px solid white',
    marginRight: 10,
    maxHeight: 45
  },
  iconsHolder: {
    display: 'none'
  },
  none: {
    display: 'none'
  },
  editUrl: {
    display: 'none'
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    margin: '0 5px'
  },
  wrapper: {
    display: 'flex',
    flexAlign: 'center'
  },
  webUrls: {
    fontSize: 14,
    color: theme.palette.primary.main,
    cursor: 'pointer',
    '&:hover .iconsHolder': {
      display: 'flex'
    }
  },
  '@global': {
    '.iconsHolder': {
      display: 'none'
    }
  }
});


class Url extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      text: null
    };
  }

  deleteWebUrl(index, e) {
    const id = this.props.index;
    this.props.deleteWebUrl(id, e);
  }

  handleText(value) {
    this.setState({
      text: value
    });
  }


  componentDidMount() {
    this.setState({
      text: this.props.url
    });
  }


  render() {
    const {
      classes, url, index, focus
    } = this.props;
    const { text } = this.state;
    return (
      <div className={`${classes.webUrls} webUrl`}>
        <div
          style={focus ? {} : {
            cursor: 'pointer'
          }}
          className={classes.wrapper}
        >
          <div
            style={{ width: '100%' }}
          >
            <input
              maxLength={255}
              autoFocus={focus}
              onBlur={() => this.props.editWebUrl(index, this.state.text)}
              onFocus={() => {
                if (!focus) {
                  this.props.modifyInput(this.props.index);
                  if (!text) this.handleText(url);
                }
              }}
              onChange={event => this.handleText(event.target.value)}
              onKeyPress={(e) => {
                if (e.charCode === 13) this.props.editWebUrl(index, this.state.text);
              }}
              value={text}
              className={classes.input}
              defaultValue={url}
            />
          </div>

          <div
            className={focus ? classes.none : 'iconsHolder'}
          >
            <div
              className={classes.icon}
              onClick={() => {
                this.props.modifyInput(this.props.index);
              }}
            >
              <PenIcon />
            </div>
            <div
              className={classes.icon}
              onClick={(e, index) => {
                this.deleteWebUrl(e, index);
              }}
            >
              <CloseIcon
                width="10px"
                height="10px"
                color="#B0B2C0"
              />
            </div>
          </div>
        </div>
        {this.props.error && (
          <p className={classes.alert}>
            {`Url '${this.props.invalidValue}' is invalid`}
          </p>
        )}
      </div>
    );
  }
}

Url.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withRouter(withStyles(styles)(Url));
