export const styles = () => ({
    timeLeftContent: {
        margin: '0px 4px',
        padding: '2px 4px',
        color: '#354052',
        fontSize: '10px',
        fontStyle: 'normal',
        fontWeight: 'normal',
        lineHeight: '12px',
        fontFamily: 'Lato, sans-serif',
        borderRadius: '4px',
        backgroundColor: '#ebecee',
        width: 'max-content'
    },
    timeLeftContentRed: {
        margin: '0px 4px',
        padding: '2px 4px',
        color: '#FFFFFF',
        fontSize: '10px',
        fontStyle: 'normal',
        fontWeight: 'normal',
        lineHeight: '12px',
        fontFamily: 'Lato, sans-serif',
        borderRadius: '4px',
        backgroundColor: '#fc6769',
        width: 'max-content'
    },
    timeExpiredIcon: {
        width: 20,
        height: 20,
        marginLeft: 5,
    }
});
