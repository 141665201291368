import React from 'react';
import {withStyles} from '@material-ui/core';
import PropTypes from 'prop-types';

import {styles} from './styles';

const Buttons = (props) => {
    const {classes, buttons} = props;

    return (
        <div className={classes.wrap}>
            {buttons.map((button, index) => (
                <div className={index === (buttons.length - 1) ? classes.lastButton : classes.button}>
                    {button.title}
                </div>
            ))}
        </div>
    );
};

Buttons.propTypes = {
    classes: PropTypes.object,
    buttons: PropTypes.array
};

export default withStyles(styles)(Buttons);
