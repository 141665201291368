/**
 * Created by Admin on 05.02.2018.
 */
import React from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import { Dialog } from "../../../../uiKit/UI/Dialog";
import { DialogTitle } from "../../../../uiKit/UI/DialogTitle";
import { DialogActions } from "../../../../uiKit/UI/DialogActions";

const styles = theme => ({
    paper: {

        width: theme.spacing.unit * 50,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 4,
    },
    flow: {
        boxShadow: '4px 4px 29px rgba(19, 69, 186, 0.14)',
        borderRadius: 10,
    },
    button: {
        background: '#1658F3',
        boxShadow: '0px 3px 9px rgba(19, 69, 186, 0.206267)',
        borderRadius: 10,
        color: 'white',
        fontFamily: "'Lato', sans-serif",
        '& + button': {
            margin: '0 0 0 10px'
        }
    },
    "@global": {
        "[class*='MuiDialog-paperWidthSm']": {
            boxShadow: '4px 4px 29px rgba(19, 69, 186, 0.14)',
            borderRadius: 10
        },
        ".buttonHover:hover": {
            background: 'white',
            color: '#1658F3'
        },
        ".buttonHover1:hover": {
            color: 'white',
            background: '#1658F3'
        },
        ".buttonHover1": {
            background: 'white',
            color: '#1658F3'
        }
    }
});

class DeleteAccess extends React.Component {
    state = {
        open: false,
    };

    handleOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    submit = () => {
        this.props.submit(this.props.id, this.props.inviteAccessId);
        this.setState({ open: false });
    };

    render() {
        const { classes } = this.props;

        return (
          <div   style={{margin: "0 auto"}}>

              <div onClick={()=>this.handleOpen()}>
                  <a href style={{color: "red", textDecoration: "underline", cursor: "pointer"}}>Remove</a>
              </div>
              <Dialog
                PaperProps={{}}
                open={this.state.open}
                keepMounted
                onClose={this.handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
              >
                  <DialogTitle disableTypography id="alert-dialog-slide-title">
                      <Typography component="h5" variant="h5">
                          {"Are you sure you want to delete access for '" + this.props.email + "'?"}
                      </Typography>
                  </DialogTitle>
                  <DialogActions>
                      <Button className={`${classes.button} buttonHover`} onClick={this.submit} color="primary">
                          Delete
                      </Button>
                      <Button className={`${classes.button} buttonHover1`} onClick={this.handleClose}>
                          Cancel
                      </Button>
                  </DialogActions>
              </Dialog>
          </div>
        );
    }
}

DeleteAccess.propTypes = {
    classes: PropTypes.object.isRequired,
};

const DeleteAccessExport = withStyles(styles)(DeleteAccess);

export default DeleteAccessExport;
