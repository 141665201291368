/**
 * Created by Admin on 19.03.2018.
 */
import React from "react";

import "./ParamsOld.css";
import ParamsList from "./ParamsList";
import {connect} from "react-redux";

class ParamsOld extends React.Component {

    state = {
        show: false,
    };

    componentDidMount() {
        let ua = window.navigator.userAgent.toLowerCase();
        let isIE = !!ua.match(/msie|trident\/7|edge/);
        let isWinPhone = ua.indexOf('windows phone') !== -1;
        let isIOS = !isWinPhone && !!ua.match(/ipad|iphone|ipod/);

        this.setState({isIE: isIE});

        if (isIOS) {
            this.highlights.css({
                'padding-left': '+=3px',
                'padding-right': '+=3px'
            });
        }
        this.highlights.innerHTML = this.applyHighlights(this.textarea.value);
    }

    applyHighlights(text) {
        text = text
            .replace(/\n$/g, '\n\n')
            .replace(/{{[^{]*?}}/g, '<mark>$&</mark>');
        if (this.state.isIE) {
            text = text.replace(/ /g, ' <wbr>');
        }
        return text;
    }

    handleScroll(elem) {
        this.backdrop.scrollTop = elem.scrollTop;
        this.backdrop.scrollLeft = elem.scrollLeft;
    }

    handleInput(text) {
        this.highlights.innerHTML = this.applyHighlights(text);
        if (this.props.params != null && text[this.textarea.selectionStart - 1] === "{" && text[this.textarea.selectionStart - 2] === "{") {
            this.setState({show: true, pos: this.textarea.selectionStart});
        }
    }

    addParam(param) {
        let text = "" + this.props.value;
        this.props.onChange(text.slice(0, this.state.pos) + param + text.slice(this.state.pos));
        this.setState({show: false});
    }

    componentDidUpdate() {
        this.highlights.innerHTML = this.applyHighlights(this.textarea.value);
    }

    render() {
        const {rtl} = this.props;
        const {disableBorderRadius} = this.props;

        return (
            <div style={{textAlign: "initial"}}>
                <div className="container"
                     style={{height: this.props.height ? this.props.height : "120px", padding: 0}}>
                    <div className="backdrop"
                         style={{
                             height: this.props.height ? this.props.height : "120px",
                             borderRadius: disableBorderRadius && '0px'
                         }}
                         ref={(div) => {
                             this.backdrop = div;
                         }}
                    >
                        <div className="highlights" ref={(div) => {
                            this.highlights = div;
                        }}/>
                    </div>
                    <textarea className="textarea"
                              dir={rtl && 'rtl'}
                              style={{
                                  height: this.props.height ? this.props.height : "120px",
                                  borderRadius: disableBorderRadius && '0px'
                              }}
                              value={this.props.value}
                              placeholder={this.props.placeholder}
                              onChange={(event) => this.props.onChange(event.target.value)}
                              onScroll={(event) => this.handleScroll(event.target)}
                              onInput={(event) => this.handleInput(event.target.value)}
                              ref={(textarea) => {
                                  this.textarea = textarea;
                              }}
                    />
                    {this.props.params != null && <ParamsList
                        options={this.props.params}
                        addParam={(param) => this.addParam(param)}
                        show={this.state.show}
                        onClose={() => this.setState({show: false})}
                        pos={this.textarea ? this.textarea.getBoundingClientRect() : {}}
                    />}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => (
    {});

export default connect(mapStateToProps)(ParamsOld);
