import React from 'react';
import TabTemplate from "../TabTemplate";
import {DelayIcon} from "../../../../uiKit/icons/Icons";
import {FormControlLabel, RadioGroup, Radio} from '@material-ui/core';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {withStyles} from "@material-ui/core";
import InputNumber from "../../../../uiKit/inputs/InputNumber";
import {isStringEmpty} from "../../../../helpers/isStringEmpty";

const styles = theme => ({
  title: {
    fontFamily: 'Lato',
    fontSize: 16,
    color: theme.palette.text.header
  },
  color: {
    fontFamily: 'Lato',
    fontSize: 14,
    color: theme.palette.text.header
  },
  rootRadio: {
    marginLeft: 13,
    color: theme.palette.primary.iconsGray,
    fontSize: 14,
    fontFamily: 'Lato',
    '&$checked': {
      color: theme.palette.primary.main
    }
  },
  checked: {},
  '@global': {
    '.rootRadio label':
        {
          margin: '-4px 16px -4px -14px'
        },
    '.rootRadio span[class*="label"]':
        {
          marginLeft: 8
        },
    '.rootRadio span':
        {
          fontSize: 14,
          fontFamily: 'Lato'
        },
  }
});

const defaultValues = [-1, 5, 10, 15];

class Delay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: JSON.stringify(this.props.widget.popupDelay),
      time: JSON.stringify(this.props.widget.popupDelay)
    };
  }

  isDefault = (value) => {
    return defaultValues.find((item) => {
      return item === value
    }) !== undefined ? true : false;
  };

  componentDidMount() {
      const {widget} = this.props;
    this.setState({
      value: this.isDefault(widget.popupDelay) ? JSON.stringify(widget.popupDelay) : 'custom',
      time: this.isDefault(widget.popupDelay) ? 0 : JSON.stringify(widget.popupDelay)
    });
  }

  handleChange = (e) => {
    let valueRadio = e.target.value;
    this.setState({
      value: valueRadio
    }, () => {
      if (valueRadio !== 'custom') {
        this.props.handleChange(valueRadio, 'popupDelay');
      } else {
          // If valueRadio is 'custom', then send to API updated this.state.time, which was set in input under this
          // 'custom' radio button
        this.props.handleChange(this.state.time, 'popupDelay');
      }
    });
  }

  render() {
    const {classes} = this.props;
    return (
        <TabTemplate
            tab={{
                name: "Delay",
                icon: <DelayIcon/>,
                description: 'Make your widget pop up automatically'
            }}
            expanded={this.props.expanded}
            onChange={this.props.handleChangeExpansion}
        >
            <p className={classes.title}>Choose when the widget should appear automatically</p>
            <div>
                <FormControlLabel
                    className={`${classes.root} rootRadio`}
                    control={(
                        <RadioGroup
                            className={classes.group}
                            value={this.state.value}
                            checked={this.state.value}
                            onChange={(e) => this.handleChange(e)}
                        >
                            <FormControlLabel
                                value='-1'
                                control={<Radio
                                    classes={{
                                        root: classes.rootRadio,
                                        checked: classes.checked,
                                    }}
                                />}
                                label="Don't appear automatically"
                            />
                            <FormControlLabel
                                value='5'
                                control={<Radio
                                    classes={{
                                        root: classes.rootRadio,
                                        checked: classes.checked,
                                    }}
                                />}
                                label="Appear after 5 sec"
                            />
                            <FormControlLabel
                                value='10'
                                control={<Radio
                                    classes={{
                                        root: classes.rootRadio,
                                        checked: classes.checked,
                                    }}
                                />}
                                label="Appear after 10 sec"
                            />
                            <FormControlLabel
                                value='15'
                                control={<Radio
                                    classes={{
                                        root: classes.rootRadio,
                                        checked: classes.checked,
                                    }}
                                />}
                                label="Appear after 15 sec"
                            />
                            <FormControlLabel
                                value='custom'
                                control={<Radio
                                    classes={{
                                        root: classes.rootRadio,
                                        checked: classes.checked,
                                    }}
                                />}
                                label="Custom"
                            />
                        </RadioGroup>
                    )}
                />
                {this.state.value === 'custom' && <InputNumber
                    onChange={(value) => {
                        this.setState({
                            time: value
                        })
                    }}
                    defaultValue={this.state.time}
                    handleKeyPress={(e) => {
                        if (e.charCode === 13 && !isStringEmpty(e.target.value) && /^[1-9]\d*(\.\d+)?$/.test(e.target.value)) {
                            this.props.handleChange(this.state.time, 'popupDelay');
                        }
                    }}

                    value={this.state.time}
                    onBlur={
                        (e) => {
                            if (!isStringEmpty(e.target.value)) {
                                this.props.handleChange(this.state.time, 'popupDelay');
                            }
                        }
                    }
                />}
            </div>
        </TabTemplate>
    );
  }
}

Delay.propTypes = {
  classes: PropTypes.object
};

const mapStateToProps = state => (
    {
      activeBot: state.activeBot,
      botWidget: state.botWidget
    });

export default withRouter(withStyles(styles, {withTheme: true})(connect(mapStateToProps)(Delay)));
