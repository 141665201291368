import React, {useEffect, useState, useRef} from 'react';
import {withStyles} from '@material-ui/core';
import PropTypes from 'prop-types';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";

import MessageGroup from "../MessageGroup/MessageGroup";

import {getMessages, getMessagesById, getMoreMessages, setMessageRead} from "../../api/user";
import {saveSupportUserMessages, saveSupportUserMoreMessages} from "../../actions/activeUser";
import {getUrlHash} from "../../../../helpers/getUrlHash";
import {updateUserUnreadMessages} from "../../actions/users";

import {isElementTop} from '../../../../helpers/isElementTop'
import {isElementBottom} from '../../../../helpers/isElementBottom'

import {styles} from './styles';
import LoaderSmall from "../../../../uiKit/loaders/loaderSmall";
import {AFTER_MESSAGES, BEFORE_MESSAGES} from "./config";
import {usePrevious} from "../../../../hooks/usePrevious";
import moment from "moment";

const MessagesWrap = (props) => { //NOSONAR
    const {classes, user} = props;
    const [scrollPosition, setScrollPosition] = useState(null);
    const [dates, setDates] = useState([]);
    const [loading, setLoading] = useState(true);
    const [scrollToTop, serScrollToTop] = useState(false);
    const scrollContainer = useRef(null);
    const messagesEnd = useRef(null);
    const messageIntoView = useRef(null);
    const prevUserMessagesLength = usePrevious(user.messages?.length);

    useEffect(() => {
        const hash = getUrlHash();

        if (user.chatId) {
            setLoading(true)
            if (hash) {
                getMessagesById(user.chatId, hash)
                  .then(saveSupportUserMessages)
                  .then(() => setLoading(false))
                  .then(scrollToMessage)
                  .catch(()=> setLoading(false))
            } else {
                getMessages(user.chatId)
                  .then(saveSupportUserMessages)
                  .then(() => setLoading(false))
                  .then(scrollToBottom)
                  .catch(()=> setLoading(false))
            }
        }
    }, [user.chatId]);

    useEffect(() => {
        if (!loading && scrollToTop) {
            scrollContainer.current.scrollTop = scrollContainer.current.scrollHeight - scrollPosition;
        }

        if (user.messages?.length) {
            setDates([...new Set(user.messages.map((item) => item.timestamp.split('T')[0]))]);

            if (user.unreadMessage) {
                const lastMessageId = user?.messages[0].id;
                setMessageRead(user.chatId, lastMessageId)
                  .then(() => updateUserUnreadMessages(user));
            }

            if (user.messages?.length - prevUserMessagesLength === 1) {
                scrollToBottom()
            }
        }
    }, [user.messages]);

    const handleScroll = ({target}) => {

        if (isElementTop(target)) {
            setScrollPosition(scrollContainer.current.scrollHeight);
            serScrollToTop(true);

            const lastMessageId = user?.messages[user?.messages?.length - 1].id;

            getMoreMessages(lastMessageId, user.chatId, BEFORE_MESSAGES)
              .then(messages => {
                  if (messages?.length) saveSupportUserMoreMessages(messages, BEFORE_MESSAGES);
              })
        }

        if (isElementBottom(target)) {
            serScrollToTop(false);

            const firstMessageId = user?.messages[0].id;

            getMoreMessages(firstMessageId, user.chatId, AFTER_MESSAGES)
              .then(messages => {
                  if (messages?.length) saveSupportUserMoreMessages(messages, AFTER_MESSAGES);
              })
        }
    };

    const scrollToBottom = () => {
        if (messagesEnd.current) {
            messagesEnd.current.scrollIntoView();
        }

        const lastMessageId = user?.messages?.length && user?.messages[0].id;
        if (lastMessageId) {
            setMessageRead(user.chatId, lastMessageId)
              .then(() => updateUserUnreadMessages(user))
        }
    };

    const scrollToMessage = () => {
        messageIntoView.current.scrollIntoView();
    };

    const formatDate = (date) => {
        return  `${moment(date).format('MMMM Do').slice(0, -2).toUpperCase()}${moment(date).format('MMMM Do').slice(-2)}`
    };

    return (
      <>
          {loading
            ? <div className={classes.loaderWrap}>
                <LoaderSmall showLoader={true}/>
            </div>
            : <div className={classes.container}
                   ref={scrollContainer}
                   onScroll={handleScroll}>
                {dates
                  .slice()
                  .reverse()
                  .map((date) => (
                    <div key={date}>
                        <p className={classes.date}>
                            {formatDate(date)}
                        </p>
                        <MessageGroup date={date}
                                      messages={user.messages}
                                      messageIntoView={messageIntoView}/>
                    </div>
                  ))
                }
                <div className={classes.bottomScrollElem} ref={messagesEnd}/>
            </div>}
      </>
    );
};

MessagesWrap.propTypes = {
    classes: PropTypes.object,
    user: PropTypes.object
};

const mapStateToProps = (state) => ({
    user: state.activeUser
});

export default withRouter(withStyles(styles)(connect(mapStateToProps)(MessagesWrap)));
