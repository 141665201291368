import {store} from '../../../index';

export const GET_TWILIO_CREDENTIALS = 'GET_TWILIO_CREDENTIALS';

export const setTwilioCredentials = (twilioCredentials) => {
	store.dispatch(
		{
			type: GET_TWILIO_CREDENTIALS,
			twilioCredentials
		}
	);
};
