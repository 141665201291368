import React from 'react';
import {withStyles} from '@material-ui/core';
import PropTypes from 'prop-types';

import {styles} from './styles';
import TextMessage from "../messages/TextMessage";
import Template from "../messages/Template";
import Attachment from "../messages/Attachment";
import RandomTextMessage from "../messages/RandomTextMessage";
import RandomAttachment from "../messages/RandomAttachment";
import Typing from "../messages/Typing";
import UserInput from "../messages/UserInput";

const MessagesWrap = (props) => {
    const {message, rtl, index, updateMessage, deleteMessage} = props;

    return (
        <>
            {message.texts &&
            <TextMessage message={message}
                         updateMessage={(message) => updateMessage(message, index)}
                         onDelete={() => deleteMessage(message)}
                         rtl={rtl}
            />}
            {message.attachment != null && (message.attachment.type === "template" ?
                <Template message={message}
                          rtl={rtl}
                          updateMessage={(message) => updateMessage(message, index)}
                          onDelete={() => deleteMessage(message)}/>
                :
                <Attachment message={message}
                            rtl={rtl}
                            updateMessage={(message) => updateMessage(message, index)}
                            onDelete={() => deleteMessage(message)}/>
            )}
            {message.random != null && message.type === "text" &&
            <RandomTextMessage message={message}
                               rtl={rtl}
                               updateMessage={(message) => updateMessage(message, index)}
                               onDelete={() => deleteMessage(message)}
            />}
            {message.random != null && message.type === "attachment" &&
            <RandomAttachment message={message}
                              rtl={rtl}
                              updateMessage={(message) => updateMessage(message, index)}
                              onDelete={() => deleteMessage(message)}/>}
            {message?.senderAction === "typing_on" &&
            <Typing message={message}
                    updateMessage={(message) => updateMessage(message, index)}
                    onDelete={() => deleteMessage(message)}/>}
            {message.type === 'user_input' &&
            <UserInput message={message}
                       rtl={rtl}
                       updateMessage={(message) => updateMessage(message, index)}
                       onDelete={() => deleteMessage(message)}/>}
        </>
    );
};
MessagesWrap.propTypes = {
    classes: PropTypes.object
};

export default withStyles(styles)(MessagesWrap);
