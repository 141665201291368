import {store} from "../../../index";

export const SAVE_ADMIN_USER = ' SAVE_ADMIN_USER';

export const saveAdminUser = (adminUser) => {
  store.dispatch(
    {
      type: SAVE_ADMIN_USER,
      adminUser
    }
  );
};
