import { ADD_INTENT, CLEAR_INTENTS, DELETE_INTENT, SAVE_INTENTS, UPDATE_INTENT } from "../actions/intents";

const intents = (state = [], action) => {
    let intents;
    switch (action.type) {

        case SAVE_INTENTS:

            return action.intents;
        case ADD_INTENT:
            intents = [...state, action.intent].sort((a, b) => a.name.toLowerCase() >= b.name.toLowerCase());
            return intents;
        case UPDATE_INTENT:
            intents = [...state];
            intents.forEach((element, index) => {
                if (element.id === action.intent.id) {
                    intents[index] = action.intent;
                }
            });
            return intents.sort((a, b) => a.name.toLowerCase() >= b.name.toLowerCase());
        case DELETE_INTENT:
            intents = [...state];
            return intents.filter(x => {
                return x.id !== action.intentId;
            });
        case CLEAR_INTENTS:
            return null;
        default:
            return state;
    }

};

export default intents
