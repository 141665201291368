import {
    SAVE_SUPPORT_REQUESTS
} from '../actions/supportRequest';

const supportRequests = (state = null, action) => {
    if (action.type === SAVE_SUPPORT_REQUESTS) {
        return action.requests;
    } else {
        return state;
    }
};

export default supportRequests;
