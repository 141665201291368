/**
 * Created by Admin on 13.02.2018.
 */
import React from "react";
import {connect} from "react-redux";
import States from "../States";
import {IconButton, withStyles} from "@material-ui/core";
import {TrashIcon} from "../../../../uiKit/icons/Icons";

const styles = theme => ({
      container: {
          background: '#ffffff',
          border: '1px solid #EBEBEF',
          borderRadius: 10,
          width: 254
      },
      inputHolder: {
          border: "1px solid #C7CAD6",
          width: "max-content",
          borderRadius: "10px",
          display: "flex",
          justifyContent: 'space-between',
          backgroundColor: "white",
          marginRight: 10,
          padding: 10,
          maxHeight: 45,
          minWidth: "100%",
          marginBottom: 16
      },
      input: {
          padding: "10px",
          border: "none",
          outline: "none",
          width: '100%',
          borderRadius: "5px",
          fontSize: "14px",
          lineHeight: "19px",
          backgroundColor: "white",
      },
      name: {
          padding: '17px 24px',
          borderBottom: '1px solid #EBEBEF',
          fontSize: 14,
          color: '#3A3F62',
          fontWeight: 'bold'
      },
      options: {
          padding: 16
      },
      text: {
          fontSize: 14,
          color: '#616581',
          margin: '0 0 8px'
      },
      "@global": {
          ".message1:hover .delete-template": {
              display: 'block'
          },
          ".message1": {
              position: 'relative'
          },
          ".message1 .delete-template": {
              left: '233px',
              top: '-20px',
              position: 'absolute',
              transition: 'all 0.5s ease'
          },
          ".trashIcon:hover svg path": {
              stroke: '#1658F3'
          },
          ".add-button": {
              background: '#5A98F7',
              color: 'white'
          },
          ".add-button:hover": {
              background: 'white',
              color: '#5A98F7'
          }
      },
  })
;

class UserInput extends React.Component {
    state = {
        atom: {
            id: null,
            name: null,
        },
        parameter: null,
    };

    static getDerivedStateFromProps(props, state) {
        function getAtomNameByAtomId(atomId, flows) {
            let selectedAtomName;
            flows.forEach(flow => {
                if (flow.atoms) {
                    flow.atoms.forEach(atom => {
                        if (atom.id === atomId) {
                            selectedAtomName = atom.name;
                        }
                    })
                }
            })
            return selectedAtomName;
        }

        return {
            atom: {
                id: props.message.next_atom,
                name: getAtomNameByAtomId(props.message.next_atom, props.flows),
            },
            parameter: props.message.parameter,
        };
    }

    onChange(atomName, atomId) {
        let message = this.props.message;
        message.next_atom = atomId;
        this.setState({
            atom: {
                id: atomId,
                name: atomName,
            },
        }, () => {
            this.props.updateMessage(message)
        })
    }

    handleName(event) {
        let message = this.props.message;
        message.parameter = event.target.value;
        this.setState({
            parameter: event.target.value
        }, () => {
            this.props.updateMessage(message)
        })
    }


    render() {
        const {classes,rtl} = this.props;
        return (
          <div className={`${classes.container} message1`}>
              {this.props.onDelete && !this.props.message.isHardCoded && <div className="delete-template">
                  <IconButton className='trashIcon' style={{
                      background: 'white', border: '1px solid #EBEBEF',
                      boxShadow: '0px 3px 6px #EBEBEF', width: 40, height: 40
                  }} aria-label="Delete" onClick={() => this.props.onDelete()}>
                      <TrashIcon width='20px' height='20px' color='#B0B2C0'/>
                  </IconButton>
              </div>}
              <div className={classes.name}>
                  User input
              </div>
              <div className={classes.options}>
                  <p className={classes.text}>Add an input name</p>
                  <div className={classes.inputHolder}>
                      <input className={classes.input}
                             placeholder="Enter input name"
                             maxLength={90}
                             style={{unicodeBidi: rtl && 'bidi-override'}}
                             value={this.state.parameter}
                             dir={rtl && 'rtl'}
                             onChange={(event) => this.handleName(event)}
                      />
                  </div>
                  <p className={classes.text}>Choose atom on successful input</p>
                  <States payload={(name, id) => this.onChange(name, id)}
                          value={this.state.atom.name}/>
              </div>

          </div>
        )
    }
}

const mapStateToProps = (state) => (
  {
      params: state.params,
      flows: state.flows,
  });

export default withStyles(styles)(connect(mapStateToProps)(UserInput));
