import React from 'react';
import {withStyles} from '@material-ui/core';
import PropTypes from 'prop-types';

import MessageWrap from "../MessageWrap/MessageWrap";

import {styles} from './styles';

const ImageMessage = (props) => {
    const {classes, timezone, isReceived, imgUrl} = props;

    return (
        <MessageWrap timezone={timezone} isReceived={isReceived}>
            <img style={{backgroundImage: '/images/platform/loader.svg'}}
                 className={classes.img}
                 src={imgUrl}
                 alt="icon"/>
        </MessageWrap>
    );
};

ImageMessage.propTypes = {
    classes: PropTypes.object,
    timezone: PropTypes.string,
    isReceived: PropTypes.bool,
    imgUrl: PropTypes.string
};

export default withStyles(styles)(ImageMessage);
