import Intents from "../tabs/nlp/components/Intents";
import React from "react";
import Triggers from "../tabs/nlp/components/Triggers";
import Training from "../tabs/nlp/components/training/Training-container";


export const nlpRoutes = [
    {
        path: "training",
        name: "Training",
        component: Training,
    },
    {
        path: "faq",
        name: "FAQ",
        component: () => <Intents isFaq={true} tableTitle="FAQ" createButton="Create FAQ"/>,
    },
    {
        path: "intents",
        name: "Intents",
        component: () => <Intents isFaq={false} tableTitle="Intents" createButton="Create Intent"/>,
    },
    {
        path: "triggers",
        name: "Triggers",
        component: Triggers,
    },
];
