import {store} from '../../../index';

export const SET_PARAMS = 'SET_PARAMS';

export const setParams = (params) => {
	store.dispatch(
		{
			type: SET_PARAMS,
			params
		}
	);
};
