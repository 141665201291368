import React from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import {FormControl, FormHelperText, Input, InputLabel} from "@material-ui/core";
import {PlusIcon} from '../../../uiKit/icons/Icons.js'
import {createNewFlow} from "../api/flow";
import Modal from "../../../uiKit/Modal";
import {isStringEmpty} from "../../../helpers/isStringEmpty";

const styles = theme => ({
    addFlow: {
        background: '#FFFFFF',
        boxShadow: '4px 4px 29px rgba(19, 69, 186, 0.0855129)',
        borderRadius: 10,
        padding: 15,
        justifyContent: 'center'
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing.unit,
    },
    buttonHolder: {
        display: "flex",
        justifyContent: "center",
        marginTop: 30
    },
    newFlowButton: {
        fontWeight: 700,
        fontSize: 14,
        color: '#3A3F62',
        margin: '0 0 0 16px',
        fontFamily: 'Lato'
    }
});

class NewFlow extends React.Component {
    state = {
        open: false,
        usedNames: [],
        isNameOk: true,
        name: '',
    };

    handleOpen = () => {
        this.setState({open: true});
    };

    handleClose = () => {
        this.setState({open: false});
    };

    checkEnterPress = (event) => {
        if (event.key === 'Enter' && !isStringEmpty(this.state.name) && !this.state.usedNames.includes(this.state.name)) {
            this.saveNewFLow();
        }
    };

    handleChangeName(text) {
        this.setState({name: text});
        if (this.state.usedNames.includes(text)) {
            this.setState({isNameOk: false})
        } else {
            this.setState({isNameOk: true})
        }
    }

    saveNewFLow() {
        if (!isStringEmpty(this.state.name) && !this.state.usedNames.includes(this.state.name)) {
            let newFLow = {
                name: this.state.name
            };
            createNewFlow(this.props.botId, newFLow);
            this.setState({open: false, name: ''});
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.flows) {
            let names = [];
            nextProps.flows.forEach(flow => {
                names.push(flow.name);
            });
            this.setState({usedNames: names});
        }
    }

    render() {
        const {classes} = this.props;
        return (
            <div>
                <div className={classes.addFlow} onClick={this.handleOpen} style={{
                    cursor: "pointer", display: 'flex', alignItems: 'center',
                }}>
                    <Button variant="fab"
                            style={{
                                background: "linear-gradient(312.17deg, #5A98F7 3.33%, #1658F3 96.71%)",
                                height: 40,
                                width: 40,
                                boxShadow: '0px 3px 9px rgba(19, 69, 186, 0.206267)'
                            }}
                            aria-label="add">
                        <PlusIcon width='13' height="13" color='white'/>
                    </Button>
                    <p className={classes.newFlowButton}>
                        Add new flow
                    </p>
                </div>
                <Modal
                    open={this.state.open}
                    onClose={this.handleClose}
                    title="Create a new flow"
                >
                    <FormControl className={classes.formControl} error={!this.state.isNameOk}
                                 aria-describedby="name-error-text" style={{width: "100%"}}>
                        <InputLabel htmlFor="name-error">Flow name</InputLabel>
                        <Input id="name-error"
                               value={this.state.name}
                               onChange={(event) => this.handleChangeName(event.target.value)}
                               onKeyUp={this.checkEnterPress}
                        />
                        {!this.state.isNameOk &&
                        <FormHelperText id="name-error-text">This flow name is already used!</FormHelperText>}
                    </FormControl>
                    <div className={classes.buttonHolder}>
                        <Button disabled={!this.state.isNameOk} color="primary" className={classes.button}
                                variant="raised"
                                size="large"
                                style={{float: "right"}}
                                onClick={() => this.saveNewFLow()}>
                            CREATE
                        </Button>
                    </div>
                </Modal>
            </div>
        );
    }
}

NewFlow.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(NewFlow);
