export const days = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];

export const dayModel = [
  {
    "time": "12AM",
    "amount": 0
  },
  {
    "time": "1AM",
    "amount": 0
  },
  {
    "time": "2AM",
    "amount": 0
  },
  {
    "time": "3AM",
    "amount": 0
  },
  {
    "time": "4AM",
    "amount": 0
  },
  {
    "time": "5AM",
    "amount": 0
  },
  {
    "time": "6AM",
    "amount": 0
  },
  {
    "time": "7AM",
    "amount": 0
  },
  {
    "time": "8AM",
    "amount": 0
  },
  {
    "time": "9AM",
    "amount": 0
  },
  {
    "time": "10AM",
    "amount": 0
  },
  {
    "time": "11AM",
    "amount": 0
  },
  {
    "time": "12PM",
    "amount": 0
  },
  {
    "time": "1PM",
    "amount": 0
  },
  {
    "time": "2PM",
    "amount": 0
  },
  {
    "time": "3PM",
    "amount": 0
  },
  {
    "time": "4PM",
    "amount": 0
  },
  {
    "time": "5PM",
    "amount": 0
  },
  {
    "time": "6PM",
    "amount": 0
  },
  {
    "time": "7PM",
    "amount": 0
  },
  {
    "time": "8PM",
    "amount": 0
  },
  {
    "time": "9PM",
    "amount": 0
  },
  {
    "time": "10PM",
    "amount": 0
  },
  {
    "time": "11PM",
    "amount": 0
  }
];