export const styles = () => ({
    container: {
        position: 'relative'
    },
    input: {
        outline: 'none',
        padding: '8px 40px 8px 12px',
        fontSize: 14,
        width: '100%',
        border: '1px solid rgba(53, 64, 82, 0.1)',
        borderRadius: 4,
        color: '#616581',
        '&::placeholder': {
            color: 'rgba(53, 64, 82, 0.5)'
        }
    },
    iconWrap: {
        position: 'absolute',
        top: 5,
        right: 12
    }
});
