import React, {Component} from "react";
import {connect} from "react-redux";
import {showSnackBar} from "../../tabs/home/actions/snackBar";
import {Snackbar, withStyles} from "@material-ui/core";

const styles = (theme) => ({
    bodyProps: {
        width: '100%',
        height: 35
    },
    snackBar: {
        padding: 0,
        display: 'flex',
        justifyContent: 'center',
        boxShadow: 'none'
    },
    message: {
        fontSize: 12,
        fontFamily: 'Lato',
    },
    success: {
        background: theme.palette.alerts.success,
    },
    error: {
        background: theme.palette.alerts.error,
    }
});

class Notification extends Component {
    handleClose = (event, reason) => {
        if(reason==='timeout' || reason==='clickaway' && !this.props.snackBar.duration) {
            showSnackBar({
                open: false,
            })
        }
    };

    render() {
        const {snackBar, classes} = this.props;
        return (
            <div>
                {snackBar.open && <Snackbar
                    className={`${classes.bodyProps} ${snackBar.success ? classes.success : classes.error}`}
                    anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                    open={true}
                    autoHideDuration={snackBar.duration}
                    onClose={this.handleClose}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                        className: `${classes.snackBar} ${snackBar.success ? classes.success : classes.error}`,
                    }}
                    message={<span id="message-id" className={classes.message}>{snackBar.text}</span>}
                />}
            </div>
        )
    }
}

const mapStateToProps = (state) => (
    {
        snackBar: state.snackBar,
    });

export default withStyles(styles)(connect(mapStateToProps)(Notification));
