/**
 * Created by serhiy on 05.03.18.
 */
import React from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import {withRouter} from "react-router-dom";
import img from "./discuss.png";
import SubmitButton from "../../../uiKit/buttons/SubmitButton";


const styles = theme => ({
    wrap: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    text: {
        maxWidth: 330,
        lineHeight: '22px',
        fontSize: 14,
        textAlign: 'center',
        color: theme.palette.primary.text,
        marginBottom: 24
    },
    img: {
        display: 'block',
        marginBottom: 24
    }
});

class NoData extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    };

    render() {
        const {classes, description, handleSubmit, buttonTitle} = this.props;
        return (
            <div className={classes.wrap}>
                <img className={classes.img} src={img} alt="img"/>
                <p className={classes.text}>
                    {description}
                </p>
                {buttonTitle &&
                <SubmitButton
                    size="lg"
                    title={buttonTitle}
                    onClick={this.props.onClick}
                />
                }
            </div>
        );
    }
}

NoData.propTypes = {
    classes: PropTypes.object.isRequired,
    description: PropTypes.string.isRequired
};

const mapStateToProps = (state) => (
    {
        intents: state.intents
    });

export default withRouter(withStyles(styles)(connect(mapStateToProps)(NoData)));
