import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {withStyles} from "@material-ui/core";
import SubmitButton from "../../../../../uiKit/buttons/SubmitButton";
import {getFacebookPages, subscribePage, unsubscribePage} from "../../../api/facebook";
import {alertSuccess} from "../../../../../api";
import {setActiveBot} from "../../../../home/actions/activeBot";
import LoaderSmall from "../../../../../uiKit/loaders/loaderSmall";
//TODO: pagination
// import Pagination from "../../nlp/Pagination";

const styles = theme => ({
  title: {
    fontFamily: 'Lato',
    fontSize: 14,
    color: theme.palette.text.header
  },
  color: {
    fontFamily: 'Lato',
    fontSize: 14,
    color: theme.palette.text.header
  },
  rootRadio: {
    marginLeft: 13,
    color: theme.palette.primary.iconsGray,
    fontSize: 14,
    fontFamily: 'Lato',
    '&$checked': {
      color: theme.palette.primary.main
    }
  },
  pageItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '1px solid #EBEBEB',
    padding: '16px 0',
    // '&:first-of-type': {
    //   paddingTop: 3               //3px coz it should be 24px according to the design, tab-top itself has 21
    // },
    '&:last-of-type': {
      // borderBottom: 'none',
      // paddingBottom: 21,           //uncomment this after adding pagination to the pages
      // paddingBottom: 0
    }
  },
  pageInfo: {
    display: 'flex',
    alignItems: 'center'
  },
  pageImg: {
    width: '40px',
    height: '40px',
    marginRight: '16px',
    borderRadius: 100
  },
  pageName: {
    color: '#3A3F62',
    fontSize: '14px',
    lineHeight: '17px',
    fontWeight: '500',
    fontFamily: 'Lato, "sans-serif"'
  },
  connectedStatus: {
    fontSize: '14px',
    fontFamily: 'Lato, "sans-serif"',
    fontWeight: '500',
    color: '#43C692',
    marginRight: '24px'
  },
  connectedAnother: {
    fontSize: '14px',
    fontFamily: 'Lato, "sans-serif"',
    fontWeight: '500',
    color: '#3A3F62',
    marginRight: '24px'
  },
  checked: {},
  '@global': {
    '.rootRadio label':
        {
          margin: '-4px 16px -4px -14px'
        },
    '.rootRadio span[class*="label"]':
        {
          marginLeft: 8
        },
    '.rootRadio span':
        {
          fontSize: 14,
          fontFamily: 'Lato'
        },
  }
});


class FacebookPage extends React.Component {
  updateFacebookData = (subscribe) => {
    let newBot = JSON.parse(JSON.stringify(this.props.activeBot));
    if (!subscribe) {
      newBot.messengerConfigs.pageId = this.props.page.id;
      newBot.messengerConfigs.accessToken = this.props.page.access_token;
      setActiveBot(newBot);
    }
   if (subscribe && this.props.activeBot.messengerConfigs.pageId == this.props.page.id) {
      newBot.messengerConfigs.pageId = null;
      newBot.messengerConfigs.accessToken = null;
      setActiveBot(newBot);
    }
    getFacebookPages(this.props.activeBot.id, this.props.token, () => {
      this.setState({
        showLoader: false
      }, () => {
        alertSuccess(`Page ${subscribe ? 'unsubscribed' : 'subscribed'} successfully`)
      })
    });
  };

  handleRequest = (subscribe, botId, pageId, pageToken, pageName) => {
    this.setState({
      showLoader: true
    }, () => {
      subscribe ? subscribePage(botId, pageId, pageToken, pageName, this.updateFacebookData ,this.handleLoader)
          : unsubscribePage(botId, pageId, pageToken, this.updateFacebookData, this.handleLoader)
    })
  };

  handleLoader=()=>{
    this.setState({
      showLoader:false
    })
  }

  constructor(props) {
    super(props);

    this.state = {
      showLoader: false
    }
  }

  render() {
    const {
      classes,
      page,
      activeBot,
      disabled
    } = this.props;
    const {showLoader} = this.state;
    return (
        <div className={classes.pageItem}>
          <div className={classes.pageInfo}>
            {page.picture &&
            <img className={classes.pageImg} src={page.picture.data.url}/>}
            <span className={classes.pageName}>{page.name}</span>
          </div>
          {showLoader ?
              <LoaderSmall
                  showLoader={true}
              /> :
              <div>
                {page.is_webhooks_subscribed ?
                    (<div>
                      {page.id == activeBot.messengerConfigs.pageId ?
                          <span className={classes.connectedStatus}>&#10003; Connected!</span> :
                          <span className={classes.connectedAnother}>Connected to another bot</span>}
                      <SubmitButton
                          title="Disconnect"
                          type="noStyle"
                          onClick={() => this.handleRequest(false, activeBot.id, page.id, page.access_token)}
                      />
                    </div>)
                    : (<div>{!disabled && <SubmitButton
                        title="Connect to page"
                        type="default"
                        onClick={(e) => {
                          e.stopPropagation();
                          this.handleRequest(true, activeBot.id, page.id, page.access_token, page.name)
                        }}
                    />}</div>)
                }
              </div>
          }

        </div>
    )
  }
};


FacebookPage.propTypes = {
  classes: PropTypes.object,
  settingsPages: PropTypes.object
};

const mapStateToProps = state => (
    {
      settingsPages: state.settingsPages,
      activeBot: state.activeBot,
      token: state.token
    });

export default withRouter(withStyles(styles, {withTheme: true})(connect(mapStateToProps)(FacebookPage)));
