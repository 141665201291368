import React from 'react';
import {withStyles} from '@material-ui/core';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import moment from "moment";

import TextMessage from "../TextMessage/TextMessage";
import ImageMessage from "../ImageMessage/ImageMessage";
import AudioMessage from "../AudioMessage/AudioMessage";
import VideoMessage from "../VideoMessage/VideoMessage";
import TemplateMessage from "../CarouselMessage/CarouselMessage";

import {styles} from './styles';

const Message = (props) => {
    const {msg, timezone} = props;

    const message = msg?.message ? JSON.parse(msg.message) : null;
    const postBack = msg?.postback ? JSON.parse(msg.postback) : null;
    const timestamp = moment(msg.timestamp).add(timezone, 'hours').format('hh:mm A');

    const isTextMessage = message?.text;
    const isImageMessage = message?.attachments?.length && message.attachments[0].type === "image";
    const isAudioMessage = message?.attachments?.length && message.attachments[0].type === "audio";
    const isVideoMessage = message?.attachments?.length && message.attachments[0].type === "video";
    const isTemplateMessage = message?.attachments?.length && message.attachments[0].type === "template";

    const isRtl = message?.isRtl || msg?.isRtl;

    return (
        <>
            {isTextMessage && <TextMessage text={message.text}
                                           timezone={timestamp}
                                           isRtl={isRtl}
                                           isReceived={!message.isEcho}/>}

            {isImageMessage && <ImageMessage timezone={timestamp}
                                             isReceived={!message.isEcho}
                                             imgUrl={message.attachments[0].payload.url}/>}

            {isAudioMessage && <AudioMessage timezone={timestamp}
                                             isReceived={!message.isEcho}
                                             url={message.attachments[0].payload.url}/>}

            {isVideoMessage && <VideoMessage timezone={timestamp}
                                             isReceived={!message.isEcho}
                                             url={message.attachments[0].payload.url}/>}

            {isTemplateMessage && <TemplateMessage timezone={timestamp}
                                                   isRtl={isRtl}
                                                   isReceived={!message.isEcho}
                                                   payload={message.attachments[0].payload}/>}

            {postBack && <TextMessage text={postBack.title || postBack.payload}
                                      timezone={timestamp}
                                      isRtl={isRtl}
                                      isReceived={!message?.isEcho}/>}
        </>
    );
};

Message.propTypes = {
    classes: PropTypes.object,
    timezone: PropTypes.number
};

const mapStateToProps = (state) => ({
    timezone: state.activeBot.timezone
});

export default withStyles(styles)(connect(mapStateToProps)(Message));
