import {withStyles} from "@material-ui/core";
import {connect} from "react-redux";

import {styles} from "./styles";
import {HomeHeader as View} from "./HomeHeader";

const mapStateToProps = state => ({
    accountSettings: state.account
})

export const HomeHeader = withStyles(styles)(connect(mapStateToProps)(View));
