import {alertError, alertSuccess, createJsonHeaders, logoutOnRequestOrResponseJson} from "../../../api";
import {BASE_URL} from "../../../configs";
import {setTwilioCredentials} from "../actions/twilioCredentials";

export function getTwilioCredentials(botId) {
    return fetch(`${BASE_URL}/bot/${botId}/twilio/integration`, {
        credentials: 'same-origin',
        headers: createJsonHeaders(),
        method: "GET",
    })
        .then(response => logoutOnRequestOrResponseJson(response))
        .then(credentials => {
            setTwilioCredentials(credentials);
            return credentials;
        })
        .catch(() => alertError("Fail. Try it later 45"));
}


export function submitTwilioCredentials(botId, credentials) {
    return fetch(`${BASE_URL}/bot/${botId}/twilio/integration`, {
        credentials: 'same-origin',
        headers: createJsonHeaders(),
        method: "POST",
        body: JSON.stringify(credentials)
    }).then(response => {
            if (response.status === 400) {
              response.text().then(text => {
                let res = JSON.parse(text);
                alertError(res.message);
              });
              return;
            } else if (response.status === 204) {
              alertSuccess("Twilio is updated successfully.");
              console.log(response)

            } else {
              alertError(response.message);
            }
          });
}
