export const styles = (theme) => ({
    header: {
        height: 80,
        width: "100%",
        background: theme.palette.primary.contrastText,
        zIndex: 2,
        position: "fixed",
        top: 0,
        left: 0,
        boxShadow: "4px 4px 29px rgba(19, 69, 186, 0.0855129)",
        fontFamily: "'Lato', sans-serif",
    },
    headerInner: {
        position: "relative",
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        padding: "0 20px",
        justifyContent: "space-between",
    },
    title: {
        color: theme.palette.primary.headerText,
        fontSize: "16px",
        fontWeight: "bold",
        lineHeight: "120%",
        margin: 0,
    },
    titleCenter: {
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
        color: theme.palette.primary.headerText,
        fontSize: "16px",
        fontWeight: "bold",
        lineHeight: "120%",
    },
    button: {
        height: "100%",
        padding: "0 10px",
        fontSize: 16,
        color: "#1658F3",
        fontWeight: "bold",
        textTransform: "none",
        fontFamily: "'Lato', sans-serif",
        textDecoration: "none",

        "& svg": {
            marginRight: 4,
        },
    },
});
