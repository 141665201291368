import {store} from '../../../index';

export const SAVE_CONTEXTS_SIZE = 'SAVE_CONTEXTS_SIZE';
export const ADD_CONTEXTS_SIZE = 'ADD_CONTEXTS_SIZE';
export const UPDATE_CONTEXTS_SIZE = 'UPDATE_CONTEXTS_SIZE';

export const saveContextsSize = (contextSize) => {
	store.dispatch(
		{
			type: SAVE_CONTEXTS_SIZE,
			contextSize
		}
	);
};

export const addContextsSize = (contextSize) => {
	store.dispatch(
		{
			type: ADD_CONTEXTS_SIZE,
			contextSize
		}
	);
};

export const deleteOldContexstSize = (contextSize) => {
	store.dispatch(
		{
			type: UPDATE_CONTEXTS_SIZE,
			contextSize
		}
	);
};
