import React from "react";
import {IconButton, Popover, withStyles} from "@material-ui/core";
import States from "../States";
import PropTypes from "prop-types";
import {isStringEmpty} from "../../../../helpers/isStringEmpty";

const styles = theme => ({
    typography: {
        margin: theme.spacing.unit * 2,
    },
    popover: {
        // height: 152,
        // width: 300,
    },
    reply: {
        position: "relative",
    },
    showDelete: {
        position: "absolute",
        right: "-15px",
        top: "-10px",
        zIndex: 1,
    },
    hideDelete: {
        display: "none",
    },
    payload: {
        margin: 0,
        fontSize: 10,
        position: "absolute",
        top: 24,
        width: "100%",
        textAlign: "center",
        color: "grey",
        padding: '0 12px',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis'

    },
    title: {
        borderRadius: 35,
        color: "#2668E8",
        outline: "none",
        textAlign: "center",
        margin: 0,
        border: "1px solid #2668E8",
        backgroundColor: "#ffffff",
        padding: "10px 24px",
        fontSize: 14,
        lineHeight: '16px',
        resize: "none",
        height: 36,
    },
    titles: {
        color: 'rgb(38, 104, 232)',
        fontSize: 14,
        maxHeigth: '100%',
        lineHeight: '0'
    }
});

class QuickReply extends React.Component {
    state = {
        open: false,
        anchorOriginVertical: 'top',
        anchorOriginHorizontal: 'center',
        transformOriginVertical: 'top',
        transformOriginHorizontal: 'center',
        positionTop: 200,
        positionLeft: 400,
        anchorReference: 'anchorEl',
        showDelete: false,
    };

    handleChange(value, fieldName) {
        let reply = {...this.props.reply};
        reply[fieldName] = value;
        this.props.onChange(reply);
    }

    handleChangeParam(value, paramIndex, fieldName) {
        let reply = {...this.props.reply};
        reply.params[paramIndex][fieldName] = value;
        this.props.onChange(reply);
    }

    deleteParam(paramIndex) {
        let reply = {...this.props.reply};
        reply.params.splice(paramIndex, 1);
        this.props.onChange(reply);
    }

    handleClickButton = () => {
        this.setState({
            open: true,
        });
    };

    addParam() {
        let reply = {...this.props.reply};
        if (!reply.params) {
            reply.params = [];
        }
        reply.params.push({
            key: '',
            value: '',
        });
        this.props.onChange(reply);
    }

    handleClose = () => {
        this.setState({
            open: false,
        });
    };

    anchorEl = null;

    showDelete(show) {
        this.setState({showDelete: show});
    }

    render() {
        const {
            open,
            anchorOriginVertical,
            anchorOriginHorizontal,
            transformOriginVertical,
            transformOriginHorizontal,
            positionTop,
            positionLeft,
            anchorReference,
        } = this.state;
        const {classes, reply, rtl} = this.props;
        const isRtl = rtl ? 'rtl' : 'ltr';

        return (
            <div className={classes.reply}
                 onMouseEnter={() => this.showDelete(true)}
                 onMouseLeave={() => this.showDelete(false)}
            >
                <input type="text"
                       className={classes.title}
                       placeholder="Reply title"
                       value={reply.content_type !== 'text' ? reply.content_type : reply.title}
                       style={reply.content_type === 'text' && isStringEmpty(reply.payload) ? {
                           border: "1px solid #f44336",
                           color: "#f44336",
                       } : {
                           border: "1px solid #2668E8",
                           color: "#2668E8",
                       }}
                       maxLength={30}
                       ref={node => {
                           this.anchorEl = node;
                       }}
                       onClick={() => this.handleClickButton()}
                       readonly={reply.content_type !== 'text' ? 'readonly' : false}
                       dir={isRtl}
                />
                <p className={classes.payload}
                   onClick={() => this.handleClickButton()}>
                    {this.props.reply.payload}
                </p>
               <IconButton aria-label="Delete"
                                             className={(this.state.showDelete && !open) ? classes.showDelete : classes.hideDelete}
                                             onClick={() => this.props.onDelete()}>
                    <img style={{height: 30}} src="/images/platform/delete.svg" alt="delete"/>
                </IconButton>
                <Popover
                    open={open}
                    anchorEl={this.anchorEl}
                    anchorReference={anchorReference}
                    anchorPosition={{top: positionTop, left: positionLeft}}
                    onClose={this.handleClose}
                    anchorOrigin={{
                        vertical: anchorOriginVertical,
                        horizontal: anchorOriginHorizontal,
                    }}
                    transformOrigin={{
                        vertical: transformOriginVertical,
                        horizontal: transformOriginHorizontal,
                    }}
                    PaperProps={{
                        style: {
                            overflow: "visible"
                        },
                    }}
                >
                    <div className={classes.popover}>
                        <input type="text"
                               autoFocus
                               className={classes.title}
                               placeholder="Reply title"
                               style={reply.content_type === 'text' && isStringEmpty(reply.payload) ? {
                                   border: "1px solid #f44336",
                                   color: "#f44336"
                               } : {
                                   border: "1px solid #2668E8",
                                   color: "#2668E8"
                               }}
                               value={reply.content_type !== 'text' ? reply.content_type : reply.title}
                               onChange={(event) => this.handleChange(event.target.value, "title")}
                               maxLength={30}
                               readonly={reply.content_type !== 'text' ? 'readonly' : false}
                               dir={isRtl}
                        />
                        <IconButton aria-label="Delete"
                                    className={this.state.showDelete ? classes.showDelete : classes.hideDelete}
                                    onClick={() => this.props.onDelete()}>
                            <img style={{height: 30}} src="/images/platform/delete.svg" alt="delete"/>
                        </IconButton>
                        <div style={{padding: "10px"}}>
                            <States payload={(value) => this.handleChange(value, "payload")}
                                    value={this.props.reply.payload}/>
                        </div>
                        <div style={{padding: 10}}>
                            <div>
                                {this.props.reply.params && this.props.reply.params.map((param, index) =>
                                    <div key={this.props.reply.id + index} style={{display: "flex"}}>
                                        <input placeholder="key"
                                               value={param.key}
                                               style={{width: 90, padding: 3}}
                                               onChange={(event) => this.handleChangeParam(event.target.value, index, "key")}/>
                                        <p style={{margin: 2}}>=</p>
                                        <input placeholder="value"
                                               value={param.value}
                                               style={{width: 90, padding: 3}}
                                               onChange={(event) => this.handleChangeParam(event.target.value, index, "value")}/>
                                        <p onClick={() => this.deleteParam(index)}
                                           style={{
                                               margin: 2,
                                               cursor: "pointer"
                                           }}>
                                            x
                                        </p>
                                    </div>
                                )}
                            </div>
                            {reply.content_type === 'text' && (
                                    <div onClick={() => this.addParam()}
                                         style={{
                                             marginTop: 10,
                                             textAlign: "center",
                                             color: "black",
                                             cursor: "pointer",
                                             backgroundColor: "white",
                                             height: 40,
                                             border: '0.5px solid rgb(51, 51, 51)',
                                             padding: 10
                                         }}>
                                        ADD PARAMETERS
                                    </div>
                                )}
                        </div>
                    </div>
                </Popover>
            </div>
        )
    }
}

QuickReply.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(QuickReply);
