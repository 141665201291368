import React, {Component} from "react";
import {Route} from "react-router-dom";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core";

import DialogsContainer from "./components/DialogsContainer/DialogsContainer";
import ChatContainer from "./components/ChatContainer/ChatContainer";
import NoChatScreen from "./components/NoChatScreen/NoChatScreen";

import {HOME_PATH} from "../../configs";
import {isUrlEndsWithSupport} from "../../helpers/isUrlEndsWithSupport";
import {getFlows} from "../flowBuilder/api/flow";
import {updateSupportUser} from './api/users'
import {styles} from './styles';
import {saveActiveUser} from "./actions/activeUser";

class SupportContainer extends Component {
    componentDidMount() {
        getFlows(this.props.match.params.botId);
        this.tryToSaveActiveUser();
    }

    tryToSaveActiveUser = () => {
        if (!isUrlEndsWithSupport()) {
            const vars = window.location.pathname.split('/');
            const id = vars[vars.length - 1];
            updateSupportUser({userId : id}).then(saveActiveUser)
        }
    }

    render() {
        const {classes} = this.props;

        return (
            <div className={classes.container}>
                <DialogsContainer/>

                {isUrlEndsWithSupport() && <NoChatScreen/>}

                <Route path={HOME_PATH + '/bot/:botId/support/:userId'}
                       render={() => <ChatContainer/>}/>
            </div>
        )
    }
}

SupportContainer.propTypes = {
    classes: PropTypes.object
};

export default withStyles(styles)(SupportContainer);
