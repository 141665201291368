import React from 'react';
import SockJS from 'sockjs-client';
import * as Stomp from 'webstomp-client';
import * as cookie from 'react-cookies';

import {BACKEND_URL} from '../configs';
import {getUrlEnd} from "../helpers/getUrlEnd";
import {isOpenBot} from "../helpers/isOpenBot";
import {isSupportTab} from "../helpers/isSupportTab";

import {updateSupportUser} from '../tabs/support/api/users';

import {updateSupportUserList} from "../tabs/support/actions/users";
import {saveMessage, saveActiveUser} from "../tabs/support/actions/activeUser";
import {getSupportRequests} from "../tabs/support/api/support";
import NotificationService from '../services/notificationService';

export const SharedWebsocketContext = React.createContext();

class WebSockets extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      client: null,
      connected: false,
      reconnectInterval: null,
      interval: 1000,
      reconnectCount: 0
    };
  }

  componentDidMount() {
    this.setupSocketClient();
  }

  componentWillUnmount() {
    this.closeConnection();
  }

  setupSocketClient = () => {
    const sockJS = new SockJS(BACKEND_URL + '/web?Authorization=' + cookie.load('YWxpYWFkbWlu'));
    let client = Stomp.over(sockJS);

    client.heartbeat = {
      incoming: 0,
      outgoing: 0
    };

    client.connect({}, this.onConnectSuccess, this.onConnectError);
    client.onclose = () => this.reconnect();

    this.setState({
      client
    });
  };

  closeConnection() {
    const {client} = this.state;

    if (client) {
      if (client.connected) {
        client.unsubscribe('/topic/admin.chatStatus');
        client.unsubscribe('/topic/admin.message');
        client.disconnect();
      }
    }
  }

  onConnectSuccess = () => {
    this.setState({connected: true, interval: 1000}, this.subscribe);
  };

  onConnectError = () => {
    this.reconnect();
  };

  reconnect = () => {
    const {reconnectCount, reconnectInterval, interval} = this.state;
    clearInterval(reconnectInterval);

    if (reconnectCount < 3) {
      const newInterval = setInterval(() => this.setupSocketClient, interval);

      this.setState({
        connected: false,
        reconnectInterval: newInterval,
        interval: interval * 2,
        reconnectCount: reconnectCount + 1
      });
    } else {
      this.closeConnection();
    }
  };

  subscribe = () => {
    this.subscribeNewMessages();
    this.subscribeUserChatStatuses();
  };

  subscribeNewMessages = () => {
    const {botId, adminUser} = this.props;
    const {client} = this.state;
    client.subscribe(`/topic/admin.message.${botId}`, (msg) => {
      const body = JSON.parse(msg.body);
      const isSelectedBot = isOpenBot() && Number(botId) === body?.botId;
      const isCurrentAdmin = body?.adminId === adminUser.id;

      //display browser notification
      if (!body.postback && isSelectedBot && isCurrentAdmin) {
        if (document.hidden || (!isSupportTab())) {
          const message = JSON.parse(body.message);
          if (!message.isEcho) {
            const title = `Message from: ${body.firstName} ${body.lastName}`;
            NotificationService.displayNotification(title, message.text, {
              userId: body.userId,
              botId
            });
          }
        }
      }

      updateSupportUser(body)
        .then(user => {
          if (user) {
            updateSupportUserList(user, adminUser.id);
            if (Number(getUrlEnd()) === user?.userId) saveMessage(body)
          }
        })
    })
  };

  subscribeUserChatStatuses = () => {
    const {botId, adminUser} = this.props;
    const {client} = this.state;

    client.subscribe(`/topic/admin.chatStatus.${botId}`, (chatStatus) => {
      const user = JSON.parse(chatStatus.body);
      Promise.all([updateSupportUser(user), getSupportRequests(botId)])
        .then((res) => {
          const copyUser = Object.assign({}, res[0], user);
          updateSupportUserList(copyUser, adminUser.id);
          if (Number(getUrlEnd()) === copyUser?.userId) saveActiveUser(copyUser);
        });
    })
  };

  sendMessage = (message) => {
    this.state.client.send('/app/admin', JSON.stringify(message));
  };

  render() {
    return (
      <SharedWebsocketContext.Provider
        value={{sendMessage: this.sendMessage}}>
        {this.props.children}
      </SharedWebsocketContext.Provider>
    );
  }
}

export default WebSockets
