import React, {useState} from "react";
import {useForm, Controller} from "react-hook-form/dist/index.ie11";

import {updateAccountSettings} from "../../api/account";

import {UserImageDropzone} from "../UserImageDropzone";

import SubmitButton from "../../../../uiKit/buttons/SubmitButton";
import Loader from "../../../../uiKit/loaders/loader";

export const AccountSettingsForm = props => {
    const {register, handleSubmit, errors, control} = useForm();
    const [isDisabledBtn, setIsDisabledBtn] = useState(false);
    const {classes} = props;
    const loading = !props.accountSettings;

    const onSubmit = data => {
        const prevData = props.accountSettings;

        updateAccountSettings(props.adminUser.id, {
            ...prevData,
            ...data
        })
    }

    const handleDisableBtn = value => setIsDisabledBtn(value)

    return (
        <>
            {loading
                ? (
                    <div className={classes.loaderWrap}>
                        <Loader/>
                    </div>
                )
                : (
                    <div className={classes.container}>
                        <h1 className={classes.title}>Account Settings</h1>
                        <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
                            <div className={classes.formImgItem}>
                                <Controller
                                    name="imageUrl"
                                    control={control}
                                    render={({onChange}) => (
                                        <UserImageDropzone
                                            setFormValue={onChange}
                                            handleDisableBtn={handleDisableBtn}
                                            imageUrl={props.accountSettings && props.accountSettings.imageUrl}
                                        />
                                    )}
                                />
                            </div>
                            <div className={classes.formItem}>
                                <label htmlFor="userName">
                                    <span>Name</span>
                                </label>
                                <input
                                    type="text"
                                    name="name"
                                    id="userName"
                                    ref={register({required: true})}
                                    defaultValue={props.accountSettings && props.accountSettings.name}
                                />
                                {errors.name && (
                                    <div className={classes.errorMessage}>
                                        <p>This field is required</p>
                                    </div>
                                )}
                            </div>
                            <div className={classes.formItem}>
                                <label htmlFor="userEmail">
                                    <span>Email</span>
                                </label>
                                <input
                                    name="email"
                                    type="email"
                                    id="userEmail"
                                    readOnly
                                    value={props.adminUser?.email}
                                    ref={register}
                                />
                            </div>
                            <div className={classes.btnHolder}>
                                <SubmitButton title='Save' disabled={isDisabledBtn}/>
                            </div>
                        </form>
                    </div>
                )
            }
        </>
    )
}
