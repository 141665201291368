/**
 * Created by dshevchenko on 15.04.2018.
 */
import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {IconButton, withStyles} from '@material-ui/core';
import CustomPopover from './CustomPopover';
import {EditMenuIcon, TrashIcon} from '../../../../uiKit/icons/Icons';

const styles = theme => ({
    levelItem: {
        padding: 24,
        display: 'flex',
        justifyContent: 'space-between',
        borderTop: '1px solid #EBEBEF',
        cursor: 'pointer',
        position: 'relative'
    },
    title: {
        fontFamily: 'Lato',
        fontSize: 14,
        color: '#3A3F62',
        marginRight: 8
    },
    submenu: {
        fontSize: 12,
        fontFamily: 'Lato',
        color: '#3A3F62',
        marginBottom: 0,
        marginTop: 2
    },
    tab: {
        width: 100,
        fontSize: 14,
        fontFamily: 'Lato',
        color: '#1658F3',
        textAlign: 'center',
        padding: '12px 0'
    },
    tabs: {
        display: 'flex'
    },
    popover: {
        width: 300
    },
    tabContainer: {
        padding: '24px 16px'
    },
    paragraph: {
        fontFamily: 'Lato',
        fontSize: 14,
        color: '#616581',
        fontWeight: 300
    },
    description: {
        fontFamily: 'Lato',
        fontSize: 10,
        color: '#B0B2C0',
        margin: '5px 0 0',
        overflow: 'hidden'
    },
    '@global': {
        '.editMenu': {
            color: '#3A3F62'
        },
        '.editMenu svg path': {
            stroke: '#3A3F62'
        },
        '.editMenu:hover': {
            color: '#1658F3'
        },
        '.editMenu:hover svg path': {
            stroke: '#1658F3'
        },
        '.item .deleteIcon': {
            display: 'none'
        },
        '.item:hover .deleteIcon': {
            display: 'block',
            position: 'absolute',
            right: -20,
            top: 15
        }
    }
});


class MenuItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            menuItem: null
        };
    }

    toggle = (value) => {
        this.setState({
            open: value
        });
    }

    static getDerivedStateFromProps(props, state) {
        if (state.menuItem === null && props.menuItem !== null) {
            return ({
                menuItem: props.menuItem,
                open: props.defaultOpen === props.index
            });
        }
        if (props.defaultOpen === props.index && !state.open) {
            props.setDefault();
            return ({
                open: props.defaultOpen === props.index
            });
        }
        return null;
    }

    function (obj) {
        let size = 0;
        let
            key;
        for (key in obj) {
            if (obj.hasOwnProperty(key)) size++;
        }
        return size;
    }


    render() {
        const {
            classes, index, menuItem
        } = this.props;
        const {
            open
        } = this.state;
        return (
            <div className="item">
                <div
                    className={classes.levelItem}
                    ref={(node) => {
                        this.itemRef = node;
                    }}
                    onClick={() => {
                        this.toggle(true);
                    }}
                >
                    <div className={classes.title}>{menuItem.title}</div>
                    {menuItem.type === 'postback' && (
                        <p className={classes.description}>
                            {menuItem.payload}
                        </p>
                    )}
                    {menuItem.type === 'web_url' && (
                        <p className={classes.description}>
                            {menuItem.url}
                        </p>
                    )}
                    <div className="deleteIcon">
                        <IconButton
                            className="trashIcon"
                            style={{
                                background: 'white',
                                border: '1px solid #EBEBEF',
                                boxShadow: '0px 3px 6px #EBEBEF',
                                width: 40,
                                height: 40
                            }}
                            aria-label="Delete"
                            onClick={(e) => {
                                this.props.deleteItem(index);
                                e.stopPropagation();
                            }}
                        >
                            <TrashIcon width="20px" height="20px" color="#B0B2C0"/>
                        </IconButton>
                    </div>
                </div>
                <CustomPopover
                    open={open}
                    onClose={() => {
                        this.toggle(false);
                    }}
                    menuItem={menuItem}
                    anchorEl={this.itemRef}
                    handleChange={(name, value) => this.handleChange(name, value)}
                    onUpdate={menuItem => this.props.onUpdate(menuItem)}
                />
                {/* //TODO check if title has changed */}
            </div>


        );
    }
}

MenuItem.propTypes = {
    classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    activeBot: state.activeBot
});

export default withStyles(styles, {withTheme: true})(connect(mapStateToProps)(MenuItem));
