import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Link} from "react-router-dom";
import {requestPasswordReset} from "../api/admin";
import LoaderOverlay from '../../../uiKit/loaders/loaderOverlay'
import FormMessage from './FormMessage'
import Input from './Input'
import Button from './Button'
import {isStringEmpty} from "../../../helpers/isStringEmpty";
import {emailValidation} from "../../../helpers/emailValidation";

const styles = theme => ({
    forgotPasswordForm: {
        position: 'relative'
    },
    errorMessage: {
        marginBottom: '20px'
    }
});

class ForgotPasswordForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            emailError: false,
            error: false,
            processingForgotPassword: false,
            submitFormSuccessfully: false
        };
    }

    handleChangeEmail = (e) => {
        this.setState({
            email: e.target.value,
            emailError: !emailValidation(e.target.value)
        });
    };

    handleRequestPasswordReset = () => {
        if (this.state.emailError) {
            return;
        }

        this.setState({
            processingForgotPassword: true
        });

        requestPasswordReset(this.state.email)
            .then((response) => {
                if (response.ok) {
                    this.setState({
                        submitFormSuccessfully: true,
                        processingForgotPassword: false
                    })
                } else {
                    if (response.status === 400) {
                        response.json().then((text) => {
                            this.setState({
                                error: true,
                                errorMessage: text.message,
                                processingForgotPassword: false
                            });
                        });
                    } else {
                        this.setState({
                            error: true,
                            processingForgotPassword: false
                        });
                    }
                }
            });
    };

    renderForgotPasswordForm = (classes) => {
        if (this.state.submitFormSuccessfully) {
            return (
                <div className={`${classes.demoForm} animated zoomIn faster`}>
                    <div className={classes.forgotPasswordForm}>
                        <FormMessage title="Check your mail box"
                                     subtitle="We’ve sent instructions on how to change your password to your email."
                                     buttonTitle="Go to sign in"
                                     buttonUrl="/login"/>
                    </div>
                </div>
            );
        }

        return (
            <div className={`${classes.demoForm} animated zoomIn faster`}>
                <div className={classes.forgotPasswordForm}>
                    {this.state.processingForgotPassword &&
                        <LoaderOverlay/>
                    }

                    <h4 className={classes.formTitle}>Reset your password</h4>
                    <div className={classes.demoDiv}>
                        <Input id="email"
                               type="email"
                               placeholder="Type your email address"
                               label="Your email address"
                               errorLabel="Enter correct email"
                               required={true}
                               hasError={this.state.emailError}
                               onChange={this.handleChangeEmail}
                               pressEnterCallback={this.handleRequestPasswordReset}/>

                        {this.state.error
                            ? <div className={classes.errorMessage}>
                                  {!isStringEmpty(this.state.errorMessage)
                                      ? this.state.errorMessage
                                      : "Something went wrong, try one more time"}
                              </div>
                            : <div/>
                        }
                    </div>
                    <h6 className={classes.forgotPasswordNoteText}>
                        We will send you a link to change your password.
                    </h6>
                    <Button type="submit"
                            title="Request a password reset"
                            onClick={this.handleRequestPasswordReset}/>
                </div>
            </div>
        );
    }

    render() {
        const classes = {...this.props.styles, ...this.props.classes};

        return (
            <main className={classes.main}>
                {this.renderForgotPasswordForm(classes)}
                <br/>
                <span className={classes.bottomText}>
                    Remembered your password? <Link to={"/Login"}>Sign in</Link>
                </span>
            </main>
        )
    }
}

export default withStyles(styles, {withTheme: true})(ForgotPasswordForm);
