import React from 'react';

import {withStyles} from "@material-ui/core/styles";

import {styles} from '../slider/ArrowIconsStyles'
import {Prev} from "../slider/Prev";

const PrevIcon = (props) => {
    const {classes, onClick, top} = props;

    return (
        <div className={classes.wrap} style={{left: -7, top}} onClick={onClick}>
            <Prev/>
        </div>
    )
};

export default withStyles(styles)(PrevIcon);
