import {store} from '../../../index';

export const ADD_CONTEXT = 'ADD_CONTEXT';
export const DELETE_CONTEXT = 'DELETE_CONTEXT';
export const SAVE_CONTEXTS = 'SAVE_CONTEXTS';
export const UPDATE_CONTEXT = 'UPDATE_CONTEXT';
export const CLEAR_CONTEXTS = 'CLEAR_CONTEXTS';

export const addContext = (context) => {
	store.dispatch(
		{
			type: ADD_CONTEXT,
			context
		}
	);
};

export const removeContext = (contextId) => {
	store.dispatch(
		{
			type: DELETE_CONTEXT,
			contextId
		}
	);
};

export const saveContexts = (contexts) => {
	store.dispatch(
		{
			type: SAVE_CONTEXTS,
			contexts
		}
	);
};

export const updateExistedContext = (context) => {
	store.dispatch(
		{
			type: UPDATE_CONTEXT,
			context
		}
	);
};

export const clearContexts = () => {
	store.dispatch(
		{
			type: CLEAR_CONTEXTS
		}
	);
};
