import React from 'react';

export const RobotIcon = () => {
	return (
		<svg width="235" height="180" viewBox="0 0 235 180" fill="none">
			<path d="M195.896 39.8416C197.053 38.4772 199.954 33.3354 208.89 17.3832L202.773 12.7573C195.365 20.2009 186.058 29.6136 185.593 29.8854C185.543 29.9187 195.896 39.8416 195.896 39.8416Z" fill="#37455C"/>
			<path d="M41.202 38.4161C40.0449 37.0516 37.1438 31.9099 28.208 15.9577L34.3258 11.3318C41.7335 18.7754 51.0403 28.1881 51.5054 28.4599C51.5552 28.4931 41.202 38.4161 41.202 38.4161Z" fill="#37455C"/>
			<path d="M63.0211 30.745C65.9665 35.8535 62.1906 43.5523 54.5891 47.9508C46.9876 52.3437 38.4448 51.7724 35.4994 46.6639C32.554 41.5555 36.3299 33.8567 43.9314 29.4582C51.5274 25.0597 60.0757 25.6366 63.0211 30.745Z" fill="#465977"/>
			<path d="M40.1171 11.2985C40.1171 17.4719 35.1232 22.475 28.9611 22.475C22.7991 22.475 17.8052 17.4719 17.8052 11.2985C17.8052 5.12503 22.7991 0.121953 28.9611 0.121953C35.1232 0.116406 40.1171 5.11949 40.1171 11.2985Z" fill="#E7F0FD"/>
			<path opacity="0.46" d="M28.961 0.116455C28.0696 0.116455 27.206 0.221842 26.3755 0.415974C31.2919 1.58632 34.9514 6.01255 34.9514 11.293C34.9514 16.5734 31.2919 20.9996 26.3755 22.17C27.206 22.3696 28.0696 22.4695 28.961 22.4695C35.1231 22.4695 40.1169 17.4664 40.1169 11.293C40.1169 5.11954 35.1231 0.116455 28.961 0.116455Z" fill="#A3B6BF"/>
			<path opacity="0.46" d="M28.9612 10.4166C28.9612 12.968 26.9016 15.0314 24.3549 15.0314C21.8136 15.0314 19.7485 12.968 19.7485 10.4166C19.7485 7.87066 21.8081 5.80176 24.3549 5.80176C26.8961 5.80176 28.9612 7.86511 28.9612 10.4166Z" fill="#E7F0FD"/>
			<path d="M174.077 32.1706C171.132 37.279 174.908 44.9778 182.509 49.3763C190.111 53.7692 198.653 53.1924 201.599 48.0895C204.544 42.981 200.768 35.2822 193.167 30.8838C185.565 26.4853 177.022 27.0621 174.077 32.1706Z" fill="#465977"/>
			<path d="M196.981 12.7185C196.981 18.8919 201.975 23.895 208.137 23.895C214.299 23.895 219.293 18.8919 219.293 12.7185C219.293 6.54507 214.299 1.54199 208.137 1.54199C201.975 1.54199 196.981 6.54507 196.981 12.7185Z" fill="#E7F0FD"/>
			<path opacity="0.46" d="M208.137 1.54199C209.028 1.54199 209.892 1.64738 210.722 1.84706C205.806 3.01186 202.146 7.43809 202.146 12.724C202.146 18.0045 205.801 22.4307 210.722 23.601C209.892 23.7952 209.028 23.9006 208.137 23.9006C201.975 23.9006 196.981 18.8975 196.981 12.724C196.981 6.54507 201.975 1.54199 208.137 1.54199Z" fill="#A3B6BF"/>
			<path opacity="0.46" d="M208.137 11.8365C208.137 14.388 210.197 16.4513 212.744 16.4513C215.285 16.4513 217.35 14.388 217.35 11.8365C217.35 9.29058 215.29 7.22168 212.744 7.22168C210.202 7.22723 208.137 9.29058 208.137 11.8365Z" fill="#E7F0FD"/>
			<path d="M117.5 157.592C182.393 157.592 235 133.923 235 104.726C235 75.5297 182.393 51.8612 117.5 51.8612C52.6065 51.8612 0 75.5297 0 104.726C0 133.923 52.6065 157.592 117.5 157.592Z" fill="#465977"/>
			<path d="M85.5658 179.612C107.33 176.589 129.409 176.123 151.35 178.258C201.815 183.173 219.775 107.444 212.577 69.3275C208.907 49.8921 187.287 35.9644 169.033 31.638C135.948 23.8006 100.608 23.3069 67.3509 30.2957C49.5125 34.1118 31.8623 46.2368 24.9141 63.6644C10.4031 100.078 36.2693 186.467 85.5658 179.612Z" fill="#D9E5F6"/>
			<path opacity="0.46" d="M24.914 63.6644C28.3466 55.0505 34.398 47.7289 41.7947 42.0713C39.3088 45.3882 37.2382 48.9935 35.6935 52.8651C21.1825 89.2789 47.0488 175.668 96.3453 168.812C118.109 165.789 140.188 165.323 162.135 167.459C174.105 168.624 184.247 165.251 192.663 158.917C182.653 171.846 168.989 179.972 151.355 178.258C129.414 176.123 107.33 176.589 85.5714 179.612C36.2693 186.467 10.403 100.078 24.914 63.6644Z" fill="#A3B6BF"/>
			<path d="M162.339 60.4585C132.52 58.1344 102.568 58.1344 72.7486 60.4585C24.9359 64.1803 8.47049 143.359 94.9553 155.229C122.388 158.995 155.33 157.026 179.375 142.1C217.028 118.726 203.603 63.67 162.339 60.4585Z" fill="#E7F0FD"/>
			<path d="M118.912 155.573C111.078 155.573 103.077 155.018 95.1326 153.931C49.7116 147.696 34.1763 123.108 34.7909 102.18C35.4165 80.7815 51.7822 63.4038 72.8539 61.762C87.6584 60.6083 102.695 60.0259 117.544 60.0259C132.393 60.0259 147.43 60.6083 162.234 61.762C180.267 63.1653 194.689 75.5066 198.985 93.1949C203.497 111.771 195.525 130.529 178.683 140.985C159.267 153.043 135.46 155.573 118.912 155.573Z" fill="#465977"/>
			<path d="M84 91.5C84 97.295 79.3058 102 73.5 102C67.6942 102 63 97.295 63 91.5C63 85.6942 67.6942 81 73.5 81C79.295 81 84 85.6942 84 91.5Z" fill="white"/>
			<path d="M165 91.5C165 97.295 160.306 102 154.5 102C148.694 102 144 97.295 144 91.5C144 85.6942 148.694 81 154.5 81C160.295 81 165 85.6942 165 91.5Z" fill="white"/>
			<path d="M117.5 122C124.873 122 131.102 127.932 131.993 135.797C132.059 136.391 131.646 136.929 131.067 137C130.776 137.034 124.138 132.412 117.5 132.412C110.862 132.412 104.224 137.034 103.933 137C103.355 136.929 102.939 136.393 103.007 135.797C103.896 127.932 110.127 122 117.5 122Z" fill="white"/>
			<g opacity="0.34">
				<path d="M125.844 41.2616C126.353 45.7932 140.05 47.973 156.178 50.0641C172.322 52.0054 186.136 53.2645 187.741 48.9992C189.369 44.8447 176.508 36.0976 158.199 33.8457C139.895 31.5217 125.284 36.8298 125.844 41.2616Z" fill="white"/>
			</g>
		</svg>

	);
};
