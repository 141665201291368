import React, {useEffect, useState} from 'react';
import {withStyles} from '@material-ui/core';
import PropTypes from 'prop-types';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";

import Search from "../Search/Search";
import DialogsSection from "../DialogsSection/DialogsSection";
import {
    getAllSupportUsers,
    getAssignedSupportUsers,
    getNewRequestSupportUsers,
    getExpiredRequestSupportUsers,
} from "../../api/users";
import {saveMoreSupportUsers, saveSupportUsers} from "../../actions/users";
import useDebounce from "../../../../hooks/useDebounce";
import {getUrlEnd} from "../../../../helpers/getUrlEnd";
import {saveActiveUser} from "../../actions/activeUser";
import LoaderSmall from "../../../../uiKit/loaders/loaderSmall";

import {isElementBottom} from '../../../../helpers/isElementBottom';
import {isUrlEndsWithSupport} from '../../../../helpers/isUrlEndsWithSupport';

import {styles} from './styles';

const OpenRequests = (props) => {
    const {classes, match, assignedToMeUsers, newRequestsUsers, expiredRequestsUsers, assignedRequestsUsers, allUsers, adminUserId} = props;
    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(true);
    const debouncedSearchTerm = useDebounce(search, 500);

    useEffect(() => {
        if (debouncedSearchTerm) {
            Promise.all([
                getNewRequestSupportUsers(match.params.botId, debouncedSearchTerm),
                getExpiredRequestSupportUsers(match.params.botId, debouncedSearchTerm),
                getAssignedSupportUsers(match.params.botId, debouncedSearchTerm),
                getAllSupportUsers(match.params.botId, debouncedSearchTerm)
            ]).then((res) => saveSupportUsers(res.flat(), adminUserId))
        } else {
            Promise.all([
                getNewRequestSupportUsers(match.params.botId),
                getExpiredRequestSupportUsers(match.params.botId),
                getAssignedSupportUsers(match.params.botId),
                getAllSupportUsers(match.params.botId)
            ]).then((res) => {
                if (!isUrlEndsWithSupport() && !allUsers?.length) {
                    const userId = getUrlEnd();
                    const currentUser = res.flat().find(user => user?.userId === Number(userId));
                    saveActiveUser(currentUser);
                }
                saveSupportUsers(res.flat(), adminUserId);
            }).then(() => setLoading(false))
        }
    }, [debouncedSearchTerm]);

    const handleScroll = (event) => {
        if (isElementBottom(event.target)) {
            getAllSupportUsers(match.params.botId, search && debouncedSearchTerm, allUsers.length)
              .then((users) => {
                  saveMoreSupportUsers(users, adminUserId);
              });
        }
    };

    return (
        <>
            <div className={classes.searchContainer}>
                <Search search={search}
                        handleSearch={setSearch}/>
            </div>
            {loading
                ? <div className={classes.loaderWrap}>
                    <LoaderSmall showLoader={true}/>
                </div>
                : <div className={classes.sectionWrap}
                       onScroll={handleScroll}>
                    {!debouncedSearchTerm && <>
                        <DialogsSection
                            title="Assigned to me"
                            search={debouncedSearchTerm}
                            users={assignedToMeUsers}/>

                        <DialogsSection
                            title="New Requests"
                            search={debouncedSearchTerm}
                            users={newRequestsUsers}/>

                        <DialogsSection
                            title="Expired Requests"
                            search={debouncedSearchTerm}
                            users={expiredRequestsUsers}/>

                        <DialogsSection
                            title="Assigned"
                            search={debouncedSearchTerm}
                            users={assignedRequestsUsers}/>
                    </>}
                    <DialogsSection
                        title="Chatbot"
                        search={debouncedSearchTerm}
                        users={allUsers}/>
                </div>}
        </>
    );
};

OpenRequests.propTypes = {
    classes: PropTypes.object,
    assignedToMeUsers: PropTypes.array,
    newRequestsUsers: PropTypes.array,
    expiredRequestsUsers: PropTypes.array,
    assignedRequestsUsers: PropTypes.array,
    allUsers: PropTypes.array,
    adminUserId: PropTypes.number
};

const mapStateToProps = state => ({
    assignedToMeUsers: state.supportUsersNew.assignedToMeUsers,
    newRequestsUsers: state.supportUsersNew.newRequestsUsers,
    expiredRequestsUsers: state.supportUsersNew.expiredRequestsUsers,
    assignedRequestsUsers: state.supportUsersNew.assignedRequestsUsers,
    allUsers: state.supportUsersNew.allUsers,
    adminUserId: state.adminUser.id
});

export default withRouter(withStyles(styles)(connect(mapStateToProps)(OpenRequests)));
