import React from 'react';
import {connect} from "react-redux";
import {withStyles} from "@material-ui/core";
import PropTypes from 'prop-types';

import SubmitButton from "../../../../../uiKit/buttons/SubmitButton";
import WhatAppTabTemplateView from "./WhatsAppTabTemplateView";
import {saveAmioCredentials} from "../../../api/whatsApp";
import {inputStyle, styles} from './style';
import {alertError, alertSuccess} from "../../../../../api";
import {fieldsList} from './config';
import Field from "./Field";

class WhatsApp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            credentials: {
                accessToken: '',
                secret: '',
                channelId: ''
            }
        };
    }

    componentDidMount() {
        let {activeBot} = this.props;
        if (activeBot.amioWhatsAppConfigs) {
            this.setState({
                credentials: activeBot.amioWhatsAppConfigs
            });
        }
    };

    onSubmit = () => {
        let {credentials} = this.state;
        let {activeBot: {id}} = this.props;
        this.saveCredentials(id, credentials);
	};

	saveCredentials = (id, credentials) => {
		saveAmioCredentials(id, credentials)
			.then((response) => {
				if (response && response.ok) {
					if (response.message) {
						response.json().then(json => alertSuccess(json.message))
					} else {
						alertSuccess("Amio WhatsApp config is updated successfully.");
					}
				} else {
					response.json().then(json => alertError(json.message))
				}
			});
	};

    handleChange = (value, key) => {
        const {credentials} = this.state;
        credentials[key] = value.trim();
        this.setState({
            credentials,
        });
    };

    render() {
        const {classes, derivedClasses, expanded, handleChangeExpansion} = this.props;
        const {credentials} = this.state;

        return (
            <WhatAppTabTemplateView
                tabExpanded={expanded}
                onChange={handleChangeExpansion}
            >
                <div className={classes.mb34}>
                    {fieldsList.map(field => (
                        <Field
                            field={field}
                            inputStyle={inputStyle}
                            value={credentials[field.key]}
                            onChange={event => this.handleChange(event.target.value, field.key)}
                        />
                    ))}
                </div>
                <div className={`${derivedClasses.tabInnerContainer} ${classes.mb16}`}>
                    <div className={derivedClasses.buttonHolder}>
                        <SubmitButton
                            title="Connect"
                            type={'secondary'}
                            size="sm"
                            onClick={this.onSubmit}
                        />
                    </div>
                </div>
            </WhatAppTabTemplateView>
        );
    }
}

WhatsApp.defaultProps = {
    derivedClasses: {},
    classes: {},
    activeBot: {}
};

WhatsApp.propTypes = {
    classes: PropTypes.object,
    derivedClasses: PropTypes.object,
    activeBot: PropTypes.object,
};

const mapStateToProps = (state) => (
    {
        activeBot: state.activeBot,
    });

export default withStyles(styles, {withTheme: true})(connect(mapStateToProps)(WhatsApp));
