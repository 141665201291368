import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = () => ({
    tableCell: {
        flexGrow: 1,
        flexBasis: 0,
        display: "flex",
        // justifyContent: "center",
        "&:last-child": {
            maxWidth: 50,
        },
        "&:nth-last-child(2)": {
            maxWidth: 150,
        }
    },
});

class TableCell extends React.Component {
    render() {
        const {classes, styles} = this.props;
        return (
            <div className={classes.tableCell} style={styles}>
                {this.props.children}
            </div>
        )
    }
}

export default withStyles(styles)(TableCell);
