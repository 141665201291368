import React from 'react';
import {withStyles} from '@material-ui/core';
import PropTypes from 'prop-types';

import MessageWrap from "../MessageWrap/MessageWrap";

import {styles} from './styles';

const TextMessage = (props) => {
    const {classes, text, timezone, isReceived, isRtl} = props;

    return (
        <MessageWrap timezone={timezone}
                     isReceived={isReceived}>
            <span className={isReceived ? classes.chatBubbleReceived : classes.chatBubbleSend}
                  style={{direction: isRtl && 'rtl'}}
            >
                {text}
            </span>
        </MessageWrap>
    );
};

TextMessage.propTypes = {
    classes: PropTypes.object,
    text: PropTypes.string,
    timezone: PropTypes.string,
    isReceived: PropTypes.bool,
    isRtl: PropTypes.bool
};

export default withStyles(styles)(TextMessage);
