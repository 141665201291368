import React from 'react';
import {withStyles} from '@material-ui/core';
import PropTypes from 'prop-types';

import {SearchIcon} from '../../../../uiKit/icons/Search'

import {styles} from './styles';

const Search = (props) => {
    const {classes, handleSearch, search, styles, placeholder} = props;

    return (
        <div className={classes.container}>
            <input className={classes.input}
                   placeholder={placeholder || 'Search chats/users'}
                   value={search}
                   style={styles}
                   onChange={(e) => handleSearch(e.target.value)}/>
            <div className={classes.iconWrap}>
                <SearchIcon/>
            </div>
        </div>
    );
};

Search.propTypes = {
    classes: PropTypes.object,
    handleSearch: PropTypes.func,
    search: PropTypes.string
};

export default withStyles(styles)(Search);
