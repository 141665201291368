/**
 * Created by Admin on 05.02.2018.
 */
import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import { availableDefaultMessages, availableOtherMessages } from "./AvailableMessages";
import { OtherIcon } from "../../../../uiKit/icons/Icons.js";

export function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        maxHeight: "95%",
        maxWidth: "95%",
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
        overflowY: "auto",
        width: '450px',
        padding: '40px 24px',
        color: '#3A3F62'
    };
}

const styles = theme => ({
    paper: {
        position: 'absolute',
        width: theme.spacing.unit * 60,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 4,
    },
    fab: {
        position: 'absolute',
        bottom: 25,
        right: 50,
        backgroundColor: "#2196f3",
        color: "#fff"
    },
    root: {
        width: '100%',
        maxWidth: 360
    },
    listBoxContainer: {
        width: '100%',
        overflowX: 'auto'
    },
    listBox: {
        display: "flex",
        // boxShadow: "0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)",
        borderRadius: 10,
        width: "max-content",
        color: "#7f7f7f",
        fontSize: 12,
        margin: '0 20px 45px'
    },
    listItem: {
        border: "1px solid #f3f3f3",
        width: 94,
        margin: 0,
        padding: '18px 10px 14px',
        boxSizing: 'border-box',
        textAlign: "center",
        cursor: "pointer",
        display: "flex",
        flexDirection: 'column',
        alignItems: "center",
        justifyContent: "space-between",
        color: '#3A3F62',
    },
    "@global": {
        ".itemAtom:hover": {
            background: '#5A98F7',
            color: 'white',
            border: "1px solid #5A98F7",
        },
        ".itemAtom:hover svg path": {
            stroke: 'white'
        },
        ".itemAtom:first-child": {
            borderBottomLeftRadius: 10,
            borderTopLeftRadius: 10,
        },
        ".itemAtom:last-child": {
            borderBottomRightRadius: 10,
            borderTopRightRadius: 10,
        },

    },
    otherItem: {
        padding: '8px 11px',
        fontSize: 12,
        margin: 10,
        cursor: "pointer",
    }
});

class NewMessage extends React.Component {
    state = {
        open: false,
        anchorEl: null,
        selectedIndex: 0,
    };

    handleOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({
            open: false,
            quickReplies: [],
        });
    };

    closeAndAddMessage(newMessage) {
        this.props.createNewMessage(newMessage);
        this.setState({
            open: false
        });
    }

    addRedirect() {
        this.props.addRedirect();
        this.setState({
            open: false
        });
    }

    addTags() {
        this.props.addTags();
        this.setState({
            open: false
        });
    }

    addRandomRedirect() {
        this.props.addRandomRedirect();
        this.setState({
            open: false
        });
    }

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.listBoxContainer}>
                <div className={classes.listBox}>
                    {availableDefaultMessages.map((message, index) =>
                        <div key={message.name}
                             className={`${classes.listItem} itemAtom`}

                             onClick={() => this.props.createNewMessage(message.newMessage)}
                        >
                            {message.icon}
                            <div>{message.name}</div>
                        </div>
                    )}
                    <div key="other"
                         className={`${classes.listItem} itemAtom`}
                         style={{ borderLeft: "2px solid rgb(243, 243, 243)" }}
                         onClick={this.handleOpen}
                    >
                        <OtherIcon width="17" height='6' color="#B0B2C0"/>
                        <div>Other</div>

                    </div>
                </div>
                <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={this.state.open}
                    onClose={this.handleClose}
                >
                    <div style={getModalStyle()} className={classes.paper}>
                        <div style={{ display: 'flex' }}>
                            {availableOtherMessages.map((message, index) =>
                                <div key={message.name}
                                     className={classes.otherItem}
                                     onClick={() => this.closeAndAddMessage(message.newMessage)}
                                >
                                    <div>{message.name}</div>
                                </div>
                            )}
                            <div key="redirect"
                                 className={classes.otherItem}
                                 onClick={() => this.addRedirect()}
                            >
                                <div>Redirect to atom</div>
                            </div>
                            <div key="tags"
                                 className={classes.otherItem}
                                 onClick={() => this.addTags()}
                            >
                                <div>Tags</div>
                            </div>
                            <div key="random_redirect"
                                 className={classes.otherItem}
                                 onClick={() => this.addRandomRedirect()}
                            >
                                <div>Random Redirect</div>
                            </div>
                        </div>


                        <Button className={this.props.classes.button}
                                variant="raised"
                                size="large"
                                color="primary"
                                style={{ float: "right" }} onClick={this.handleClose}>
                            Close
                        </Button>
                    </div>
                </Modal>
            </div>
        );
    }
}

NewMessage.propTypes = {
    classes: PropTypes.object.isRequired,
};

const NewMessageExport = withStyles(styles)(NewMessage);

export default NewMessageExport;
