/**
 * Created by Admin on 21.03.2018.
 */
import {SHOW_SNACKBAR} from "../actions/snackBar";

const snackBar = (state = {open: false}, action) => {
    if (action.type === SHOW_SNACKBAR) {
        return action.snackBar;
    } else {
        return state;
    }
};

export default snackBar
