import React from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import {deleteIntent} from "../api/nlp";
import {connect} from "react-redux";
import {Dialog, DialogActions, DialogTitle, Slide} from "@material-ui/core";

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const styles = theme => ({
    paper: {
        position: 'absolute',
        width: theme.spacing.unit * 50,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 4,
    },
    deleteInt: {
        flexBasis: '10%',
        color: '#FF624C',
        cursor: 'pointer'
    },
    flow: {
        boxShadow: '4px 4px 29px rgba(19, 69, 186, 0.14)',
        borderRadius: 10,
    },
    button: {
        background: '#1658F3',
        boxShadow: '0px 3px 9px rgba(19, 69, 186, 0.206267)',
        borderRadius: 10,
        margin: '40px 0',
        color: 'white',
        fontFamily: 'Lato, sans-serif'
    },
    "@global": {
        "[class*='MuiDialog-paperWidthSm']": {
            boxShadow: '4px 4px 29px rgba(19, 69, 186, 0.14)',
            borderRadius: 10
        },
        ".buttonHover:hover": {
            background: 'white',
            color: '#1658F3'
        },
        ".buttonHover1:hover": {
            color: 'white',
            background: '#1658F3'
        },
        ".buttonHover1": {
            background: 'white',
            color: '#1658F3'
        }
    }
});

class DeleteIntent extends React.Component {
    state = {
        open: false,
    };

    // componentWillMount() {
    //     getIntentContexts(this.props.intentId);  //todo fix ASAP
    // }

    handleOpen = () => {
        this.setState({open: true});
    };

    handleClose = () => {
        this.setState({open: false});
    };

    deleteIntent() {
        deleteIntent(this.props.intentId, this.props.intentsSize);
        this.setState({open: false});
    }

    render() {
        const {classes} = this.props;

        return (
            <div className={classes.deleteInt}>
                <div onClick={() => this.handleOpen()}>Delete</div>

                <Dialog

                    PaperProps={{}}
                    open={this.state.open}
                    transition={Transition}
                    keepMounted
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle id="alert-dialog-slide-title">
                        {"Are you sure you want to delete" + this.props.name + "?"}
                    </DialogTitle>
                    <DialogActions style={{display: 'flex', justifyContent: 'space-around'}}>
                        <Button className={`${classes.button} buttonHover`} onClick={() => this.deleteIntent()}
                                color="primary">
                            Delete
                        </Button>
                        <Button className={`${classes.button} buttonHover1`} onClick={this.handleClose}>
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }

    getContextList() {
        return this.props.contexts.map(e => e.currentAtom).join(", ");
    }
}

DeleteIntent.propTypes = {
    classes: PropTypes.object.isRequired,
};


const mapStateToProps = (state) => (
    {
        contexts: state.contexts,
        intents: state.intents
    });

export default withStyles(styles)(connect(mapStateToProps)(DeleteIntent));
