export const styles = () => ({
    button: {
        width: 110,
        height: 36,
        border: '1px solid #1658F3',
        borderRadius: 4,
        color: '#1658F3',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        fontWeight: 'bold',
        marginRight: '12px',
        backgroundColor: '#ffffff',
        whiteSpace:'nowrap',
        '&:disabled': {
            cursor: 'no-drop'
        }
    },
    popover: {
        width: 240,
        maxHeight: 180
    },
    adminItem: {
        padding: '7px 12px',
        cursor: 'pointer',
        fontSize: 14,
        '&:hover': {
            background: 'rgba(53, 64, 82, 0.1)'
        }
    }
});
