import React from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import {withRouter} from "react-router-dom";

const styles = theme => ({
    heading: {
        fontFamily: 'Lato',
        fontWeight: 'bold',
        fontSize: 24,
        color: '#3A3F62',
        marginBottom: 8,
    },
});

class Heading extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {classes} = this.props;
        return (
            <h2 className={classes.heading}>
                {this.props.children}
            </h2>
        )
    }
}

Heading.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(Heading));
