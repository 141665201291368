/**
 * Created by Admin on 02.02.2018.
 */
import React from "react";
import Select from "react-select"
import {Button, withStyles} from "@material-ui/core";
import "./Atom.css";
import "../components/messages/Messages.css";
import Modal from "@material-ui/core/Modal";
import NewMessage from "./messages/NewMessage";
import {getAtom, saveAtom} from "../api/atom";
import AtomNLPModal from "../../nlp/components/AtomNLPModal";
import {Scrollbars} from "react-custom-scrollbars";
import {connect} from "react-redux";
import {Prompt, withRouter} from "react-router-dom";
import RedirectToAtom from "./messages/RedirectToAtom";
import {DownIcon, UpIcon} from "../../../uiKit/icons/Icons";
import "array.prototype.move";
import {alertError} from "../../../api";
import TagAction from "./messages/TagAction";
import {loadTags} from "../api/tag";
import uuidv1 from "uuid/v1";
import {getBotLanguages} from "../../settings/api/settings";
import RandomAtoms from "./RandomAtoms/RandomAtoms";
import {randomRedirectInitialState} from "./messages/AvailableMessages";
import {isStringEmpty} from "../../../helpers/isStringEmpty";
import MessagesWrap from "../components/MessagesWrap/MessagesWrap";

const styles = theme => ({
    warning: {
        width: 42,
        height: 26,
        marginTop: 18,
    },
    headerBlock: {
        padding: '15px 40px',
        display: "flex",
        justifyContent: 'space-between'
    },
    headerSelectorAndButton: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        minWidth: '40%',
        height: '100%',
    },
    headerButton: {
        margin: "5px",
        background: 'linear-gradient(285.2deg, #5A98F7 -35.9%, #1658F3 61.17%)',
        boxShadow: '0px 3px 9px rgba(19, 69, 186, 0.206267)',
        color: 'white',
        borderRadius: 10,
        height: 45
    },
    root: {
        background: '#FFFFFF',
        boxShadow: '4px 4px 29px rgba(19, 69, 186, 0.0855129)'
    },
    input: {
        border: "none",
        outline: "none",
        borderRadius: "5px",
        fontSize: "14px",
        lineHeight: "19px",
        backgroundColor: "white",
    },
    inputHolder: {
        border: "1px solid #C7CAD6",
        width: "max-content",
        borderRadius: "10px",
        display: "flex",
        transition: '1s all ease',
        justifyContent: 'space-between',
        backgroundColor: "white",
        marginRight: 10,
        padding: 10,
        margin: 5,
        maxHeight: 40,
    },
    '@global': {
        '.element:hover>.text_element': {
            opacity: '1',
            transition: '0.5s all ease'
        },
        '.element:hover rect': {
            fill: '#1658F3',
            transition: '0.5s all ease'
        },
        '.element:hover path': {
            stroke: '#1658F3',
            transition: '0.5s all ease'
        },
        '.element>.text_element': {
            opacity: '0',
            transition: '0.5s all ease'
        },
    }
});

class AtomEditor extends React.Component {

    handleChange = event => {
        let atom = {...this.state.atom};
        atom.name = event.target.value;
        this.setState({
            atom: atom,
        });
    };
    handleOpen = () => {
        if (!this.state.atom.isHardCoded) {
            this.setState({open: true});
        }
    };
    handleClose = () => {
        if (this.state.isNameOk)
            this.setState({open: false});
    };
    handleOpenAtomNLPModal = () => {
        this.setState({openNlp: true});
    };
    handleCloseAtomNLPModal = () => {
        this.setState({openNlp: false});
    };

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            isNameOk: true,
            name: '',
            openNlp: false,
            languageOptions: [],
            selectedLanguageOption: {
                value: "EN",
                label: "English",
                rtl: false
            },
        };
    }

    componentDidMount() {
        getAtom(this.props.match.params.atomId, this.state.selectedLanguageOption.value, (atom) => {
            this.setState({atom: JSON.parse(JSON.stringify(atom))});
            loadTags(this.props.match.params.botId);
            this.setLanguageOptions(this.props.match.params.botId);
        });
    }

    setLanguageOptions = () => {
        getBotLanguages(this.props.activeBot.id).then(botLanguages => {
            if (botLanguages.defaultLanguage) {
                let {defaultLanguage, activeLanguages} = botLanguages;
                const selectedOption = {
                    label: defaultLanguage.fullName,
                    value: defaultLanguage.shortName,
                    rtl: defaultLanguage.isRtl
                };

                const languageOptions = activeLanguages.map(language => {
                    return {
                        label: language.fullName,
                        value: language.shortName,
                        rtl: language.isRtl
                    }
                });

                this.setState({
                    languageOptions: languageOptions,
                    selectedLanguageOption: selectedOption,
                });
            }
        });
    };

    handleChangeName(text) {
        let atom = {...this.state.atom};
        atom.name = text;
        this.setState({
            atom: atom,
        });
        if (this.props.atom.name !== text && this.getAtomNames().includes(text) || text.trim() === '') {
            this.setState({isNameOk: false})
        } else {
            this.setState({isNameOk: true})
        }
    }

    getAtomNames() {
        let names = [];
        if (this.props.flows) {

            this.props.flows.forEach(flow => {
                flow.atoms != null && flow.atoms.forEach(atom => {
                    names.push(atom.name);
                })
            });
        }
        return names;
    }

    saveAtom() {
        let send = true;
        this.state.atom.messages && this.state.atom.messages.forEach((message) => {
            if (message.type === "text" && message.texts.some(elem => isStringEmpty(elem))) {
                alertError(`You can't save an empty message.`);
                send = false
            }
        });

        if (this.state.atom.randomAtoms && this.state.atom.randomAtoms.some((randomAtom) => !randomAtom.atomName)) {
            alertError(`You can't save an empty elements.`);
            send = false
        }

        send && saveAtom(this.state.atom, this.props.match.params.botId, this.state.selectedLanguageOption.value, (atom) => {
            this.setState({atom: JSON.parse(JSON.stringify(atom))});
        });
    }

    static getDerivedStateFromProps(props, state) {
        if (state.atom && state.atom.id != props.match.params.atomId) {
            getAtom(props.match.params.atomId, state.selectedLanguageOption.value);
            return ({
                atom: props.atom,
                open: false,
                isNameOk: true
            });
        }
        return null;
    }


    deleteMessage = (message)  => {
        let newAtom = {...this.state.atom};
        newAtom.messages.splice(newAtom.messages.indexOf(message), 1);
        this.setState({atom: newAtom});
    }

    updateMessage = (message, index) => {
        let newAtom = {...this.state.atom};
        newAtom.messages[index] = message;
        this.setState({
            atom: newAtom,
        })
    }

    updateRedirect(redirectTo) {
        let newAtom = {...this.state.atom};
        newAtom.redirectTo = redirectTo;
        this.setState({
            atom: newAtom,
        })
    }

    updateTags(tags) {
        let newAtom = {...this.state.atom};
        newAtom.tags = tags;
        this.setState({
            atom: newAtom,
        });
    }

    updateRandomRedirect = (randomAtoms) => {
        let newAtom = this.state.atom;
        newAtom.randomAtoms = randomAtoms;
        this.setState({
            atom: newAtom
        });
    };

    updateSendFirstAtomOnlyOnce = (sendFirstAtomOnlyOnce) => {
        let newAtom = {...this.state.atom};
        newAtom.sendFirstAtomOnlyOnce = sendFirstAtomOnlyOnce;
        this.setState({
            atom: newAtom,
        })
    };

    moveToTop(oldIndex) {
        let newIndex = oldIndex - 1;
        let atom = JSON.parse(JSON.stringify(this.state.atom));
        atom.messages.move(oldIndex, newIndex);
        this.setState({
            atom: atom,
        });
    }

    moveToBottom(oldIndex) {
        let newIndex = oldIndex + 1;
        let atom = JSON.parse(JSON.stringify(this.state.atom));
        atom.messages.move(oldIndex, newIndex);
        this.setState({
            atom: atom,
        });
    }

    createNewMessage(message) {
        let newAtom = {...this.state.atom};
        if (!newAtom.messages) newAtom.messages = [];
        message['tempId'] = uuidv1();
        newAtom.messages.push(JSON.parse(JSON.stringify(message)))
        this.setState({
            atom: newAtom,
        });
    }


    addRedirect() {
        if (!this.state.atom.randomAtoms) {
            let newAtom = {...this.state.atom};
            newAtom.redirectTo = '';
            this.setState({
                atom: newAtom,
            });
        } else {
            alertError("Atom can contain only one of these elements: Redirect to atom, Random redirect");
        }
    }

    addTags() {
        let newAtom = {...this.state.atom};
        newAtom.tags = [];
        this.setState({
            atom: newAtom,
        });
    }

    addRandomRedirect = () => {
        if (this.state.atom.redirectTo === undefined || this.state.atom.redirectTo === null) {
            let newAtom = {...this.state.atom};
            newAtom.randomAtoms = JSON.parse(JSON.stringify(randomRedirectInitialState.randomAtoms));
            newAtom.sendFirstAtomOnlyOnce = randomRedirectInitialState.sendFirstAtomOnlyOnce;
            this.setState({
                atom: newAtom,
            });
        } else {
            alertError("Atom can contain only one of these elements: Redirect to atom, Random redirect");
        }
    };

    checkAtomForEditing() {
        let propsAtom = this.deepCopyFunction(this.props.atom);
        let stateAtom = this.deepCopyFunction(this.state.atom);

        return JSON.stringify(this.deleteTextsField(propsAtom)) !== JSON.stringify(this.deleteTextsField(stateAtom));
    };

    deepCopyFunction = inObject => {
        let outObject, value, key
        if (typeof inObject !== "object" || inObject === null) {
            return inObject // Return the value if inObject is not an object
        }
        // Create an array or object to hold the values
        outObject = Array.isArray(inObject) ? [] : {}
        for (key in inObject) {
            value = inObject[key]
            // Recursively (deep) copy for nested objects, including arrays
            outObject[key] = (typeof value === "object" && value !== null) ? this.deepCopyFunction(value) : value
        }

        return outObject
    };

    deleteTextsField(atom) {
        if (!atom || !atom.messages) return;
        atom.messages.forEach(msg => {
            delete msg.texts;
        });
        return atom;
    }

    handleSelectedLanguage = (languageOption) => {
        this.setState({
            selectedLanguageOption: languageOption,
        }, () => {
            getAtom(this.props.match.params.atomId, this.state.selectedLanguageOption.value, (atom) => {
                this.setState({atom: JSON.parse(JSON.stringify(atom))});
                loadTags(this.props.match.params.botId);
            })
        });
    };

    render() {
        const {classes} = this.props;
        const {rtl} = this.state.selectedLanguageOption;
        const nameBorder = !this.state.isNameOk ? '1px solid #FF624C' : '1px solid #C7CAD6'

        return (
            <div className={classes.root} style={{height: "100%", position: 'fixed', width: '42%'}}>
                {this.state.atom && (
                  <>
                    <Prompt when={this.checkAtomForEditing()}
                            message="Are you sure you want to leave this page without saving your current changes?"/>
                    <div key={this.state.atom.id}>
                      <div className={classes.headerBlock}>
                        <div style={{
                          maxHeight: 55,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between'
                        }}>
                            {this.state.open? (
                              <div className={classes.inputHolder} style={{
                                  display: 'block',
                                  border: nameBorder
                              }}>
                                  <input className={classes.input}
                                         autoFocus
                                         onBlur={() => this.handleClose()}
                                         value={this.state.atom.name}
                                         onKeyUp={this.checkEnterPress}
                                         onChange={(event) => this.handleChangeName(event.target.value)}
                                  />
                              </div>
                            ) : (
                              <p style={{
                                  fontWeight: 600, lineHeight: '20px', fontSize: 14,
                                  color: '#3A3F62'
                              }} onClick={() => this.handleOpen()}>{this.state.atom.name}</p>
                            )}
                        </div>
                        <div className={classes.headerSelectorAndButton}>
                          <div style={{width: '220px', marginRight: '16px'}}>
                            <Select
                              options={this.state.languageOptions}
                              value={this.state.selectedLanguageOption}
                              onChange={this.handleSelectedLanguage}
                            />
                          </div>

                          <Button disabled={!this.state.isNameOk}
                                  className={classes.headerButton} onClick={() => this.saveAtom()}>
                            Save
                          </Button>
                        </div>

                        <Modal
                          aria-labelledby="simple-modal-title"
                          aria-describedby="simple-modal-description"
                          open={this.state.openNlp}
                          onClose={this.handleCloseAtomNLPModal}
                        >
                          <AtomNLPModal atomId={this.state.atom.id}
                                        atomName={this.state.atom.name}
                                        closeAtomNLP={this.handleCloseAtomNLPModal}/>
                        </Modal>
                      </div>
                      <Scrollbars
                        style={{
                          position: "relative",
                          height: "calc(100vh - 220px)",
                        }}
                        ref={(scroll) => this.scrollbar = scroll}
                        renderView={props =>
                          <div {...props}
                               style={{
                                 position: "absolute",
                                 top: 0,
                                 left: 0,
                                 right: 0,
                                 bottom: 0,
                                 overflow: "scroll",
                                 marginRight: "-17px",
                                 marginBottom: "-17px",
                                 padding: 40,
                               }}
                          />}
                      >
                        {this.state?.atom?.messages?.map((message, index) => (
                          <div key={message.id || message.tempId}
                               style={{position: "relative", display: 'flex', alignItems: 'center'}}>
                            <div style={{cursor: 'pointer', position: 'relative', right: 20}} key={index}>
                              {this.state.atom.messages.length > 1 && index != 0 &&
                              <div style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexDirection: 'column'
                              }}
                                   className='element'
                                   onClick={() => {
                                     this.moveToTop(index)
                                   }}
                              >
                                <div
                                  className='text_element'
                                  style={{fontSize: 12, textAlign: 'center'}}>
                                  Move <br/>
                                  up
                                </div>
                                <div style={{margin: '15px 0'}}><UpIcon/></div>
                              </div>}
                              {this.state.atom.messages.length > 1 && index + 1 !== this.state.atom.messages.length &&
                              <div style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexDirection: 'column'
                              }}
                                   onClick={() => this.moveToBottom(index)}
                                   className='element'
                              >

                                <div style={{margin: '10px 0'}}><DownIcon/></div>
                                <div className='text_element' style={{fontSize: 12, textAlign: 'center'}}>
                                  Move <br/>
                                  down
                                </div>
                              </div>}
                            </div>
                            <MessagesWrap message={message}
                                          rtl={rtl}
                                          index={index}
                                          updateMessage={this.updateMessage}
                                          deleteMessage={this.deleteMessage}/>
                          </div>
                        ))}
                        {this.state.atom.redirectTo != null &&
                        <RedirectToAtom value={this.state.atom.redirectTo}
                                        onChange={(value) => this.updateRedirect(value)}
                                        onDelete={() => this.updateRedirect(null)}/>}
                        {this.state.atom.tags != null &&
                        <TagAction value={this.state.atom.tags}
                                   onChange={(value) => this.updateTags(value)}
                                   onDelete={() => this.updateTags(null)}/>}
                        {this.state.atom.randomAtoms != null &&
                        <RandomAtoms randomAtoms={this.state.atom.randomAtoms}
                                     sendFirstAtomOnlyOnce={this.state.atom.sendFirstAtomOnlyOnce}
                                     updateRandomRedirect={this.updateRandomRedirect}
                                     updateSendFirstAtomOnlyOnce={this.updateSendFirstAtomOnlyOnce}
                                     onDelete={() => this.updateRandomRedirect(null)}/>}

                      </Scrollbars>
                      <NewMessage
                        createNewMessage={(message) => this.createNewMessage(message)}
                        addRedirect={() => this.addRedirect()}
                        addTags={() => this.addTags()}
                        addRandomRedirect={this.addRandomRedirect}
                      />
                    </div>
                  </>
                )}
            </div>
        )
    };
}

const mapStateToProps = (state) => (
    {
        atom: state.atom,
        activeBot: state.activeBot,
        flows: state.flows,
    });

export default withRouter(withStyles(styles)(connect(mapStateToProps)(AtomEditor)));
