import {store} from '../../../index';

export const SET_SEARCH_ATOMS = 'SET_SEARCH_ATOMS';

export const setSearchAtoms = (searchAtoms = []) => {
  store.dispatch({
    type: SET_SEARCH_ATOMS,
    searchAtoms
  })
};
