import {createJsonHeaders} from "../../../api";
import {BASE_URL} from "../../../configs";

export function saveAmioCredentials(botId, data) {
	return fetch(`${BASE_URL}/bot/${botId}/amioWhatsAppConfig`, {
		credentials: 'same-origin',
		headers: createJsonHeaders(),
		method: "PUT",
		body: JSON.stringify(data)
	}).then(response => response)
		.catch(e => console.log(e));
}
