export const styles = () => ({
    container: {
        padding: '0px 16px',
        overflow: 'auto',
        height: 'calc(100% - 126px)'
    },
    chatDate: {
        textAlign: 'center',
        color: '#B0B2C0',
        fontSize: 12,
        margin: '24px 0px'
    },
    bottomScrollElem: {
        float: "left",
        clear: "both"
    },
    date: {
        textAlign: 'center',
        fontSize: 12,
        fontWeight: 500,
        color: '#3A3F62',
        margin: '16px 0 20px',
        width: '100%',
        display: 'flex',
        justifyContent: 'center'
    },
    loaderWrap:{
        height: 'calc(100% - 126px)',
        display:'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
});
