import React from "react";
import {withStyles} from "@material-ui/core";

import {styles} from "./languagesStyles";

const HeaderView = props => {
    const {classes, onButtonClick, availableLanguagesLength} = props;
    return (
        <div className={classes.headerWrapper}>
            <h2>Languages</h2>
            {availableLanguagesLength > 0 &&
                <div className={classes.addNewLanguageBtn}
                     onClick={onButtonClick}
                >
                    + Add Language
                </div>
            }
        </div>
    )
};

export default withStyles(styles, {withTheme: true})(HeaderView);
