import React from 'react';
import Loader from '../../../../uiKit/loaders/loader';
import { withStyles } from "@material-ui/core";

import { days, dayModel } from './daysModel';
import DayModel from "./DayModel";

const styles = theme => ({
    grid: {
        display: 'flex',
        flexFlow: 'row nowrap',
        backgroundColor: theme.palette.primary.contrastText,
        '&>:first-child': {
            marginRight: 16
        },
        '&:last-child': {
            marginRight: 0
        }
    },
    gridColumn: {
        width: 'calc(100% / 8)',
        marginRight: 5,
    },
    gridItem: {
        height: 10,
        width: '100%',
        marginBottom: 5
    },
    gridItemTransp: {
        backgroundColor: theme.palette.primary.contrastText,
        textAlign: 'right',
    },
    gridItemText: {
        color: theme.palette.primary.text,
        fontSize: 12,
        lineHeight: 1,
        fontFamily: 'Lato, sans-serif',
        textAlign: 'right',
        width: '100%',
        verticalAlign: 'top'
    },
    textCenter: {
        textAlign: 'center'
    }
});


const GridChart = ({ classes, chartData, color, maxPerHour }) => {
    const getDayData = (dayToAmount, dayName, time) => {
        let opacity = 3;
        let amount = 0;

        const dayHours = [...dayToAmount];
        const index = dayHours.findIndex(x => x.time === time);

        if (index !== -1 && dayHours[index] && dayHours[index].amount) {
            opacity = (dayHours[index].amount / maxPerHour) * 100;
            amount = dayHours[index].amount;
        }

        return { opacity, amount };
    };

    const groupByDays = (valuesArr, days) => (
        days.map(day => {
            const dayToAmount = valuesArr.filter(x => x.dayOfWeek === day);

            return ({
                dayOfWeek: day,
                dayToAmount: [...dayToAmount]
            });
        })
    );

    if (chartData) {
        const daysData = chartData.averageConversationByTimeOfDay;

        return (
            <div>
                <div className={classes.grid}>
                    <div className={classes.gridColumn}>
                        {dayModel.map((model, index) => (
                            <div key={model.time} className={`${classes.gridItem} ${classes.gridItemTransp}`}>
                                {
                                    (index % 2 === 0) && <span className={classes.gridItemText}>{model.time}</span>
                                }
                            </div>
                        ))}
                    </div>
                    {
                        groupByDays(daysData, days).map(day => {
                            const dayShort = day.dayOfWeek.substr(0, 3).toLowerCase();

                            return (
                                <div className={classes.gridColumn} key={day.dayOfWeek}>
                                    {
                                        <DayModel
                                            dayModel={dayModel}
                                            color={color}
                                            day={day}
                                            getDayData={getDayData}
                                        />
                                    }
                                    <div className={`${classes.gridItem} ${classes.gridItemTransp}`}>
                                        <div className={`${classes.gridItemText} ${classes.textCenter}`}>
                                            {dayShort.charAt(0).toUpperCase() + dayShort.slice(1)}
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        )
    }

    return <div><Loader/></div>;
};


export default withStyles(styles, { withTheme: true })(GridChart);
