import React, {Fragment, useState} from 'react';
import {withStyles} from "@material-ui/core";
import {withRouter} from "react-router-dom";

import {styles} from "./PopoverSupportStyles";
import Popover from "@material-ui/core/Popover";
import {ANCHOR_ORIGIN, TRANSFORM_ORIGIN} from "../CommentsFilter/CommentsFilterConfig";
import {ThreeIcon} from "../../../../uiKit/icons/TreeDots";
import {HOME_PATH} from "../../../../configs";

const PopoverSupport = (props) => {
    const {classes, userId, messageId} = props;
    const [target, setTarget] = useState(null);
    const [isOpenPopover, setOpenPopover] = useState(false);

    const redirectToChat = () => {
        const {botId} = props.match.params;
        const link = `${HOME_PATH}/bot/${botId}/support/${userId}#${messageId}`;
        window.open(link, '_blank');
        setOpenPopover(false)
    };

    return <Fragment>
        <div onClick={() => setOpenPopover(true)}
             style={{cursor: 'pointer'}}
             ref={node => setTarget(node)}>
            <ThreeIcon/>
        </div>
        <Popover
            classes={{paper: classes.popover}}
            open={isOpenPopover}
            anchorEl={target}
            onClose={() => setOpenPopover(!isOpenPopover)}
            anchorOrigin={ANCHOR_ORIGIN}
            transformOrigin={TRANSFORM_ORIGIN}>
            <div className={classes.filterItem}
                 onClick={redirectToChat}>
                Go to chat
            </div>
        </Popover>
    </Fragment>
};

export default withRouter(withStyles(styles)(PopoverSupport));
