import React from 'react';
import {withRouter} from "react-router-dom";
import {withStyles} from "@material-ui/core";
import PropTypes from "prop-types";

const styles = () => ({
    main: {
        padding: '50px 60px',
        background: '#F7FAFE',
        width: '100%',
        height: '100vh'
    },
    container: {
        background: '#ffffff',
        borderRadius: '10px',
        boxShadow: '4px 4px 29px rgba(19, 69, 186, 0.0855129)',
        width : '512px',
        height : '274px',
        margin: '0 auto',
        display: 'flex',
        padding: '15px'

    },
    innerContainer: {
        margin: 'auto',
        textAlign: 'center'
    },
    font: {
        fontFamily: 'Lato',
        fontStyle: 'normal'
    },
    title: {
        fontWeight: 'bold',
        lineHeight: 'normal',
        fontSize: '36px',
        color: '#3A3F62'
    },
    text: {
        fontWeight: 'normal',
        lineHeight: '25px',
        fontSize: '16px',
        color: '#727689'
    },
    emailText: {
        fontWeight: 'bold',
        lineHeight: '28px',
        fontSize: '18px',
        color: '#1658F3'
    }
});

class AfterSignUp extends React.Component {
    constructor(props) {
        super(props);
    }

    render(){
        let email;
        if(this.props.location.state) {
             email = this.props.location.state.email;
        }
        const {classes} = this.props;
        return (
            <div className={classes.main}>
                <div className={classes.container}>
                    <div className={classes.innerContainer}>
                        <h4 className={`${classes.title} ${classes.font}`}>You're almost done!</h4>
                        <p className={`${classes.text} ${classes.font}`}>We've sent a confirmation email
                            {email && <span> to
                                <span className={`${classes.emailText} ${classes.font}`}> {email}</span>
                            </span>}
                            <br/>Please check your inbox and finish the registration.</p>
                    </div>
                </div>
            </div>
        );
    }
}

AfterSignUp.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles, {withTheme: true})(AfterSignUp));
