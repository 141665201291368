import React from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import {withRouter} from "react-router-dom";

const styles = theme => ({
  input: {
    border: "none",
    outline: "none",
    width: '100%',
    borderRadius: "5px",
    fontSize: "14px",
    lineHeight: "19px",
    backgroundColor: "white",
    color: theme.palette.primary.main,
  },
  red: {
    border: '1px solid #FF624C',
    color: theme.palette.alerts.error,
    width: "max-content",
    borderRadius: "10px",
    display: "flex",
    justifyContent: 'space-between',
    backgroundColor: "white",
    marginRight: 10,
    padding: 10,
    maxHeight: 45,
  },
  inputHolder: {
    border: "1px solid #C7CAD6",
    borderRadius: "10px",
    display: "flex",
    justifyContent: 'space-between',
    backgroundColor: "white",
    marginRight: 10,
    padding: '10px 24px',
    maxHeight: 45,
    width: 320
  },
});

class InputContent extends React.Component {

  render() {
    const {classes, holderStyle} = this.props;
    return (
        <div
            style={holderStyle} onClick={this.props.onClick} className={classes.inputHolder}>
          {this.props.children}
        </div>
    )
  }
}

InputContent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(InputContent));