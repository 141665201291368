import React from "react";
import {withStyles} from "@material-ui/core";

import {styles} from "./languagesStyles";

const ActiveLanguagesListView = props => {
    const {children, title} = props;

    return(
        <div>
            <p>{title}</p>
            <hr/>
            {children}
        </div>
    )
};

export default withStyles(styles, {withTheme: true})(ActiveLanguagesListView);