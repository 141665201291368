import React from 'react';
import {withStyles} from '@material-ui/core';
import PropTypes from 'prop-types';
import {connect} from "react-redux";

import InteractionTimer from "../InteractionTimer/InteractionTimer";
import UserWebsite from "../UserWebsite/UserWebsite";

import {styles} from './styles';
import {CHAT_PLATFORM} from "../../../../constants/chatPlatform";

const UserName = (props) => {
    const {classes, user} = props;
    const isFromWhatsApp = user?.platform === CHAT_PLATFORM.WHATSAPP;
    const userWebsiteLocation = user.messages?.find((m) => m.websiteLocation !== null)?.websiteLocation;

    return (
        <div className={classes.userNameWrap}>
            <div className={classes.userName}>
                {user.firstName} {user.lastName}

                {isFromWhatsApp && user.userMessageDatetime && (
                    <InteractionTimer lastInteractionDate={user?.userMessageDatetime}/>
                )}
            </div>

            {userWebsiteLocation && (
                <UserWebsite url={userWebsiteLocation}/>
            )}
        </div>
    );
};

UserName.propTypes = {
    classes: PropTypes.object,
    user: PropTypes.object
};

const mapStateToProps = (state) => ({
    user: state.activeUser
});

export default withStyles(styles)(connect(mapStateToProps)(UserName));
