import React from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from "react-player";

import MessageWrap from "../MessageWrap/MessageWrap";

const VideoMessage = (props) => {
    const {url, timezone, isReceived} = props;

    return (
        <MessageWrap timezone={timezone} isReceived={isReceived}>
            <div>
                <ReactPlayer
                    width='328px'
                    height='150px'
                    url={url}
                    controls/>
            </div>
        </MessageWrap>
    );
};

VideoMessage.propTypes = {
    url: PropTypes.string,
    timezone: PropTypes.string,
    isReceived: PropTypes.bool
};

export default VideoMessage
