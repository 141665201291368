/**
 * Created by Admin on 25.05.2018.
 */
import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";

const styles = theme => ({
    primary: {
        padding: '10px 16px',
        color: theme.palette.primary.contrastText,
        background: theme.palette.primary.main,
        border: "none",
        outline: "none",
        cursor: "pointer",
        borderRadius: 10,
        lineHeight: 'normal',
        fontFamily: 'Lato',
        fontSize: 14,
        "&:hover": {},
        "&:disabled": {
            backgroundColor: theme.palette.primary.light
        }
    },
    secondary: {
        padding: '13px 28px',
        color: theme.palette.primary.main,
        background: theme.palette.primary.contrastText,
        borderColor: theme.palette.primary.main,
        border: "1px solid",
        outline: "none",
        cursor: "pointer",
        borderRadius: 10,
        lineHeight: 'normal',
        fontFamily: 'Lato',
        fontSize: 14,
        "&:hover": {},
        "&:disabled": {
            backgroundColor: theme.palette.primary.background
        }
    },
    default: {
        padding: '8px 16px',
        background: theme.palette.primary.contrastText,
        border: "1px solid",
        borderColor: theme.palette.default.gray,
        color: theme.palette.text.header,
        borderRadius: 10,
        fontSize: 14,
        lineHeight: 1,
        "&:hover": {
            cursor: 'pointer'
        },
    },
    noStyle: {
        color: theme.palette.text.header,
        textDecoration: 'underline',
        border: 'none',
        background: 'transparent',
        padding: '0',
        fontSize: 14,
        fontWeight: '600',
        "&:hover": {
            cursor: 'pointer'
        },
    },
    sizeSm: {
        padding: '8px 16px',
    },
    sizeLg: {
        padding: '14px 28px',
    },
    cancel: {
        backgroundColor: 'unset',
        outline: "none",
        cursor: "pointer",
        fontSize: 14,
        color: '#616581',
        padding: '8px 28px',
        border: 'none',
    },
    empty: {
        background: theme.palette.primary.contrastText,
        color: theme.palette.primary.main,
        border: "1px solid",
        borderColor: theme.palette.primary.main,
    },
});

class SubmitButton extends React.Component {

    render() {
        const {classes, type, size} = this.props;
        const classButton = `${type ? classes[type] : classes.primary} ${this.props.empty && classes.empty}`;
        const buttonSizeClass = size === 'sm' ? classes.sizeSm : size === 'lg' ? classes.sizeLg : '';

        return (
            <button disabled={this.props.disabled}
                    className={`${classButton} ${buttonSizeClass}`}
                    onClick={(e) => this.props.onClick && this.props.onClick(e)}
                    style={this.props.styles}>
                {this.props.title}
            </button>
        )
    }
}

SubmitButton.propTypes = {
    classes: PropTypes.object,
    styles: PropTypes.object,
    title: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    empty: PropTypes.bool,
    onClick: PropTypes.func,
};

export default withStyles(styles, {withTheme: true})(SubmitButton);
