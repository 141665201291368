import React from "react";
import {Icon404} from "../../uiKit/icons/Icons";
import {Redirect, withRouter} from "react-router-dom";
import {withStyles} from "@material-ui/core/styles/index";
import {HOME_PATH} from "../../configs";
import {styles} from './styles'

class PermissionDenied extends React.Component {
    state = {
        redirect: false,
    };

    render() {
        if (this.state.redirect) {
            return <Redirect to={HOME_PATH + "/bot/" + this.props.match.params.botId + "/dashboard"}/>
        }

        const {classes} = this.props;
        return (
            <div>
                <div className={classes.main}>
                    <div>
                        <Icon404/>
                    </div>
                    <div style={{maxWidth: 290, margin: '0 auto'}}>
                        <h2 className={classes.heading}>Page not found...</h2>
                        <p className={classes.paragraph}>
                            We're unable to find a page you're looking for.
                            Please go back to the Dashboard.</p>
                    </div>
                    <button className={classes.back}
                            onClick={() => this.setState({redirect: true})}

                    >
                        Back to Dashboard
                    </button>
                </div>
            </div>
        )
    }
}

export default withRouter(withStyles(styles)(PermissionDenied));

