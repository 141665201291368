import {alertError, createJsonHeaders, logoutOnRequestOrResponseJson} from "../../../api";
import {SUPPORT_USERS_SIZE} from "../../../constants/userSize";
import {BASE_URL} from "../../../configs";

export function getAssignedToMeSupportUsers(botId, adminId, searchName = '', offset = 0) {
    return fetch(`${BASE_URL}/support/assigned-to/${botId}?searchName=${searchName}&adminId=${adminId}`, {
        credentials: 'same-origin',
        headers: createJsonHeaders(),
        method: 'GET'
    }).then((response) => logoutOnRequestOrResponseJson(response))
        .catch(() => alertError('Sorry but something going wrong please ping support!', 5000));
}

export function getAssignedSupportUsers(botId, searchName = '', offset = 0) {
    return fetch(`${BASE_URL}/support/assigned/${botId}?searchName=${searchName}`, {
        credentials: 'same-origin',
        headers: createJsonHeaders(),
        method: 'GET'
    }).then((response) => logoutOnRequestOrResponseJson(response))
        .catch(() => alertError('Sorry but something going wrong please ping support!', 5000));
}

export function getNewRequestSupportUsers(botId, searchName = '', offset = 0) {
    return fetch(`${BASE_URL}/support/open/${botId}?searchName=${searchName}`, {
        credentials: 'same-origin',
        headers: createJsonHeaders(),
        method: 'GET'
    }).then((response) => logoutOnRequestOrResponseJson(response))
        .catch(() => alertError('Sorry but something going wrong please ping support!', 5000));
}

export function getExpiredRequestSupportUsers(botId, searchName = '', offset = 0) {
    return fetch(`${BASE_URL}/support/expired/${botId}?searchName=${searchName}`, {
        credentials: 'same-origin',
        headers: createJsonHeaders(),
        method: 'GET'
    }).then((response) => logoutOnRequestOrResponseJson(response))
        .catch(() => alertError('Sorry but something going wrong please ping support!', 5000));
}

export function getAllSupportUsers(botId, searchName = '', offset = 0) {
    return fetch(`${BASE_URL}/support/chatbot/${botId}?searchName=${searchName}&size=${SUPPORT_USERS_SIZE}&offset=${offset}`, {
        credentials: 'same-origin',
        headers: createJsonHeaders(),
        method: 'GET'
    }).then((response) => logoutOnRequestOrResponseJson(response))
        .catch(() => alertError('Sorry but something going wrong please ping support!', 5000));
}

export function getClosedSupports(botId, searchName = '', offset = 0) {
    return fetch(`${BASE_URL}/support/closed/${botId}?searchName=${searchName}&size=${SUPPORT_USERS_SIZE}&offset=${offset}`, {
        credentials: 'same-origin',
        headers: createJsonHeaders(),
        method: 'GET'
    }).then((response) => logoutOnRequestOrResponseJson(response))
        .catch(() => alertError('Sorry but something going wrong please ping support!', 5000));
}

export function updateSupportUser(user) {
    return fetch(`${BASE_URL}/support/user/${user.userId}`, {
        credentials: 'same-origin',
        headers: createJsonHeaders(),
        method: 'GET'
    }).then((response) => logoutOnRequestOrResponseJson(response))
        .catch(() => alertError('Sorry but something going wrong please ping support!', 5000));
}
