export const styles = () => ({
    selectButton: {
        cursor: 'pointer',
        borderRadius: 10,
        border: '1px solid #EBEBEF',
        color: '#616581',
        display: 'flex',
        alignItems: 'center',
        marginRight: 10,
        fontSize: 12,
        padding: '5px 7px',
        '& span': {
            paddingRight: 8
        }
    },
    popover: {
        width: 135,
        height: 125,
        borderRadius: 4,
        boxShadow: '0px 1px 5px rgba(19, 69, 186, 0.241904)',
        padding: '6px 0px'
    },
    divider: {
        width: '100%',
        borderBottom: '1px solid rgba(53, 64, 82, 0.1)'
    }
});
