import React from 'react';
import { withStyles } from "@material-ui/core";
import uuid from 'uuid';

const styles = theme => ({
  gradientBarWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  gradientBar: {
    display: 'flex',
    flexFlow: 'row nowrap',
    width: '100%',
    maxWidth: '340px',
    marginTop: 30
  },
  gradientBarColumn: {
    width: '20%',
    marginRight: 5,
    '&:last-child': {
      marginRight: 0
    }
  },
  gradientBarItem: {
    height: 10,
    width: '100%',
    marginBottom: 8
  },
  gradientBarItemText: {
    color: '#616581',
    fontSize: 12,
    lineHeight: 1,
    fontFamily: 'Lato, sans-serif',
    textAlign: 'right'
  }
});


const GradientBar = ({ color, step, classes, gridItemsCount }) => {
  if (!color) {
    return <div/>;
  }

  const {r, g, b} = color;
  const colors = [];

  for (let i = 0; i < gridItemsCount; i++) {
    colors.push({
      start: `rgba(${r}, ${g}, ${b}, ${0.2 * i})`,
      end: `rgba(${r}, ${g}, ${b}, ${0.2 * (i + 1)})`
    })
  }

  return (
    <div className={classes.gradientBarWrapper}>
      <div className={classes.gradientBar}>
        {
          colors.map((color, index) => {
            return (
              <div key={uuid()} className={classes.gradientBarColumn}>
                <div
                  style={{ background: `linear-gradient(90deg, ${color.start}, ${color.end})` }}
                  className={classes.gradientBarItem}
                />
                <div className={classes.gradientBarItemText}>
                  {(index + 1) * step}
                </div>
              </div>

            )
          })
        }
      </div>
    </div>
  )
};

export default withStyles(styles, { withTheme: true })(GradientBar);
