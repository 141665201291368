import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import Facebook from "./facebook/Facebook";
import TabTemplate from "../../../widget/components/TabTemplate";
import ChatbaseIntegrationTemplate from "./ChatbaseIntegrationTemplate";
import DialogflowIntegrationTemplate from "./DialogflowIntegrationTemplate";
import WhatsApp from "./whatsApp/WhatsApp";
import TwillioIntegration from "./twilioIntegration/TwilioIntegration";
import WitAiIntegrationTemplate from "./WitAiIntegrationTemplate";

const styles = theme => ({
    heading: {
        fontWeight: 'bold',
        fontSize: '24px',
        color: '#3A3F62',
        fontFamily: 'Lato, "sans-serif"',
        margin: '0 0 8px'
    },
    paragraph: {
        fontFamily: 'Lato',
        fontSize: 14,
        color: '#616581'
    },
    block: {
        borderBottom: '1px solid #EBEBEF',
        paddingBottom: 32
    },
    input: {
        border: "none",
        outline: "none",
        width: '100%',
        borderRadius: "5px",
        fontSize: "14px",
        lineHeight: "19px",
        backgroundColor: "white",
        color: '#1658F3',
    },
    inputHolder: {
        border: "1px solid #C7CAD6",
        borderRadius: "10px",
        display: "flex",
        justifyContent: 'space-between',
        backgroundColor: "white",
        marginRight: 10,
        padding: 10,
        maxHeight: 45,
        width: "400px"
    },
    btn: {
        marginTop: 16,
        cursor: 'pointer',
        border: '1px solid #5A98F7',
        boxSizing: 'border-box',
        borderRadius: 10,
        color: '#5A98F7',
        padding: '8px 16px',
        width: 'max-content'
    },
    btnDelete: {
        marginTop: 16,
        cursor: 'pointer',
        border: '1px solid rgba(97, 101, 129, 0.5)',
        boxSizing: 'border-box',
        borderRadius: 10,
        color: 'rgba(97, 101, 129, 0.5)',
        padding: '8px 16px',
        width: 'max-content'
    },
    modal: {
        padding: '40px 160px',
        margin: '15% auto', /* 15% from the top and centered */
        boxShadow: '4px 4px 29px rgba(19, 69, 186, 0.14)',
        borderRadius: 10,
        overflowY: 'auto',
        backgroundColor: 'white',
        width: 640
    },
    box: {
        display: 'flex',
        width: 664,
        marginTop: 22
    },
    circle: {
        background: 'rgba(90, 152, 247, 0.2)',
        height: 8,
        width: 12,
        marginTop: 6,
        borderRadius: 8,
        marginRight: 16
    },
    btnWhite: {
        background: '#ffffff',
        fontSize: '14px',
        fontWeight: '600',
        border: '1px solid #C7CAD6',
        color: '#3A3F62',
        borderRadius: '10px',
        padding: '8px 16px',
        cursor: 'pointer',
        fontFamily: 'Lato, "sans-serif"',
    },
    btnSimple: {
        color: '#3A3F62',
        textDecoration: 'underline',
        border: 'none',
        background: 'transparent',
        padding: '0',
        cursor: 'pointer',
        fontSize: '14px',
        fontWeight: '600'
    },
    container: {
        marginBottom: '26px'
    },
    itemContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        marginBottom: 100,
        width: 'calc(100% - 148px)', //148px because margin to the edge has to be 200px,
        // outer container has 12px padding, tabs container has 40px right margin
        ['@media (max-width:1380px)']: {
            width: 'calc(100% - 48px)',
        },
    },
    item: {
        backgroundColor: '#ffffff',
        boxShadow: '4px 4px 50px rgba(19, 69, 186, 0.25)',
        borderRadius: '10px',
        padding: '24px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        flexBasis: '30%'
    },
    itemDisabled: {
        opacity: '.6',
        cursor: 'not-allowed'
    },
    itemImg: {
        maxWidth: '48px',
        height: '48px'
    },
    itemTitle: {
        padding: '16px',
        borderBottom: '1px solid #EBEBEF',
        margin: '0 0 16px',
        fontFamily: 'Lato, sans-serif',
        fontSize: '16px',
        fontWeight: '300'
    },
    itemBtn: {
        color: '#1658F3',
        border: '1px solid #1658F3',
        borderRadius: '10px',
        padding: '8px 14px',
        fontSize: '14px',
        fontFamily: 'Lato, sans-serif',
        fontWeight: '300',
        cursor: 'pointer'
    },
    itemBtnDisabled: {
        cursor: 'not-allowed',
        color: '#C7CAD6',
        borderColor: '#C7CAD6'
    },
    topContainer: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    tabInner: {
        minHeight: 350,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    },
    tabInnerContainer: {
        width: '100%'
    },
    tabIcon: {
        maxWidth: 20
    },
    '@global':
        {
            '.Select-menu-outer':
                {
                    top: "100%!important",
                    bottom:
                        "auto!important",
                }
            ,
            "@media screen and (max-width:1300px)": {
                '.containerForItems': {
                    width: 'calc(100%-48px)'
                },
            },
            '.Select-placeholder':
                {
                    lineHeight: '43px',
                }
            ,
            '.error  .Select-placeholder':
                {
                    color: '#FF624C'
                }
            ,
            '.Select-input > input':
                {
                    lineHeight: '28px'
                }
            ,
            '.Select-value':
                {
                    paddingTop: 5
                }
            ,
            '.Select-control':
                {
                    height: 45
                }
            ,
            '.drop-up .Select-menu-outer':
                {
                    bottom: '100 !important',
                    top:
                        'auto !important'
                }
            ,
            '.drop-up .Select-placeholder':
                {
                    color: '#1658F3'
                }
            ,
            '.drop-up .Select-value span':
                {
                    color: '#1658F3 !important'
                }
            ,
            '.link:hover': {
                textDecoration: 'none',
                color: '#3A3F62'
            }
        }
    ,
});

class NewIntegrations extends React.Component {
    handleChangeExpansion = (expanded) => {
        this.setState({
            expanded: expanded === this.state.expanded ? null : expanded,
        });
    };

    constructor(props) {
        super(props);

        this.state = {
            open: false,
            expanded: null,
        };
    }

    render() {
        const {classes} = this.props;
        const {expanded} = this.state;

        return (
            <div>
                <div className={classes.topContainer}>
                    <h3 className={classes.heading}>Integrations</h3>
                </div>
                <p className={classes.paragraph} style={{marginBottom: 26}}>
                    Choose a platform you would like to connect your chatbot to
                </p>

                <div className={` ${classes.itemContainer} containerForItems`}>
                    <Facebook
                        derivedClasses={classes}
                        expanded={expanded === 'fb'}
                        handleChangeExpansion={() => this.handleChangeExpansion('fb')}
                    />
					<WhatsApp
						derivedClasses={classes}
						expanded={expanded === 'wa'}
						handleChangeExpansion={() => this.handleChangeExpansion('wa')}
					/>
                    <TabTemplate
                        tab={{
                            name: "DialogFlow",
                            icon: <img src='/images/platform/dialogflow.svg' alt="" className={classes.tabIcon}/>,
                            description: 'Connect the DialogFlow agent to be able to make your bot smarter by setting the NLP'
                        }}
                        expanded={expanded === 'df'}
                        onChange={() => this.handleChangeExpansion('df')}>
                        <DialogflowIntegrationTemplate derivedClasses={classes}/>
                    </TabTemplate>
                    <TabTemplate
                        tab={{
                            name: 'WitAi',
                            icon: <img src='/images/platform/wit.ai.png' alt="" className={classes.tabIcon}/>,
                            description: 'Connect the WitAi'
                        }}
                        expanded={expanded === 'witAi'}
                        onChange={() => this.handleChangeExpansion('witAi')}
                    >
                        <WitAiIntegrationTemplate derivedClasses={classes}/>
                    </TabTemplate>
                     <TabTemplate
                         tab={{
                           name: "Chatbase",
                           icon: <img src='/images/platform/chatbase-icon.svg' alt="" className={classes.tabIcon}/>,
                           description: 'Connect the Chatbase'
                         }}
                         expanded={expanded === 'cb'}
                         onChange={() => this.handleChangeExpansion('cb')}>
                         <ChatbaseIntegrationTemplate derivedClasses={classes}/>
                    </TabTemplate>
                    <TabTemplate
                        tab={{
                            name: "Twilio",
                            icon: <img src='/images/platform/twillio-icon.png' alt="" className={classes.tabIcon}/>,
                            description: 'Connect your Twillio agent to be able to chat with your customers via SMS'
                        }}
                        expanded={expanded === 'twillio'}
                        onChange={() => this.handleChangeExpansion('twillio')}>
                        <TwillioIntegration />
                    </TabTemplate>
                </div>
            </div>
        )
    }
}

NewIntegrations.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles, {withTheme: true})(NewIntegrations);
