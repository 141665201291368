export const styles = () => ({
    container: {
        display: 'flex',
        width: '100%',
        height: 72,
        borderBottom: '1px solid rgba(53, 64, 82, 0.1)'
    },
    tab: {
        width: '50%',
        fontSize: 14,
        color: '#616581',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer'
    },
    activeTab: {
        background: '#5A98F7',
        color: '#FFFFFF'
    }
});
