import {CLEAR_ATOM, SAVE_ATOM} from '../actions/atom'

const atom = (state = [], action) => {
    switch (action.type) {
        case SAVE_ATOM:
            return action.atom;
        case CLEAR_ATOM:
            return null;
        default:
            return state;//todo: was null, added verification to atom.js if atom.length != 0, ATOM does not opening after returning to the flows
    }
};

export default atom
