import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';

const styles = theme => ({
  input: {
    border: 'none',
    outline: 'none',
    width: '100%',
    borderRadius: '5px',
    fontSize: '14px',
    lineHeight: '19px',
    backgroundColor: 'white',
    padding: '4px 0',
    color: theme.palette.text.header
  },
  red: {
    border: '1px solid #FF624C',
    color: '#FF624C',
    width: 'max-content',
    borderRadius: '10px',
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: 'white',
    marginRight: 10,
    padding: 10,
    maxHeight: 45
  },
  inputHolder: {
    border: '1px solid #C7CAD6',
    borderRadius: '10px',
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: 'white',
    marginRight: 10,
    padding: '10px 24px',
    maxHeight: 45,
    width: 400
  }
});

class InputHolder extends React.Component {
  render() {
    const {
      classes, defaultValue, placeholder, holderStyle, inputStyle, maxLength, rtl
    } = this.props;
    return (
        <div style={holderStyle} className={classes.inputHolder}>
          <input
              className={classes.input}
              style={inputStyle}
              maxLength={maxLength>0 ? maxLength : 255}
              defaultValue={defaultValue}
              placeholder={placeholder}
              type={this.props.type ? this.props.type : 'text' }
              onChange={e => this.props.onChange(e)}
              onKeyPress={(e) => {
                this.props.handleKeyPress && this.props.handleKeyPress(e);
              }}
              onBlur={(e) => {
                this.props.onBlur && this.props.onBlur(e);
              }}
              dir={rtl && 'rtl'}
          />
        </div>
    );
  }
}

InputHolder.propTypes = {
  classes: PropTypes.object.isRequired,
  placeholder: PropTypes.string.isRequired,
  maxLength:PropTypes.number.isRequired
};

export default withRouter(withStyles(styles)(InputHolder));