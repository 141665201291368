import React from 'react';
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";
import ReactTooltip from 'react-tooltip';

import './DateControl.css';
import ButtonCsv from "../ButtonCSV";

const styles = theme => ({
    container: {
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'space-between',
        alignItems: 'center',
        '&span': {
            verticalAlign: 'middle'
        }
    },
    text: {
        fontSize: 12,
        lineHeight: 1,
        fontFamily: 'Lato, sans-serif',
        margin: '0',
        color: '#3A3F62',
    },
    titleWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center'
    },
    title: {
        fontSize: 18,
        fontWeight: 700,
        marginRight: 16
    },
    number: {
        fontSize: 18,
        lineHeight: 1,
        color: '#1658F3',
        fontWeight: 700,
        marginLeft: 8,
    },
    stats: {
        textAlign: 'right',
        verticalAlign: 'middle',
        '&>p': {
            display: 'inline-flex',
            alignItems: 'center',
            margin: 0,
            '&:first-child': {
                marginRight: 16
            }
        }
    },
    tooltip: {
        maxWidth: '80%',
        width: '400px',
        border: '1px solid #EBEBEF',
        boxSizing: 'border-box',
        boxShadow: '4px 4px 29px rgba(19, 69, 186, 0.0855129)',
        borderRadius: '10px',
        zIndex: 9999,
        opacity: 1
    },
});

const buildTooltip = (classes, title, tooltipText) => (
    <ReactTooltip
        className={classes.tooltip}
        place="bottom"
        type="light"
        effect="solid"
        id={`tooltip-${title}`}
    >
        {tooltipText}
    </ReactTooltip>
);



const HeaderStats = ({ classes, title, tooltipText, users, usersTitle, totalUsers, headerStats, fileName, headersCsv, dataCsv, hourly, startDate, endDate }) => (
    <div className={classes.container}>
        <span className={classes.titleWrapper}>
            <p className={`${classes.text} ${classes.title}`}>
                {title}
                <span>
                    &nbsp;<img data-tip data-for={`tooltip-${title}`} src="/images/platform/information-button.svg" alt="info icon"/>
                </span>
            </p>
            {dataCsv && <ButtonCsv data={dataCsv}
                                   fileName={fileName}
                                   headers={headersCsv}
                                   hourly={hourly}
                                   startDate={startDate}
                                   endDate={endDate} />}
        </span>
        {buildTooltip(classes, title, tooltipText)}
        {
            headerStats && (
                <div className={`${classes.text} ${classes.stats}`}>
                    <p><span>{usersTitle}</span><span className={classes.number}>{users}</span></p>
                    <p>Total users<span className={classes.number}>{totalUsers}</span></p>
                </div>
            )
        }
    </div>
);

HeaderStats.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles, { withTheme: true })(HeaderStats));