import React from 'react';

import {withStyles} from "@material-ui/core/styles";

import {styles} from '../slider/ArrowIconsStyles'

const SendIcon = (props) => {
    const {color} = props;
    return (
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M1 10.4737L21 1L11.5263 21L9.42105 12.5789L1 10.4737V10.4737Z" stroke={color || "#1658F3"}
                  strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
};

export default withStyles(styles)(SendIcon);
