import React from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {withRouter} from 'react-router-dom';
import {withStyles} from "@material-ui/core";
import {updateWitAiConfigsRequest} from "../../../home/api/bots";
import SubmitButton from "../../../../uiKit/buttons/SubmitButton";
import Loader from "../../../../uiKit/loaders/loader";
import {alertSuccess} from "../../../../api";

const styles = theme => ({
    mb16: {
        marginBottom: 16
    },
    mb32: {
        marginBottom: 32
    },
    description: {
        fontSize: 12,
        color: '#B0B2C0',
        fontFamily: 'Lato, sans-serif',
        margin: '4px 0 0'
    },
    inputTitle: {
        marginTop: 3
    },
    errorAlert: {
        color: theme.palette.alerts.error,
        marginBottom: '-10px',
        fontSize: 12
    }
});

class WitAiIntegrationTemplate extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isConnected: false,
            showLoader: false,
            isError: false,
            errorMessage: "",
            witAiConfigs: {}
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let isConnected = prevState.isConnected;

        if (!prevState.isConnected && nextProps.activeBot.witAiConfigs) {
            const {witAiConfigs} = nextProps.activeBot;
            const newIsConnected = witAiConfigs.isConnected;

            if (isConnected !== newIsConnected) {
                return {witAiConfigs, isConnected: newIsConnected};
            }
        }

        return null;
    }

    onSubmit = () => {
        if (this.state.isConnected) {
            alertSuccess("Wit.Ai already connected!")
        } else {
            this.setState({
                showLoader: true
            })

            const bot = {...this.props.activeBot};
            updateWitAiConfigsRequest(bot.id, this.successCallback, this.errorCallback);
        }
    };

    successCallback = () => {
        this.setState({
            isError: false,
            isConnected: true,
            showLoader: false,
        })
    };

    errorCallback = (errorMessage) => {
        this.setState({
            isError: true,
            isConnected: false,
            showLoader: false,
            errorMessage: errorMessage,
        })
    };

    handleChange = (value, name) => {
        let witAiConfigs = {...this.state.witAiConfigs};
        witAiConfigs[name] = value.trim();
        this.setState({
            witAiConfigs: {...witAiConfigs},
            isError: false
        })
    };


    render() {
        const {classes, derivedClasses, buttonStyles} = this.props;
        const {isError, errorMessage, showLoader, isConnected} = this.state;

        return (
            <div>
                {
                    showLoader ? <Loader/>
                        :
                        <div className={`${derivedClasses.tabInnerContainer} ${classes.mb16}`}>
                            <div className={classes.mb32}>
                                {
                                    isError && <p className={classes.errorAlert}>{`${errorMessage}`}</p>
                                }
                            </div>
                            <div className={derivedClasses.buttonHolder}>
                                <SubmitButton
                                    title={isConnected ? 'Connected' : 'Connect'}
                                    type={isConnected ? buttonStyles.type : 'secondary'}
                                    size="sm"
                                    onClick={this.onSubmit}
                                />
                            </div>
                        </div>
                }
            </div>
        );
    }
}

WitAiIntegrationTemplate.defaultProps = {
    derivedClasses: {},
    classes: {},
    activeBot: {},
    buttonStyles: {}
};

WitAiIntegrationTemplate.propTypes = {
    classes: PropTypes.object,
    derivedClasses: PropTypes.object,
    activeBot: PropTypes.object,
    buttonStyles: PropTypes.object,
};

const mapStateToProps = (state) => (
    {
        activeBot: state.activeBot,
    });

export default withRouter(withStyles(styles, {withTheme: true})(connect(mapStateToProps)(WitAiIntegrationTemplate)));
