import {SAVE_NLP_REQUESTS, UPDATE_NLP_REQUEST, CLEAR_NLP_REQUESTS} from "../actions/nlpRequests";

const nlpRequests = (state = null, action) => {
  switch (action.type) {
    case SAVE_NLP_REQUESTS:
      return action.requests;

    case UPDATE_NLP_REQUEST:
      let nlpRequests = JSON.parse(JSON.stringify(state));
      nlpRequests.data.forEach((element, index) => {
        if (element.id === action.request.id) {
          nlpRequests.data[index] = action.request;
        }
      });
      nlpRequests.data.filter(x => !x.trained);
      return nlpRequests;

    case CLEAR_NLP_REQUESTS:
      return null;

    default:
      return state;
  }
};

export default nlpRequests
