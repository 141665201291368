/**
 * Created by Admin on 13.02.2018.
 */
import React from "react";
import MessageQuickReplies from "./MessageQuickReplies";
import {IconButton, Tooltip, withStyles} from "@material-ui/core";
import {Warning} from "@material-ui/icons";
import MessageButton from "./MessageButton";
import {connect} from "react-redux";
import {USE_MESSAGE_KEYS} from "../../../../configs";
import {TrashIcon} from '../../../../uiKit/icons/Icons.js'
import {withRouter} from "react-router-dom";
import ParamsOld from "../../../../uiKit/inputs/ParamsOld";
import {PlusIcon} from "../../../../uiKit/icons/Icons";


const styles = theme => ({
    "@global": {
        ".message:hover .delete-template": {
            display: 'block'
        },
        ".message": {
            position: 'relative'
        },
        ".delete-template": {
            left: '228px',
            top: '0px',
            position: 'absolute',
            display: 'none',
            transition: 'all 0.5s ease'
        },
        ".trashIcon:hover svg path": {
            stroke: '#1658F3'
        },
        ".add-button": {
            background: '#5A98F7',
            color: 'white'
        },
        ".add-button:hover": {
            background: 'white',
            color: '#5A98F7'
        }
    },
    textFieldsWrap: {
        display: 'flex',
        overflow: 'scroll',
        alignItems: 'center',
        cursor: 'pointer',
        '&::-webkit-scrollbar': {
            height: 6
        },
        '&::-webkit-scrollbar-thumb': {
            opacity: 0.6,
            backgroundColor: 'rgba(99, 99, 99, 0.2)'
        },
    },
    keyInput: {
        width: '100%',
        border: 'none',
        borderRadius: 10,
        outline: 'none',
        height: 40,
        fontSize: 14,
        padding: '0px 24px'
    },
    plusIconWrap: {
        minWidth: 250,
        height: 120,
        marginLeft: 10,
        border: '1px solid #e5e5e5',
        borderRadius: 10,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer'
    },
    deleteText: {
        background: 'white',
        border: '1px solid #EBEBEF',
        boxShadow: '0px 3px 6px #EBEBEF',
        width: 40,
        height: 40,
    },
    deleteIconWrap: {
        position: 'absolute',
        right: -19,
        display: 'none',
        marginTop: -20,
        zIndex: 100
    },
    textWrap: {
        minWidth: 250,
        marginLeft: 10,
        position: 'relative',
        '&:hover': {
            '& i': {
                display: 'block'
            }
        }
    }
});

class TextMessage extends React.Component {
    state = {};

    handleChangeText(index, value) {
        let newMessage = JSON.parse(JSON.stringify(this.props.message));
        newMessage.texts[index] = value;
        this.props.updateMessage(newMessage);
    };

    handleChangeName(value) {
        let newMessage = JSON.parse(JSON.stringify(this.props.message));
        newMessage.name = value;
        this.props.updateMessage(newMessage);
    }

    updateBtn(btn, btnIndex) {
        let newMessage = JSON.parse(JSON.stringify(this.props.message));
        newMessage.buttons[btnIndex] = btn;
        this.props.updateMessage(newMessage);
    }

    deleteButton(btnIndex) {
        let newMessage = JSON.parse(JSON.stringify(this.props.message));
        newMessage.buttons.splice(btnIndex, 1);
        this.props.updateMessage(newMessage);
    }

    addNewButton() {
        let newMessage = JSON.parse(JSON.stringify(this.props.message));
        if (newMessage.buttons == null) {
            newMessage.buttons = [];
        }
        newMessage.buttons.push({
            type: "postback",
            title: '',
            payload: '',
        });
        this.props.updateMessage(newMessage);
    }

    addAddTextField = () => {
        let newMessage = JSON.parse(JSON.stringify(this.props.message));
        newMessage.texts.push('');
        this.props.updateMessage(newMessage);
    };

    handleDeleteText = (index) => {
        let newMessage = JSON.parse(JSON.stringify(this.props.message));
        newMessage.texts.splice(index, 1);
        this.props.updateMessage(newMessage);
    };

    render() {
        const {classes, rtl} = this.props;
        return (
            <div style={{width: '100%'}}>
                <div className={classes.textFieldsWrap}>
                    <div className="message"
                         style={{textAlign: "left", minWidth: 250, margin: '0px 0px 10px', maxWidth: 250}}>
                        {this.props.onDelete && !this.props.message.isHardCoded && <div className="delete-template">
                            <IconButton className='trashIcon' style={{
                                background: 'white', border: '1px solid #EBEBEF',
                                boxShadow: '0px 3px 6px #EBEBEF', width: 40, height: 40
                            }} aria-label="Delete" onClick={() => this.props.onDelete()}>
                                <TrashIcon width='20px' height='20px' color='#B0B2C0'/>
                            </IconButton>
                        </div>}
                        {this.props.message.isHardCoded &&
                        <Tooltip id="tooltip-bottom-start"
                                 title="This message is being used in code. Don't change the name!"
                                 placement="bottom-start"
                                 style={{margin: 10, color: "#f44336"}}>
                            <Warning style={{marginLeft: 15}}/>
                        </Tooltip>}
                        {USE_MESSAGE_KEYS
                        && <input value={this.props.message.name}
                                  placeholder="Message key"
                                  className={classes.keyInput}
                                  onChange={(event) => this.handleChangeName(event.target.value)}/>}
                        <ParamsOld
                            title="Text message"
                            onChange={(value) => this.handleChangeText(0, value)}
                            value={this.props.message.texts[0]}
                            placeholder="Input text..."
                            params={this.props.params}
                            disableBorderRadius={true}
                            rtl={rtl}
                        />
                        <div className="rep-btns-container">
                            {this.props.message.buttons != null && this.props.message.buttons.map((btn, btnIndex) =>
                                <MessageButton btn={btn} key={btnIndex}
                                               last={btnIndex === 2}
                                               rtl={rtl}
                                               onUpdate={(btn) => this.updateBtn(btn, btnIndex)}
                                               onDelete={() => this.deleteButton(btnIndex)}
                                />
                            )}
                            {(this.props.message.buttons == null || this.props.message.buttons.length < 3) &&
                            <div className="add-button" onClick={() => this.addNewButton()}>
                                Add button
                            </div>}
                        </div>
                    </div>
                    {this.props.message.texts
                        .filter((element, index) => index !== 0)
                        .map((elem, index) => (
                            <div className={classes.textWrap}>
                                <i className={classes.deleteIconWrap}
                                   onClick={() => this.handleDeleteText(index + 1)}>
                                    <IconButton className={classes.deleteText}
                                                aria-label="Delete">
                                        <TrashIcon width='20px'
                                                   height='20px'
                                                   color='#B0B2C0'/>
                                    </IconButton>
                                </i>
                                <ParamsOld title="Text message"
                                           onChange={(value) => this.handleChangeText(index + 1, value)}
                                           value={this.props.message.texts[index + 1]}
                                           placeholder="Input text..."
                                           params={this.props.params}/>
                            </div>
                        ))}
                    <div className={classes.plusIconWrap} onClick={this.addAddTextField}>
                        <PlusIcon width='20px' height='20px' color='grey'/>
                    </div>
                </div>
                <MessageQuickReplies message={this.props.message}
                                     updateMessage={(message) => this.props.updateMessage(message)}
                                     rtl={rtl}/>
            </div>
        )
    }
}

const mapStateToProps = (state) => (
    {
        params: state.params
    });

export default withRouter(withStyles(styles, {withTheme: true})(connect(mapStateToProps)(TextMessage)));
