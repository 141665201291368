export const styles = () => ({
    container: {
        width: '398px'
    },
    header: {
        height: 56,
        borderBottom: '1px solid rgba(53, 64, 82, 0.1)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    selectButton: {
        cursor: 'pointer',
        borderRadius: 10,
        border: '1px solid #EBEBEF',
        color: '#616581',
        display: 'flex',
        alignItems: 'center',
        marginRight: 10,
        fontSize: 12,
        padding: '5px 7px',
        '& span': {
            paddingRight: 8
        }
    },
    commentsContainer: {
        display: 'flex',
        flexDirection: 'column',
        padding: '0px 38px',
        height: 440,
        overflow: 'auto'
    },
    loaderContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: 440,
        justifyContent:'center'
    }
});
