import React from 'react';
import {withStyles} from '@material-ui/core';
import PropTypes from 'prop-types';

import {styles} from './styles';

const MessageWrap = (props) => {
    const {classes, isReceived, timezone, children} = props;

    return (
        <div className={isReceived ? classes.containerReceived : classes.containerSend}>
            {timezone && <span className={classes.timeZone}>
                {timezone}
            </span>}
            {children}
        </div>
    );
};

MessageWrap.propTypes = {
    classes: PropTypes.object,
    isReceived: PropTypes.bool,
    timezone: PropTypes.string,
    children: PropTypes.object
};

export default withStyles(styles)(MessageWrap);
