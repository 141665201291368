import React, { useEffect } from "react";
import {withStyles} from '@material-ui/core';

import Heading from "../../../../uiKit/texts/Heading";
import { PlusIcon } from "../../../../uiKit/icons/Icons";

import Attribute from "./Attribute";

import { styles } from "./styles";
import { useAttributes } from "./useAttributes";

const Attributes = props => {
  const { classes } = props;
  const botID = props.match.params.botId;
  const {
    attributes,
    getAttributes,
    setAttribute,
    deleteAttribute,
    createAttribute,
  } = useAttributes(botID);

  useEffect(() => {
    getAttributes()
  }, [])

  return (
    <div style={{maxWidth: 640}}>
      <Heading>Attributes</Heading>
      <p className={classes.paragraph}>
        Set default values for the attributes. Use them to quickly insert your brand's info into a chatbot.
      </p>
      <div className={classes.attributes}>
        <div className={classes.titlesColumns}>
          <div className={classes.column}>
            <p>Name</p>
          </div>
          <div className={classes.column}>
            <p>Value</p>
          </div>
        </div>
        {attributes.length > 0 && attributes.map((attribute, index) => (
          <Attribute
            key={index}
            index={index}
            attribute={attribute}
            setAttribute={setAttribute}
            deleteAttribute={deleteAttribute}
          />
        ))}
        <button className={classes.addNew} onClick={createAttribute}>
          <PlusIcon height='14' width='14' color='#1658F3'/> Add attribute
        </button>
      </div>
    </div>
  )
}

export default withStyles(styles, {withTheme: true})(Attributes);
