/**
 * Created by Admin on 20.02.2018.
 */
import React from "react";
import {withStyles} from "@material-ui/core";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import Modal from "../../../uiKit/Modal";
import {PlusIcon} from "../../../uiKit/icons/Icons";
import NewBotModal from "./NewBotModal";

const styles = theme => ({
    item: {
        width: "250px",
        height: "170px",
        background: 'linear-gradient(325.02deg, #5A98F7 0%, #1658F3 100%)',
        margin: "10px",
        boxShadow: "4px 4px 29px rgba(19, 69, 186, 0.0855129)",
        borderRadius: 10,
        fontFamily: 'Lato, sans-serif',
        fontWeight: 'bold',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexFlow: 'column nowrap',
        cursor: "pointer",
    },
    itemText: {
        margin: 0,
        maxWidth: '100%',
        color: theme.palette.primary.contrastText,
    },
});

class CreateNewBot extends React.Component {
    state = {
        open: false,
    };

    handleOpen = () => {
        this.setState({open: true});
    };

    handleClose = () => {
        this.setState({open: false})
    };

    render() {
        const {classes} = this.props;
        return (
            <div>
                <div className={classes.item} onClick={this.handleOpen}>
                    <div>
                        <PlusIcon width='15' height="15" color='white'/>
                    </div>
                    <p className={classes.itemText}>Create a new bot</p>
                </div>

                <Modal
                    open={this.state.open}
                    onClose={this.handleClose}
                    classes={{paper: classes.paper}}
                    title={"Create new chatbot"}
                >
                    <NewBotModal
                        new={true}
                        buttonName="Create"
                        botName=""
                        onClose={this.handleClose}
                    />
                </Modal>
            </div>
        );
    };
}

const mapStateToProps = (state) => (
    {
        bots: state.bots,
    });

export default withRouter(withStyles(styles)(connect(mapStateToProps)(CreateNewBot)));
