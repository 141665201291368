import {ADD_NEW_FLOW, SAVE_FLOWS} from "../actions/flows";

const flows = (state = [], action) => {
    let flows;
    switch (action.type) {
        case SAVE_FLOWS:
            return action.flows;
        case ADD_NEW_FLOW:
            flows = [...state];
            flows.push(action.flow);
            return flows;
        default:
            return state;
    }
};

export default flows

