import React from "react";
import {withStyles} from "@material-ui/core";
import PropTypes from "prop-types";
import Modal from "@material-ui/core/Modal";
import Close from "@material-ui/icons/Close";

export function getModalStyle(style) {
    let top, left, overflowY;

    if (style) {
        top = style.top || 50;
        left = style.left || 50;
        overflowY = style.overflowY || 'auto';
    } else {
        top = 50;
        left = 50;
        overflowY = 'auto'
    }

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
        overflowY: `${overflowY}`,
        maxHeight: "90%",
    };
}

const styles = theme => ({
    paper: {
        position: 'absolute',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: "30px 50px 24px",
        outline: "none",
        width: 600,
        borderRadius: 10,
    },
    title: {
        lineHeight: 'normal',
        fontSize: 18,
        color: theme.palette.primary.headerText,
        textAlign: 'center'
    },
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    bot: {
        color: "#b0b0b0!important",
    },
    flex: {},
    container: {},
    menuButton: {},
    loader: {
        position: 'absolute',
        left: '50%',
        marginLeft: '-35px',
        top: '40%',
        zIndex: 9999
    },
    buttonHolder: {
        marginTop: 40,
        display: "flex",
        justifyContent: "flex-end",
    },
    btnClose: {
        position: 'absolute',
        right: '24px',
        top: '24px',
        color: '#B0B2C0',
        cursor: 'pointer'
    },
});

class CustomizedModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        const {classes, style} = this.props;
        return (
            <div>
                <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={this.props.open}
                    disableBackdropClick={this.props.disableClose}
                    onClose={this.props.onClose}
                    style={{overflowY: 'auto'}}
                >
                    <div style={getModalStyle(style)} className={classes.paper}>
                        <Close className={classes.btnClose} onClick={this.props.onClose}/>
                        {this.props.title && <h2 className={classes.title}>{this.props.title}</h2>}
                        {this.props.children}
                    </div>
                </Modal>
            </div>
        )
    }
}

CustomizedModal.propTypes = {
    classes: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    title: PropTypes.string,
};

export default withStyles(styles)(CustomizedModal);
