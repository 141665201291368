import React, {Component} from "react";
import {Link, Redirect} from "react-router-dom";
import {withStyles} from '@material-ui/core/styles';
import {checkIfEmailIsRegistered, getBotInviteInfo, signUpInvitedUser, signUpUser} from "../api/admin";
import base64 from "base-64";
import Button from './Button'
import Input from './Input'
import PasswordInput from './PasswordInput'
import {isStringEmpty} from "../../../helpers/isStringEmpty";
import {emailValidation} from "../../../helpers/emailValidation";

const styles = theme => ({
  "@global": {
    '@media (max-width: 575px)': {
      demoForm: {
        textAlign: 'center',
        maxWidth: 'unset',
        flexBasis: '90%',
        margin: 'auto'
      }
    },
  }
});

class SignUpForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      username: "",
      email: "",
      password: "",
      passwordConfirm: "",
      redirectToLogin: false,
      redirectToError: false,
      redirectToUsedLink: false,
      redirectToConfirmEmail: false,
      inputNameError: false,
      inputEmailError: false,
      inputEmailIsRegisteredError: false,
      inputPasswordError: false,
      inputPasswordConfirmError: false,
      hidePassword: true,
      hidePasswordConfirm: true
    };
  };

  componentDidMount() {
    if (this.props.invited) {
      this.processInviteCode();
    }
  }

  handleChange = (event, state, error) => {
    this.setState({[state]: event.target.value});
    this.setState({[error]: !event.target.checkValidity()});
  };

  validatePasswordConfirm = (password, passwordConfirm) => {
    if (!isStringEmpty(passwordConfirm)) {
      this.setState({
          passwordConfirm: passwordConfirm,
          inputPasswordConfirmError: (password !== passwordConfirm)
      });
    }
  };

  handleChangeEmail = (e) => {
    let email = e.target.value;
    this.setState({
      email: email,
      inputEmailIsRegisteredError: false,
      inputEmailError: !emailValidation(email)
    });
  };

  togglePassword = (e, state) => {
    e.preventDefault();
    this.setState({[state]: !this.state[state]});
  };

  handleSubmitRegister = () => {
    // Check whether this email isn't already registered
    this.checkIfEmailIsRegistered(() => this.submitRegister());
  };

  submitRegister = () => {
    const invited = this.props.invited;
    if (!this.isFormValid()) {
      return;
    }

    let encodedPassword = base64.encode(this.state.password);
    let user = {
      name: this.state.username,
      password: encodedPassword,
    };

    if (invited) {
      this.signUpInvitedUser(user);
    } else {
      this.signUpUser(user);
    }
  };

  checkIfEmailIsRegistered = (callback) => {
    checkIfEmailIsRegistered(this.state.email)
        .then((response) => {
          return response.json().then((body) => {
            this.setState({inputEmailIsRegisteredError: body.emailIsRegistered});
            return body;
          })
        }).then((body) => {
      if(!body.emailIsRegistered){
        callback();
      }
    });
  };

  // Check whether inputs aren't empty and there's no any error
  isFormValid = () => {
    this.checkEmptyString('inputNameError', 'username');
    this.checkEmptyString('inputEmailError', 'email');
    this.checkEmptyString('inputPasswordError', 'password');
    this.checkEmptyString('inputPasswordConfirmError', 'passwordConfirm');
    return !(this.state.inputNameError || this.state.inputEmailError || this.state.inputEmailIsRegisteredError ||
        this.state.inputPasswordError || this.state.inputPasswordConfirmError);
  };

  checkEmptyString = (errorState, state) => {
    // If there's no any other error with this input, check if it's not empty
    if(!this.state[errorState]){
      this.setState({[errorState]: isStringEmpty(this.state[state])})
    }
  };

  signUpInvitedUser(user) {
    user.code = this.props.match.params.code;            // When a user is invited, add `code` to the request body
    signUpInvitedUser(user).then((response) => {
      if (response.ok) {
        this.setState({redirectToLogin: true});
      }
    })
  }

  signUpUser(user){
    user.email = this.state.email;                      // When a user isn't invited, add `email` to the request body
    signUpUser(user).then((response) => {
      if(response.ok) {
        this.setState({redirectToConfirmEmail: true});
      }
    })
  }

  processInviteCode() {
    let code = this.props.match.params.code;
    getBotInviteInfo(code).then((response) => {
      if (response.status === 400) {
        this.setState({redirectToError: true});
      } else if (response.status === 404) {
        this.setState({redirectToUsedLink: true});
      } else if (response.ok) {
        return response.json();
      }
    }).then((json) => {
      this.setState({email: json.email});
    })
  }

  render() {
    const invited = this.props.invited;
    const classes = {...this.props.classes, ...this.props.styles}; // Use styles from this file and styles from props
    const {redirectToLogin, redirectToError, redirectToUsedLink, redirectToConfirmEmail} = this.state;
    if (redirectToLogin) {
      return <Redirect to='/login'/>;
    }
    if (redirectToError || redirectToUsedLink) {
      return <Redirect to='/unavailable'/>
    }
    // After signing up, redirect to "confirm your email" page
    if (redirectToConfirmEmail) {
      return <Redirect to={{
        pathname: '/sign-up/confirm',
        state: {email: this.state.email}
      }}/>
    }
    return (
        <main className={classes.main}>
          <div className={`${classes.demoForm} animated zoomIn faster`}>
            <h4 className={classes.formTitle}>Sign up</h4>
            <div className={classes.demoDiv}>
                <Input id="name"
                       type="text"
                       required={true}
                       placeholder="Type your name and last name"
                       label="Full name"
                       errorLabel="Enter your name"
                       hasError={this.state.inputNameError}
                       onChange={(e) => this.handleChange(e, 'username', 'inputNameError')}/>

                <Input id="email"
                       type="email"
                       required={true}
                       placeholder="Enter your email"
                       label="Email"
                       errorLabel={this.state.inputEmailError
                               ? "Enter correct email"
                               : this.state.inputEmailIsRegisteredError
                                   ? "Email is in use"
                                   : ""
                       }
                       defaultValue={this.state.email}
                       disabled={invited}
                       onBlur={this.handleChangeEmail}
                       hasError={this.state.inputEmailError || this.state.inputEmailIsRegisteredError}/>

                <PasswordInput id="password"
                               placeholder="Type a password"
                               label="Create a password"
                               errorLabel="Password is too short"
                               minLength={8}
                               onChange={(e) => {
                                   this.handleChange(e, 'password', 'inputPasswordError');
                                   this.validatePasswordConfirm(e.target.value, this.state.passwordConfirm);
                               }}
                               hasError={this.state.inputPasswordError}/>

                <PasswordInput id="passwordConfirm"
                               placeholder="Type a password"
                               label="Confirm your password"
                               errorLabel="Passwords don't match"
                               onChange={(e) => this.validatePasswordConfirm(this.state.password, e.target.value)}
                               hasError={this.state.inputPasswordConfirmError}/>
            </div>
            <Button type="submit"
                    title="Register"
                    onClick={this.handleSubmitRegister}/>
          </div>
          <br/>
          <span className={classes.bottomText}>Already have an account? <Link to={"/Login"}>Sign in</Link></span>
        </main>
    );
  }
}

export default withStyles(styles, {withTheme: true})(SignUpForm);
