import {alertError, alertSuccess, createJsonHeaders, logoutOnRequestOrResponseJson} from "../../../api";
import {setSearchAtoms } from '../actions/searchAtoms';
import {editAtom} from '../actions/atom'
import {saveAtoms} from '../actions/atoms'
import {getFlows} from "./flow";
import {BASE_URL} from "../../../configs";

export function createNewAtom(atom, botId, selectFunction) {
    fetch(BASE_URL + "/atom", {
        credentials: 'same-origin',
        headers: createJsonHeaders(),
        method: "POST",
        body: JSON.stringify(atom)
    }).then((response) =>
        logoutOnRequestOrResponseJson(response))
        .then((json) => {
            alertSuccess("Atom is created successfully.");
            getFlows(botId);
            // selectFunction(json.id);
        }).catch(function (error) {
        alertError("Fail. Try it later");
    });
}

export function saveAtom(atom, botId, language, callback) {
    fetch(BASE_URL + "/atom?language=" + language, {
        credentials: 'same-origin',
        headers: createJsonHeaders(),
        method: "PUT",
        body: JSON.stringify(atom)
    }).then((response) => {
        if (response.status === 400) {
            response.text().then((text) => {
                let res = JSON.parse(text);
                alertError(res.message)
            });
            return;
        }
        logoutOnRequestOrResponseJson(response)
            .then((json) => {
                alertSuccess("Atom is updated successfully.");
                editAtom(json);
                getFlows(botId);
                callback(json);
            });
    }).catch(function (error) {
        alertError("Fail. Try it later");
    });
}

export function getAtom(atomId, language, callback) {
    fetch(BASE_URL + "/atom/" + atomId + "?language=" + language, {
        credentials: 'same-origin',
        headers: createJsonHeaders(),
        method: "GET",
    }).then((response) =>
        logoutOnRequestOrResponseJson(response))
        .then((json) => {
            if(callback) callback(json);
            editAtom(json);
        }).catch(function (error) {
        alertError("Fail. Try it later");
    });
}

export function getAtoms(botId) {
    fetch(BASE_URL + "/atom/all/" + botId, {
        credentials: 'same-origin',
        headers: createJsonHeaders(),
        method: "GET",
    }).then((response) =>
        logoutOnRequestOrResponseJson(response))
        .then((json) => {
            saveAtoms(json);
        }).catch(function (error) {
        alertError("Fail. Try it later");
    });
}

export function deleteAtom(atomId, botId, callback) {
    fetch(BASE_URL + "/atom/" + atomId, {
        credentials: 'same-origin',
        headers: createJsonHeaders(),
        method: "DELETE",
    }).then((response) =>
        logoutOnRequestOrResponseJson(response))
        .then((json) => {
            getFlows(botId);
            alertSuccess("Atom is deleted succesfully");
            callback();
        }).catch(function (error) {
        alertError("Fail. Try it later");
    });
}

export const searchAtomsByContent = (searchQuery) => {
    fetch(BASE_URL + `/atom/search?query=${searchQuery}`, {
       credentials: 'same-origin',
       headers: createJsonHeaders(),
       method: "GET"
    })
        .then(logoutOnRequestOrResponseJson)
        .then(result => {
            setSearchAtoms(result)
        })
        .catch((_) => alertError("Fail. Try later"))
};
