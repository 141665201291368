import {alertError, logoutOnRequestOrResponseJson} from "../../../api";
import * as cookie from "react-cookies";
import {BASE_URL} from "../../../configs";


export function selectedFile(file) {
    return fetch(BASE_URL + "/file", {
        credentials: 'same-origin',
        method: "POST",
        headers: {"Authorization": "Bearer " + cookie.load('YWxpYWFkbWlu')},
        body: file
    }).then((response) => logoutOnRequestOrResponseJson(response))
        .catch(() => {
            alertError("Sorry but something going wrong please ping support!", 5000);
        });
}
