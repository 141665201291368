/**
 * Created by Admin on 25.04.2018.
 */
import React from 'react';
import {withStyles} from '@material-ui/core';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {Checkbox, FormControlLabel} from '@material-ui/core';
import InputHolder from '../../../../uiKit/inputs/InputHolder';
import {WelcomeIcon, HelloIcon} from '../../../../uiKit/icons/Icons';
import TabTemplate from '../TabTemplate';
import Select from "react-select";
import {isStringEmpty} from "../../../../helpers/isStringEmpty";


const styles = theme => ({
    title: {
        fontSize: 16,
        color: theme.palette.text.header
    },
    color: {
        fontSize: 14,
        color: theme.palette.text.header
    },
    description: {
        fontSize: 14,
        color: theme.palette.primary.text,
        marginTop: 16
    },
    label: {
        fontSize: 14,
        marginLeft: -16,
        display: 'block',
        paddingTop: 16,
        color: theme.palette.primary.text
    },
    rootCheckbox: {
        marginBottom: 0,
        color:
        theme.palette.primary.iconsGray,
        fontSize: 14,
        fontFamily: 'Lato',
        '&$checked':
            {
                color: theme.palette.primary.main
            }
    },
    checked: {},
    '@global':
        {
            'label.checkbox ':
                {
                    marginBottom: -12
                },
        }
});

class WelcomeScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            widget: this.props.widget,
            languageOptions: this.props.languageOptions,
            selectedLanguageOption: this.props.selectedOption,
        };
    }

    handleChange = (value, name) => {
        const widget = JSON.parse(JSON.stringify(this.props.widget));
        if (widget[name] === value) return;
        widget[name] = value;
        this.setState({
            widget
        }, () => {
            this.props.handleChange(value, name);
        });
    };

    render() {
        const {classes, widget, selectedLanguageOption} = this.props;
        const isRtl = selectedLanguageOption && selectedLanguageOption.rtl

        return (
            <TabTemplate
                tab={{
                    name: 'Welcome screen',
                    icon: <WelcomeIcon/>,
                    description: 'Add a greeting text and input fields that will be shown at the first widget screen'
                }}
                expanded={this.props.expanded}
                onChange={this.props.handleChangeExpansion}
            >
                <p className={classes.title}>Add a greeting text</p>
                <div style={{width: '220px', marginRight: '16px'}}>
                    <Select
                        options={this.props.languageOptions}
                        value={this.props.selectedLanguageOption}
                        onChange={this.props.handleSelectedLanguage}
                    />
                </div>
                {!(widget.inputEmail || widget.inputName) && <div>
                    <p className={classes.description}>Widget Header</p>
                    <InputHolder
                        defaultValue={widget.header}
                        onChange={(e) => {
                            this.setState({
                                header: e.target.value
                            });
                        }}
                        maxLength={60}
                        handleKeyPress={(e) => {
                            if (e.charCode === 13 && !isStringEmpty(e.target.value)) {
                                this.handleChange(this.state.header, 'header');
                            }
                        }}
                        onBlur={() => this.handleChange(this.state.header, 'header')}
                        rtl={isRtl}
                    />
                    <p className={classes.description}>Popup Text</p>
                    <InputHolder
                        defaultValue={widget.popupText}
                        onChange={(e) => {
                            this.setState({
                                popupText: e.target.value
                            });
                        }}
                        maxLength={60}
                        handleKeyPress={(e) => {
                            if (e.charCode === 13 && !isStringEmpty(e.target.value)) {
                                this.handleChange(this.state.popupText, 'popupText');
                            }
                        }}
                        onBlur={() => this.handleChange(this.state.popupText, 'popupText')}
                        rtl={isRtl}
                    />
                    <p className={classes.description}>Input Placeholder</p>
                    <InputHolder
                        defaultValue={widget.inputPlaceholder}
                        onChange={(e) => {
                            this.setState({
                                inputPlaceholder: e.target.value
                            });
                        }}
                        maxLength={60}
                        handleKeyPress={(e) => {
                            if (e.charCode === 13 && !isStringEmpty(e.target.value)) {
                                this.handleChange(this.state.inputPlaceholder, 'inputPlaceholder');
                            }
                        }}
                        onBlur={() => this.handleChange(this.state.inputPlaceholder, 'inputPlaceholder')}
                        rtl={isRtl}
                    />
                </div>}
                {(widget.inputEmail || widget.inputName) && <div>
                    <p className={classes.description}>Widget Header</p>
                    <InputHolder
                        defaultValue={widget.header}
                        onChange={(e) => {
                            this.setState({
                                header: e.target.value
                            });
                        }}
                        maxLength={60}
                        handleKeyPress={(e) => {
                            if (e.charCode === 13 && !isStringEmpty(e.target.value)) {
                                this.handleChange(this.state.header, 'header');
                            }
                        }}
                        onBlur={() => this.handleChange(this.state.header, 'header')}
                        rtl={isRtl}
                    />
                    <p className={classes.description}>Title</p>
                    <InputHolder
                        defaultValue={widget.title}
                        onChange={(e) => {
                            this.setState({
                                title: e.target.value
                            });
                        }}
                        maxLength={15}
                        handleKeyPress={(e) => {
                            if (e.charCode === 13 && !isStringEmpty(e.target.value)) {
                                this.handleChange(this.state.title, 'title');
                            }
                        }}
                        onBlur={() => this.handleChange(this.state.title, 'title')}
                        rtl={isRtl}
                    />
                    <p className={classes.description}>Greeting Text</p>
                    <InputHolder
                        defaultValue={widget.greetingText}
                        onChange={(e) => {
                            this.setState({
                                greetingText: e.target.value
                            });
                        }}
                        maxLength={50}
                        handleKeyPress={(e) => {
                            if (e.charCode === 13 && !isStringEmpty(e.target.value)) {
                                this.handleChange(this.state.greetingText, 'greetingText');
                            }
                        }}
                        onBlur={() => this.handleChange(this.state.greetingText, 'greetingText')}
                        rtl={isRtl}
                    />
                    <p className={classes.description}>Popup Text</p>
                    <InputHolder
                        defaultValue={widget.popupText}
                        onChange={(e) => {
                            this.setState({
                                popupText: e.target.value
                            });
                        }}
                        maxLength={60}
                        handleKeyPress={(e) => {
                            if (e.charCode === 13 && !isStringEmpty(e.target.value)) {
                                this.handleChange(this.state.popupText, 'popupText');
                            }
                        }}
                        onBlur={() => this.handleChange(this.state.popupText, 'popupText')}
                        rtl={isRtl}
                    />
                    <p className={classes.description}>Start Button</p>
                    <InputHolder
                        defaultValue={widget.startButton}
                        onChange={(e) => {
                            this.setState({
                                startButton: e.target.value
                            });
                        }}
                        maxLength={60}
                        handleKeyPress={(e) => {
                            if (e.charCode === 13 && !isStringEmpty(e.target.value)) {
                                this.handleChange(this.state.startButton, 'startButton');
                            }
                        }}
                        onBlur={() => this.handleChange(this.state.startButton, 'startButton')}
                        rtl={isRtl}
                    />
                    <p className={classes.description}>Start Label</p>
                    <InputHolder
                        defaultValue={widget.startLabel}
                        onChange={(e) => {
                            this.setState({
                                startLabel: e.target.value
                            });
                        }}
                        maxLength={60}
                        handleKeyPress={(e) => {
                            if (e.charCode === 13 && !isStringEmpty(e.target.value)) {
                                this.handleChange(this.state.startLabel, 'startLabel');
                            }
                        }}
                        onBlur={() => this.handleChange(this.state.startLabel, 'startLabel')}
                        rtl={isRtl}
                    />
                    <p className={classes.description}>Input Placeholder</p>
                    <InputHolder
                        defaultValue={widget.inputPlaceholder}
                        onChange={(e) => {
                            this.setState({
                                inputPlaceholder: e.target.value
                            });
                        }}
                        maxLength={60}
                        handleKeyPress={(e) => {
                            if (e.charCode === 13 && !isStringEmpty(e.target.value)) {
                                this.handleChange(this.state.inputPlaceholder, 'inputPlaceholder');
                            }
                        }}
                        onBlur={() => this.handleChange(this.state.inputPlaceholder, 'inputPlaceholder')}
                        rtl={isRtl}
                    />
                </div>}
                {widget.inputEmail && <div><p className={classes.description}>Email Placeholder</p>
                    <InputHolder
                        defaultValue={widget.emailPlaceholder}
                        onChange={(e) => {
                            this.setState({
                                emailPlaceholder: e.target.value
                            });
                        }}
                        maxLength={60}
                        handleKeyPress={(e) => {
                            if (e.charCode === 13 && !isStringEmpty(e.target.value)) {
                                this.handleChange(this.state.emailPlaceholder, 'emailPlaceholder');
                            }
                        }}
                        onBlur={() => this.handleChange(this.state.emailPlaceholder, 'emailPlaceholder')}
                        rtl={isRtl}
                    />
                </div>
                }
                {widget.inputName && <div><p className={classes.description}>Name Placeholder</p>
                    <InputHolder
                        defaultValue={widget.namePlaceholder}
                        onChange={(e) => {
                            this.setState({
                                namePlaceholder: e.target.value
                            });
                        }}
                        maxLength={60}
                        handleKeyPress={(e) => {
                            if (e.charCode === 13 && !isStringEmpty(e.target.value)) {
                                this.handleChange(this.state.namePlaceholder, 'namePlaceholder');
                            }
                        }}
                        onBlur={() => this.handleChange(this.state.namePlaceholder, 'namePlaceholder')}
                        rtl={isRtl}
                    />
                </div>
                }

                <p className={classes.title} style={{margin: '16px 0 0'}}>Choose the input fields to start a chat</p>
                <div style={{display: 'flex'}}>
                    <FormControlLabel
                        className="checkbox"
                        control={(
                            <Checkbox
                                onChange={() => this.handleChange(!widget.inputEmail, 'inputEmail')}
                                value={widget.inputEmail}
                                checked={widget.inputEmail}
                                classes={{
                                    root: classes.rootCheckbox,
                                    checked: classes.checked,
                                }}
                            />
                        )}
                    />
                    <span className={classes.label}>Email input</span>
                </div>
                <div style={{display: 'flex'}}>
                    <FormControlLabel
                        className="checkbox"
                        control={(
                            <Checkbox
                                onChange={() => this.handleChange(!widget.inputName, 'inputName')}
                                value={widget.inputName}
                                checked={widget.inputName}
                                classes={{
                                    root: classes.rootCheckbox,
                                    checked: classes.checked,
                                }}
                            />
                        )}
                    />
                    <span className={classes.label}>Name input</span>
                </div>

            </TabTemplate>
        );
    }
}

WelcomeScreen.propTypes = {
    classes: PropTypes.object
};

const mapStateToProps = state => (
    {
        activeBot: state.activeBot,
        botWidget: state.botWidget
    });

export default withRouter(withStyles(styles, {withTheme: true})(connect(mapStateToProps)(WelcomeScreen)));
