import {store} from '../../../index';

export const SAVE_NLP_REQUESTS = 'SAVE_NLP_REQUESTS';
export const UPDATE_NLP_REQUEST = 'UPDATE_NLP_REQUEST';
export const CLEAR_NLP_REQUESTS = 'CLEAR_NLP_REQUESTS';

export const saveNlpRequests = (requests) => {
	store.dispatch(
		{
			type: SAVE_NLP_REQUESTS,
			requests
		}
	);
};

export const updateTrainedNlpRequest = (request) => {
	store.dispatch(
		{
			type: UPDATE_NLP_REQUEST,
			request
		}
	);
};

export const clearNlpRequests = () => {
	store.dispatch(
		{
			type: CLEAR_NLP_REQUESTS
		}
	);
};
