import React from "react";
import Select from "react-select";
import {connect} from "react-redux";
import {withStyles} from "@material-ui/core";

import {styles} from "./languagesStyles";
import {addOptions, removeOptionsFromLanguage} from "./config";
import {addLanguage} from "../../api/settings";

class NewLanguageModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedLanguage: null,
            isLanguageSelected: false,
            languageError: false,
        }
    };

    handleSelectedLanguage = (language) => {
        this.setState({
            selectedLanguage: language,
            isLanguageSelected: true,
            languageError: false,
        })
    };

    handleLanguageSubmit() {
        this.checkWhetherLanguageSelected();
        if (this.state.isLanguageSelected) {
            const selected = removeOptionsFromLanguage(this.state.selectedLanguage);
            this.props.setActiveLoader(true);
            addLanguage(this.props.activeBot.id, selected.shortName).then((response) => {
                this.props.setActiveLoader(false);
                if (response.ok) {
                    this.props.updateSelectedLanguage(selected);
                }
            });
            this.props.closeAddLanguageModal();
        }
    };

    checkWhetherLanguageSelected() {
        if (this.state.selectedLanguage !== null) {
            this.setState({
                isLanguageSelected: true,
                languageError: false,
            });
        } else {
            this.setState({
                isLanguageSelected: false,
                languageError: true,
            });
        }
    };

    render() {
        const {selectedLanguage} = this.state;
        const {classes, availableLanguages} = this.props;

        const st = classes.selectValue;

        return (
            <div>
                <h2 className={classes.newLanguageModalHeader}>Add new language</h2>
                <p className={classes.newLanguageModalLabel}>Language</p>
                <Select
                    value={selectedLanguage}
                    placeholder={"Select new language"}
                    onChange={this.handleSelectedLanguage}
                    options={addOptions(availableLanguages)}
                />
                {this.state.languageError && <p className={classes.languageError}>
                    Language is not selected
                </p>}
                <button onClick={this.handleLanguageSubmit.bind(this)} className={classes.createButton}>
                    Add
                </button>
            </div>
        );
    }
}

const mapStateToProps = (state) => (
    {
        activeBot: state.activeBot,
    });
export default withStyles(styles, {withTheme: true})(connect(mapStateToProps)(NewLanguageModal));
