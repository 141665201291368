import * as cookie from "react-cookies";
import {store} from "../../../index";

export const IS_AUTHENTICATED = 'IS_AUTHENTICATED';
export const IS_NOT_AUTHENTICATED = 'IS_NOT_AUTHENTICATED';

export const authenticate = (token) => (
    store.dispatch(
        {
            type: IS_AUTHENTICATED,
            security: {
                token: token,
                authenticated: true
            }
        })
);

export const logout = () => {
    cookie.remove('YWxpYWFkbWlu', {path: '/'});
    store.dispatch(
        {
            type: IS_NOT_AUTHENTICATED,
            security: {
                token: null,
                authenticated: false
            }
        })
};
