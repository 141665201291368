import React from "react";
import {withStyles} from "@material-ui/core";

import {styles} from "./languagesStyles";
import SubmitButton from "../../../../uiKit/buttons/SubmitButton";
import Modal from "../../../../uiKit/Modal";

const RowView = props => {
    const {classes, language, index, onLanguageDelete, deleteModal, setDeleteModal} = props;
    const isEven = (index % 2) === 0;

    return (
        <div className={isEven ? classes.evenRow : classes.notEvenRow}>
            <p>{language.fullName}</p>
            {!language.isDefault &&
            <div>
                <p onClick={() => setDeleteModal(language.shortName)} className={classes.deleteLanguageBtn}>Delete</p>
                <Modal
                    open={deleteModal === language.shortName}
                    onClose={() => setDeleteModal(null)}
                >
                    <p className={classes.deleteModalHeader}>Are you sure you want to delete {language.fullName} language?</p>
                    <div className={classes.buttonsHolder}>
                        <SubmitButton onClick={() => {
                            onLanguageDelete(language.shortName);
                            setDeleteModal(false);
                        }}
                                      empty
                                      title="Delete"
                        />
                        <SubmitButton onClick={() => setDeleteModal(null)}
                                      title="Cancel"
                        />
                    </div>
                </Modal>
            </div>
            }
        </div>
    )
};

export default withStyles(styles, {withTheme: true})(RowView);
