import React from 'react';
import { connect } from 'react-redux';
import { LineChart } from 'react-chartkick';

import Loader from '../../../../uiKit/loaders/loader';
import DateControl from "./DateControl";
import NoChartData from "../NoChartData";
import { loadUsersStatistics } from "../../api/dashboard";
import { totalUsersHeaders } from "../../../../constants/totalUsersHeaders";



const noDataText = 'You have no statistics yet. It will appear here after your chatbot will get first users';
const tooltipText = `This chart shows the number of daily
        active users for the selected period. For a given day,
         Daily Users is the number of unique users who were
         chatting with the bot that day.`;

const convertDataCsv = (data) => {
    return Object.keys(data.points).map(k => {
        return {
            date : k,
            users: data.points[k]
        }
    })
};

const AreaChart = ({ botId, usersStatistics: chartData, className }) => (
    <div className={className} style={{ minHeight: 550 }}>
        <DateControl
            title="Total users"
            headerStats
            style={!chartData ? { display: 'none' } : {}}
            botId={botId}
            loadData={loadUsersStatistics}
            tooltipText={tooltipText}
            users={chartData ? chartData.usersBetweenDates : '-'}
            totalUsers={chartData ? chartData.totalUsers : '-'}
            fileName={chartData ? 'total_users': ''}
            dataCsv={chartData ? convertDataCsv(chartData) : []}
            headersCsv={totalUsersHeaders}
        />
        {
            !chartData
                ? (
                    <div style={{
                        margin: 'auto',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        <Loader/>
                    </div>
                )
                : chartData.points && Object.keys(chartData.points).length
                ? <LineChart data={chartData.points} min={0}/>
                : <NoChartData text={noDataText}/>
        }
    </div>
);

const mapStateToProps = (state) => (
    {
        usersStatistics: state.usersStatistics
    });

export default connect(mapStateToProps)(AreaChart);
