import React from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import {Button} from "@material-ui/core";
import {getModalStyle} from "../../flowBuilder/components/messages/NewMessage";
import {withRouter} from "react-router-dom";
import {createNewContext, getAtomContexts, updateContext} from "../api/context";
import {connect} from "react-redux";
import TriggerItem from "./TriggerItem";
import {getIntents} from "../api/nlp";
import {PlusIcon} from "../../../uiKit/icons/Icons";

const styles = theme => ({
    paper: {
        position: 'absolute',
        width: '60%',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 4,
    },
    mainSelected: {
        display: 'flex',
        margin: '20px 0',
        width: '100%',
        justifyContent: 'space-between',
        background: '#FFFFFF',
        boxShadow: '4px 4px 29px rgba(19, 69, 186, 0.0855129)',
        borderRadius: 10
    },
    selectItem: {
        flexBasis: '30%',
        padding: 24,
    },
    title: {
        textAlign: 'center',
        fontSize: 24,
        color: '#3A3F62',
        fontWeight: 'bold'
    },
    createIntentButton: {
        background: 'linear-gradient(312.17deg, #5A98F7 3.33%, #1658F3 96.71%)',
        boxShadow: '0px 3px 9px rgba(19, 69, 186, 0.206267)',
        height: 43,
        width: 43,
        padding: 0,
        minWidth: 43,
        borderRadius: 25
    },
});

class AtomNLPModal extends React.Component {

    state = {
        page: 0,
        rowsPerPage: 10,
        open: false,
        triggerAtom: {}
    };

    componentDidMount() {
        let self = this;
        getAtomContexts(this.props.atomId, self);
        getIntents(this.props.match.params.botId, false);
    }

    createTrigger() {
        let context = {
            previousAtom: this.state.currentAtom,
            intent: this.state.intent,
            currentAtom: this.state.newAtom,
        };
        if (this.props.modalTriggerId == null) {
            createNewContext(context, this.props.match.params.botId);//todo: get atom contexts id if this.props.triggerNewAtom != null
        } else {
            context.id = this.props.modalTriggerId;
            updateContext(context, this.props.match.params.botId);
        }
    };

    save() {
        // this.props.closeAtomNLP();
    }

    handleChangePage = (event, page) => {
        this.setState({page});
    };

    handleChangeRowsPerPage = event => {
        this.setState({rowsPerPage: event.target.value});
    };

    openTriggerModal(contextId) {
        this.setState({
            modalTriggerId: contextId,
            triggerAtom: {
                id: this.props.atomId,
                name: this.props.atomName
            },
            open: true,
        });
    }

    closeTriggerModal = () => {
        this.setState({open: false});
    };

    render() {
        const {classes} = this.props;
        const {rowsPerPage, page} = this.state;
        const emptyRows = rowsPerPage - Math.min(rowsPerPage, this.props.contexts.length - page * rowsPerPage);
        return (<div>
            <div style={getModalStyle()} className={classes.paper}>
                {this.props.contexts != null &&
                <div>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        minWidth: 260,
                        justifyContent: 'space-between'
                    }}>
                        <h1 className={classes.title}>Triggers</h1>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            flexBasis: '30%',
                        }}>

                            <Button className={classes.createIntentButton}
                                    onClick={() => this.createTrigger()}
                                    variant="raised">
                                <PlusIcon height='20px' width='20px' color="#FFFFFF"/>
                            </Button>
                            <p>Add new <span style={{textTransform: 'lowercase'}}>Trigger</span></p>
                        </div>
                    </div>

                    <div className={classes.table}>
                        {this.props.contexts && this.props.contexts.map(context =>
                            <div key={context.id}><TriggerItem context={context}
                                                               modalTriggerId={context.id}/></div>
                        )}
                    </div>
                </div>}
            </div>
        </div>)

    }
}


const mapStateToProps = (state) => (
    {
        contexts: state.contexts,
        intents: state.intents
    });

AtomNLPModal.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withRouter((withStyles(styles))(connect(mapStateToProps)(AtomNLPModal)));
