export const styles = theme => ({
  paragraph: {
    fontFamily: 'Lato',
    fontSize: 14,
    color: '#616581'
  },
  attributes: {
    maxWidth: 540,
    marginTopmarginTop: 32
  },
  attribute: {
    display: "flex",

    "& + &": {
      marginTop: 24
    },

    "&:hover": {
      "& .deleteIcon": {
        opacity: .5
      }
    }
  },
  titlesColumns: {
    display: "flex",
    marginBottom: 24,

    "& p": {
      fontSize: 14,
      lineHeight: "17px",
      color: "#616581",
      margin: 0
    }
  },
  column: {
    width: 240,
    position: "relative",

    '& + &': {
      marginLeft: 16
    },
    "& input": {
      width: "100%",
      padding: "8px 12px",
      fontSize: 14,
      lineHeight: "20px",
      color: "#354052",
      fontWeight: 400,
      background: "#FFFFFF",
      border: "1px solid rgba(53, 64, 82, 0.1)",
      boxSizing: "border-box",
      borderRadius: "4px",

      "&::placeholder": {
        color: "#B0B2C0",
      }
    }
  },
  deleteIcon: {
    position: "absolute",
    top: 5,
    right: -40,
    cursor: "pointer",
    opacity: 0,
    transition: "all .1s ease-in",

    "&:hover": {
      opacity: "1 !important"
    }
  },
  addNew: {
    background: "none",
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 4,
    padding: "8px 16px",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '32px 0 0',
    cursor: 'pointer',

    "& svg": {
      marginRight: 4
    }
  }
});
