import {
  alertError,
  alertSuccess,
  createJsonHeaders,
  logoutOnRequestOrResponseJson
} from "../../../api";
import { BASE_URL } from "../../../configs";

export function getChatbaseConfig(botId) {
  return fetch(BASE_URL + "/bot/" + botId + "/chatbase/", {
    credentials: "same-origin",
    headers: createJsonHeaders(),
    method: "GET"
  })
    .then(response => {
      return logoutOnRequestOrResponseJson(response);
    })
    .catch(function(error) {
      alertError("Sorry but something going wrong please ping support!");
    });
}

export function updateChatbaseConfig(botId, chatbaseConfig, callback) {
  fetch(BASE_URL + "/bot/" + botId + "/chatbase/", {
    credentials: "same-origin",
    headers: createJsonHeaders(),
    method: "PUT",
    body: JSON.stringify(chatbaseConfig)
  }).then(response => {
    if (response.status === 400) {
      response.text().then(text => {
        let res = JSON.parse(text);
        alertError(res.message);
      });
      return;
    } else if (response.status === 204) {
      alertSuccess("Chatbase is updated successfully.");
    } else {
      alertError("Fail. Try it later");
    }
  });
}
