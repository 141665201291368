/**
 * Created by Admin on 13.02.2018.
 */
import React from "react";
import {connect} from "react-redux";
import Tags from "./Tags";
import {withRouter} from "react-router-dom";

const styles = {
    container: {
        marginTop: 10,
        padding: 10,
        width: '90%',
    },
    flowBuilderTags: {
        width: '100%'
    },
};

class TagAction extends React.Component {
    render() {
        return (
            <div className='message' style={styles.container}>
                <p>Tags</p>
                <Tags onChange={(value) => this.props.onChange(value)}
                      value={this.props.value}
                      style={styles.flowBuilderTags}
                      styleSelect={styles.flowBuilderTags}/>
                <div className="dlt-msg" onClick={() => this.props.onDelete()}>
                    <img src="/images/platform/delete.svg" alt="delete"/>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => (
    {
        params: state.params
    });

export default withRouter(connect(mapStateToProps)(TagAction));
