import React from 'react';
import {withRouter} from 'react-router-dom';

import ErrorScreen from '../ErrorScreen/ErrorScreen';

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			hasError: false
		};
	}

	static getDerivedStateFromError() {
		return { hasError: true };
	}

	componentDidUpdate(prevProps,prevState) {
		if (this.props.location.pathname !== prevProps.location.pathname) {
			this.setState({ hasError: false})
		}
	}

	render() {
		if (this.state.hasError) {
			return <ErrorScreen/>;
		}

		return this.props.children;
	}
}

export default withRouter(ErrorBoundary);
