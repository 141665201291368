export const styles = () => ({
    container: {
        overflowY: 'auto',
        height: 'calc(100% - 126px)'
    },
    loaderWrap: {
        height: 'calc(100% - 127px)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    searchContainer: {
        margin: '8px 12px',
    }
});
